const AllSubcategoryFollowDialog = ({ isFollowed, lang }) => {
  return (
    <div className='px-[24px] pt-[20px]'>
      <div className='flex items-center justify-between'>
        <div className='text-[20px] text-black'>
          {lang?.common?.are_you_sure}
        </div>
        {/* <MdClose size={20} /> */}
      </div>
      <div className='mt-4 flex text-left text-sm'>
        {isFollowed
          ? lang?.common?.unfollowing_all_sub_categories
          : lang?.common?.following_all_sub_categories}
      </div>
    </div>
  );
};

export default AllSubcategoryFollowDialog;
