
const RemoveFollowerDialog = ({lang}) => {
  
  return (
    <div className='px-[24px] pt-[20px]'>
      <div className='flex items-center justify-between'>
        <div className='text-[20px] text-black'>
          {lang?.rons_tips?.remove_follower}
        </div>
        {/* <MdClose size={20} /> */}
      </div>
      <div className='mt-4 flex text-left text-sm'>
        {lang?.rons_tips?.follower_will_not_be_notified}
      </div>
    </div>
  );
};

export default RemoveFollowerDialog;
