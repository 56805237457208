import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DefaultSeo } from 'next-seo';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import 'react-day-picker/dist/style.css';
import { Provider } from 'react-redux';
import '../../styles/globals.css';
import '../../styles/style.css';
import { getSelectedCity, getUrlBasedOnLocale } from '../../utils';
import { LOCALES } from '../../utils/constant';
import { DEFAULT_META } from '../../utils/meta';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
const PusherInit = dynamic(() => import('../components/Pusher/PusherInit'), {
  ssr: false,
});
import { RouteGuard } from '../components/RouteGuard/RouteGuard';
import store from '../store';
import { AdsProvider } from '../contexts/AdsContext';
import { RonsTipsProvider } from '../contexts/RonsTipsContext';
import dynamic from 'next/dynamic';

const theme = createTheme({
  typography: {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: 14,
  },
  palette: {
    primary: {
      main: '#212121',
    },
  },
});

function MyApp({ Component, pageProps }) {
  const { metaData = DEFAULT_META } = pageProps;
  const router = useRouter();
  const { city } = router.query;

  if (typeof window !== 'undefined') {
    if (router.isReady) {
      if (!city || city === 'ZÃ¼rich') {
        router.replace({
          pathname: router.pathname,
          query: { ...router.query, city: getSelectedCity()?.name || 'Zürich' },
        });
      }
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <DefaultSeo
          {...metaData}
          dangerouslySetAllPagesToNoFollow={
            process.env.NEXT_PUBLIC_ENV !== 'PRODUCTION'
          }
          dangerouslySetAllPagesToNoIndex={
            process.env.NEXT_PUBLIC_ENV !== 'PRODUCTION'
          }
        />

        <RouteGuard>
          <ErrorBoundary>
            <Head>
              {LOCALES.map((locale) => (
                <link
                  key={locale}
                  rel='alternate'
                  href={`${process.env.NEXT_PUBLIC_FRONTEND_URL}${getUrlBasedOnLocale(locale, router)}`}
                  hreflang={locale}
                />
              ))}
              <link
                rel='alternate'
                href={`${process.env.NEXT_PUBLIC_FRONTEND_URL}${router.asPath}`}
                hreflang='x-default'
              />
              <link rel='manifest' href='/manifest.json' />
            </Head>
            {/* Google Analytics Script */}
            {process.env.NEXT_PUBLIC_ENV === 'PRODUCTION' && (
              <>
                <Script
                  src='https://www.googletagmanager.com/gtag/js?id=G-JGTNXPV2LY'
                  strategy='afterInteractive'
                />
                <Script id='google-analytics' strategy='afterInteractive'>
                  {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-JGTNXPV2LY');
                  `}
                </Script>
              </>
            )}
            <PusherInit />
            <AdsProvider>
              <RonsTipsProvider>
                <Component {...pageProps} />
              </RonsTipsProvider>
            </AdsProvider>
          </ErrorBoundary>
        </RouteGuard>
      </Provider>
    </ThemeProvider>
  );
}

export default MyApp;
