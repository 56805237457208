import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from '../../../../utils';
import { getProfileId } from '../../../../utils/authUtils';
import { instance } from '../../../axios/axios';

const moduleName = 'notifications';
const apiRoute = `/notifications`;

export const getNotificationsAsync = createAsyncThunk(
  `${moduleName}/getNotificationsAsync`,
  async ({ take = 10, skip = 0 }) => {
    const response = await instance.get(
      `${apiRoute}?profile_id=${getProfileId()}&take=${take}&skip=${skip}`,
      getHeaders()
    );
    return response?.data;
  }
);
export const getTopNotificationsAsync = createAsyncThunk(
  `${moduleName}/getTopNotificationsAsync`,
  async () => {
    const response = await instance.get(
      `top-notifications?profile_id=${getProfileId()}`,
      getHeaders()
    );
    return response?.data;
  }
);
export const makeReadNotificationsAsync = createAsyncThunk(
  `${moduleName}/makeReadNotificationsAsync`,
  async ({ id, is_top = 0 }) => {
    const response = await instance.get(
      `notification-makeread/${id}?is_top=${is_top}`,
      getHeaders()
    );
    response.data.id = id;
    return response.data;
  }
);

export const markAllAsRead = createAsyncThunk(
  `${moduleName}/markAllAsRead`,
  async (profileId) => {
    const response = await instance.get(
      `mark-as-read/${profileId}`,
      getHeaders()
    );
    return response.data;
  }
);

export const deleteNotification = createAsyncThunk(
  `${moduleName}/deleteNotification`,
  async (notificationId) => {
    const response = await instance.delete(
      `notifications/${notificationId}`,
      getHeaders()
    );
    return response.data;
  }
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    unreadNotifications: [],
    notifications: [],
    topNotifications: [],
    loadingNotifications: false,
    unreadCount: 0,
    unreadChatExists: false,
  },
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setUnreadChatExists: (state, action) => {
      state.unreadChatExists = action.payload;
    },
    addTopNotifications: (state, action) => {
      if (state?.topNotifications?.length > 0) {
        const topNots = state.topNotifications;
        const alreadyExists = topNots.find(
          (item) => item?.id == action.payload.id
        );
        if (alreadyExists) return;
      }
      state.topNotifications = [action.payload, ...state.topNotifications];
      state.unreadNotifications = [action.payload];
      state.unreadCount = state.unreadCount + 1;
      // state.topNotifications.map(notification =>{
      //     if(notification.id === action.payload.id) notification.read_at = new Date();
      //     return notification;
      // });
    },
  },
  extraReducers: {
    [getNotificationsAsync.pending]: (state, action) => {
      state.loadingNotifications = true;
    },
    [getNotificationsAsync.fulfilled]: (state, action) => {
      state.notifications = action.payload;
      state.loadingNotifications = false;
      state.unreadCount = action?.payload?.unread_notifications;
    },
    [getTopNotificationsAsync.fulfilled]: (state, action) => {
      state.topNotifications = action?.payload?.notifications;
      state.unreadCount = action?.payload?.unread_notifications;
      state.unreadChatExists = action?.payload?.unread_chat_exists;
      const top_nots = action?.payload?.notifications || [];
      const temp = [];

      for (const item of top_nots) {
        if (item?.read_at == '' || item?.read_at == null) {
          temp.push(item);
        }
      }

      state.unreadNotifications = temp;
    },
    [makeReadNotificationsAsync.fulfilled]: (state, action) => {
      state.unreadCount = action?.payload?.unread_notifications;
      state.topNotifications = state.topNotifications.map((notification) => {
        if (notification.id === action.payload.id)
          notification.read_at = new Date();
        return notification;
      });
    },
    [markAllAsRead.fulfilled]: (state, action) => {
      state.unreadNotifications = [];
    },
  },
});

export const { setNotifications, addTopNotifications, setUnreadChatExists } =
  notificationsSlice.actions;
export default notificationsSlice.reducer;
