import { configureStore } from '@reduxjs/toolkit';
import citiesReducer from './slices/common/citiesSlice';
import commonReducer from './slices/common/commonSlice';
import seoReducer from './slices/common/seoSlice';
import adsReducer from './slices/common/adsSlice';
import userProfileReducer from './slices/common/userProfileSlice';
import communityReducer from './slices/community/communitySlice';
import commentReducer from './slices/community/commentSlice';
import replyReducer from './slices/community/replySlice';
import loveReducer from './slices/community/loveSlice';
import followReducer from './slices/community/followSlice';
import chatReducer from './slices/common/chatSlice';
import notificationReducer from './slices/common/notificationsSlice';
import ronstipsReducer from './slices/ronstips/ronstipsSlice';
import acSettingReducer from './slices/common/acSettingSlice';
import myFollowsSlice from './slices/common/myFollowsSlice';
import tagsSlice from './slices/common/tagsSlice';
import marketSlice from './slices/market/marketSlice';
import subscriptionsSlice from './slices/subscriptions/subscriptionsSlice';
import userPortfolioSlice from './slices/common/userPortfolioSlice';
import footerSlice from './slices/common/footerSlice';
import dashboardSlice from './slices/common/dashboardSlice';
import myPaidAdsSlice from './slices/myPaidAds/myPaidAdsSlice';
const store = configureStore({
  reducer: {
    common: commonReducer,
    userProfile: userProfileReducer,
    cities: citiesReducer,
    seo: seoReducer,
    ads: adsReducer,
    community: communityReducer,
    comment: commentReducer,
    reply: replyReducer,
    love: loveReducer,
    chat: chatReducer,
    notifications: notificationReducer,
    follow: followReducer,
    ronstips: ronstipsReducer,
    acSetting: acSettingReducer,
    myFollows: myFollowsSlice,
    tags: tagsSlice,
    market: marketSlice,
    subscriptions: subscriptionsSlice,
    userPortfolio: userPortfolioSlice,
    footer: footerSlice,
    dashboard: dashboardSlice,
    myPaidAds: myPaidAdsSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
});

export default store;
