import { getLangBasedOnCity } from '../../utils';

const en_meta = {
  community_home_meta_title: 'Ron Community',
  community_home_meta_description: 'Ron Community',
  rons_tips_home_meta_title: "Ron's Tips",
  rons_tips_home_meta_description: "Ron's Tips",
  market_home_meta_title: 'Ron Market',
  market_home_meta_description: 'Ron Market',
  rons_tips_guides_meta_title: "Guides",
  rons_tips_guides_meta_description: "Guides",
  rons_tips_family_life_meta_title: "Family Life",
  rons_tips_family_life_meta_description: "Family Life",
  rons_tips_family_life_diy_meta_title: "DIY",
  rons_tips_family_life_diy_meta_description: "DIY",
  rons_tips_family_life_finance_meta_title: "Finance",
  rons_tips_family_life_finance_meta_description: "Finance",
  rons_tips_family_life_parenthood_meta_title: "Parenthood",
  rons_tips_family_life_parenthood_meta_description: "Parenthood",
  rons_tips_family_life_podcasts_meta_title: "Podcasts",
  rons_tips_family_life_podcasts_meta_description: "Podcasts",
  rons_tips_family_life_trips_meta_title: "Trips",
  rons_tips_family_life_trips_meta_description: "Trips",
  rons_tips_family_life_inspiration_meta_title: "Inspiration",
  rons_tips_family_life_inspiration_meta_description: "Inspiration",
  rons_tips_city_life_meta_title: "City Life",
  rons_tips_city_life_meta_description: "City Life",
  rons_tips_whats_up_meta_title: "What's Up",
  rons_tips_whats_up_meta_description: "What's Up",
  rons_tips_balance_day_meta_title: "Balance Day",
  rons_tips_balance_day_meta_description: "Balance Day",
  rons_tips_pastime_meta_title: "Pastime",
  rons_tips_pastime_meta_description: "Pastime",
  rons_tips_ron_voyage_meta_title: "Ron Voyage",
  rons_tips_ron_voyage_meta_description: "Ron Voyage",
  rons_tips_roncast_meta_title: "Roncast",
  rons_tips_roncast_meta_description: "Roncast",
  rons_tips_rooftop_day_meta_title: "Rooftop Day",
  rons_tips_rooftop_day_meta_description: "Rooftop Day",
  rons_tips_streetfood_tour_meta_title: "Streetfood Tour",
  rons_tips_streetfood_tour_meta_description: "Streetfood Tour",
  rons_tips_whats_happening_meta_title: "What's Happening",
  rons_tips_whats_happening_meta_description: "What's Happening",
  rons_tips_rons_events_meta_title: "Ron's Events",
  rons_tips_rons_events_meta_description: "Ron's Events",
  rons_tips_food_and_shop_meta_title: "Food and Shop",
  rons_tips_food_and_shop_meta_description: "Food and Shop",
  rons_tips_food_and_drinks_meta_title: "Food and Drinks",
  rons_tips_food_and_drinks_meta_description: "Food and Drinks",
  rons_tips_new_in_town_meta_title: "New in Town",
  rons_tips_new_in_town_meta_description: "New in Town",
  rons_tips_shopping_meta_title: "Shopping",
  rons_tips_shopping_meta_description: "Shopping",
  rons_tips_contest_meta_title: "Contest",
  rons_tips_contest_meta_description: "Contest",
  rons_tips_people_meta_title: "People",
  rons_tips_people_meta_description: "People",
  rons_tips_out_of_town_meta_title: "Out of Town",
  rons_tips_out_of_town_meta_description: "Out of Town",
  about_ron_meta_title: 'About Ron',
  about_ron_meta_description: 'About Ron',
  terms_and_conditions_meta_title: 'Terms & Conditions | Ron Orp',
  terms_and_conditions_meta_description: 'Terms & Conditions | Ron Orp',
  privacy_policy_meta_title: 'Privacy Policy | Ron ORP',
  privacy_policy_meta_description: 'Privacy Policy | Ron ORP',
  advertise_on_ron_orp_meta_title: 'Advertise on Ron Orp',
  advertise_on_ron_orp_meta_description: 'Advertise on Ron Orp',
  place_ad_on_website_meta_title: 'Place ad on Website',
  place_ad_on_website_meta_description: 'Place ad on Website',
  post_in_newsletter_meta_title: 'Post in Newsletter',
  post_in_newsletter_meta_description: 'Post in Newsletter',
  top_placement_meta_title: 'Top Placement',
  top_placement_meta_description: 'Top Placement',
  start_new_discussion_meta_title: 'Start New Discussion | Community',
  start_new_discussion_meta_description: 'Start New Discussion | Community',
  create_story_meta_title: 'Create Story | Community',
  create_story_meta_description: 'Create Story | Community',
  create_survey_meta_title: 'Create Survey | Community',
  create_survey_meta_description: 'Create Survey | Community',
  create_phototrip_meta_title: 'Create Phototrip | Community',
  create_phototrip_meta_description: 'Create Phototrip | Community',
  create_together_event_meta_title: 'Create Together Event | Community',
  create_together_event_meta_description: 'Create Together Event | Community',
  love_registration_meta_title: 'Love Registration',
  love_registration_meta_description: 'Love Registration',
  place_ad_meta_title: 'Place Advertisement | Market',
  place_ad_meta_description: 'Place Advertisement | Market',
  create_events_meta_title: 'Create Events | Market',
  create_events_meta_description: 'Create Events | Market',
  create_housing_meta_title: 'Create Housing | Market',
  create_housing_meta_description: 'Create Housing | Market',
  create_secondhand_meta_title: 'Create Secondhand | Market',
  create_secondhand_meta_description: 'Create Secondhand | Market',
  create_jobs_meta_title: 'Create Jobs | Market',
  create_jobs_meta_description: 'Create Jobs | Market',
  create_city_offer_meta_title: 'Create City Offer | Market',
  create_city_offer_meta_description: 'Create City Offer | Market',
  create_create_commercial_meta_title: 'Create Commercial | Market',
  create_create_commercial_meta_description: 'Create Commercial | Market',
};

const fr_meta = {
  community_home_meta_title: 'Communauté Ron',
  community_home_meta_description:
    'Rejoignez la communauté Ron pour des événements et discussions.',
  rons_tips_home_meta_title: 'Conseils de Ron',
  rons_tips_home_meta_description:
    'Explorez des astuces et guides pour la vie urbaine et au-delà.',
  market_home_meta_title: 'Marché Ron',
  market_home_meta_description:
    'Achetez, vendez et connectez-vous sur le Marché Ron.',
  rons_tips_guides_meta_title: 'Guides de Ron',
  rons_tips_guides_meta_description:
    'Des guides complets pour votre vie urbaine avec les conseils Ron.',
  rons_tips_family_life_meta_title: 'Vie familiale de Ron',
  rons_tips_family_life_meta_description:
    'Découvrez le meilleur de la vie familiale avec les conseils Ron.',
  rons_tips_family_life_diy_meta_title: 'DIY de Ron',
  rons_tips_family_life_diy_meta_description:
    'Découvrez le meilleur de la vie familiale avec les conseils Ron.',
  rons_tips_family_life_finance_meta_title: 'Finance de Ron',
  rons_tips_family_life_finance_meta_description:
    'Découvrez le meilleur de la vie financière avec les conseils Ron.',
  rons_tips_family_life_parenthood_meta_title: 'Parenthood de Ron',
  rons_tips_family_life_parenthood_meta_description:
    'Découvrez le meilleur de la vie familiale avec les conseils Ron.',
  rons_tips_family_life_podcasts_meta_title: 'Podcasts de Ron',
  rons_tips_family_life_podcasts_meta_description:
    'Découvrez le meilleur de la vie familiale avec les conseils Ron.',
  rons_tips_family_life_trips_meta_title: 'Trips de Ron',
  rons_tips_family_life_trips_meta_description:
    'Découvrez le meilleur de la vie familiale avec les conseils Ron.',
  rons_tips_family_life_inspiration_meta_title: 'Inspiration de Ron',
  rons_tips_family_life_inspiration_meta_description:
    'Découvrez le meilleur de la vie familiale avec les conseils Ron.',
  rons_tips_city_life_meta_title: 'Vie urbaine de Ron',
  rons_tips_city_life_meta_description:
    'Découvrez le meilleur de la vie urbaine avec les conseils Ron.',
  rons_tips_whats_up_meta_title: 'Quoi de neuf de Ron',
  rons_tips_whats_up_meta_description:
    'Restez informé des événements et actualités de votre ville.',
  rons_tips_balance_day_meta_title: 'Journée équilibre de Ron',
  rons_tips_balance_day_meta_description:
    'Atteignez l’équilibre avec les astuces pratiques de Ron.',
  rons_tips_pastime_meta_title: 'Loisirs de Ron',
  rons_tips_pastime_meta_description:
    'Découvrez des loisirs amusants et engageants avec Ron.',
  rons_tips_ron_voyage_meta_title: 'Ron Voyage',
  rons_tips_ron_voyage_meta_description:
    'Votre guide pour des voyages passionnants avec Ron.',
  rons_tips_roncast_meta_title: 'Roncast',
  rons_tips_roncast_meta_description:
    'Écoutez Roncast pour des idées et inspirations.',
  rons_tips_rooftop_day_meta_title: 'Journée Rooftop de Ron',
  rons_tips_rooftop_day_meta_description:
    'Célébrez la Journée Rooftop avec les événements exclusifs de Ron.',
  rons_tips_streetfood_tour_meta_title: 'Tour de streetfood de Ron',
  rons_tips_streetfood_tour_meta_description:
    'Partez à la découverte du street food avec Ron.',
  rons_tips_whats_happening_meta_title: 'Quoi de neuf de Ron',
  rons_tips_whats_happening_meta_description:
    'Restez informé des événements et actualités de votre ville.',
  rons_tips_rons_events_meta_title: 'Événements de Ron',
  rons_tips_rons_events_meta_description:
    'Découvrez les événements de Ron et participez à des concours.',
  rons_tips_food_and_shop_meta_title: 'Nourriture et shopping de Ron',
  rons_tips_food_and_shop_meta_description:
    'Découvrez les meilleurs endroits pour manger et faire du shopping.',
  rons_tips_food_and_drinks_meta_title: 'Nourriture et boissons de Ron',
  rons_tips_food_and_drinks_meta_description:
    'Explorez de superbes expériences gastronomiques avec Ron.',
  rons_tips_new_in_town_meta_title: 'Nouveau en ville de Ron',
  rons_tips_new_in_town_meta_description:
    'Découvrez votre nouvelle ville avec les conseils essentiels de Ron.',
  rons_tips_shopping_meta_title: 'Shopping de Ron',
  rons_tips_shopping_meta_description:
    'Faites du shopping malin avec les conseils de Ron.',
  rons_tips_contest_meta_title: 'Concours de Ron',
  rons_tips_contest_meta_description:
    'Participez aux concours de Ron et gagnez des prix incroyables.',
  rons_tips_people_meta_title: 'Gens de Ron',
  rons_tips_people_meta_description:
    'Histoires et idées sur les gens de la communauté Ron.',
  rons_tips_out_of_town_meta_title: 'Sorties de ville de Ron',
  rons_tips_out_of_town_meta_description:
    'Découvrez des lieux hors de la ville avec les conseils Ron.',
  about_ron_meta_title: 'À propos de Ron',
  about_ron_meta_description:
    'Découvrez-en plus sur Ron et la vision derrière la plateforme.',
  terms_and_conditions_meta_title: 'Termes et conditions',
  terms_and_conditions_meta_description:
    'Comprenez les termes et conditions d’utilisation de Ron Orp.',
  privacy_policy_meta_title: 'Politique de confidentialité',
  privacy_policy_meta_description:
    'Lisez notre politique de confidentialité pour savoir comment nous protégeons vos données.',
  advertise_on_ron_orp_meta_title: 'Publicité sur Ron Orp',
  advertise_on_ron_orp_meta_description:
    'Touchez votre audience cible avec la publicité sur Ron Orp.',
  place_ad_on_website_meta_title: 'Placer une annonce sur le site',
  place_ad_on_website_meta_description:
    'Placez votre annonce directement sur le site de Ron Orp.',
  post_in_newsletter_meta_title: 'Publier dans la newsletter',
  post_in_newsletter_meta_description:
    'Publiez votre annonce dans la newsletter populaire de Ron.',
  top_placement_meta_title: 'Placement premium',
  top_placement_meta_description:
    'Assurez une visibilité maximale avec un placement premium.',
  start_new_discussion_meta_title: 'Commencer une discussion',
  start_new_discussion_meta_description:
    'Engagez des discussions avec des membres partageant les mêmes idées.',
  create_story_meta_title: 'Créer une histoire',
  create_story_meta_description:
    'Partagez votre histoire avec la communauté Ron.',
  create_survey_meta_title: 'Créer une enquête',
  create_survey_meta_description:
    'Obtenez des réponses et engagez la communauté avec une enquête.',
  create_phototrip_meta_title: 'Créer une phototrip',
  create_phototrip_meta_description:
    'Montrez vos talents en photographie avec une phototrip.',
  create_together_event_meta_title: 'Créer un événement ensemble',
  create_together_event_meta_description:
    'Organisez des événements et connectez-vous à travers la plateforme Ron.',
  love_registration_meta_title: 'Inscription amour',
  love_registration_meta_description:
    'Trouvez l’amour et des connexions significatives avec Ron.',
  place_ad_meta_title: 'Placer une annonce',
  place_ad_meta_description:
    'Annoncez vos services et produits sur le Marché de Ron.',
  create_events_meta_title: 'Créer des événements',
  create_events_meta_description:
    'Faites la promotion de vos événements sur le Marché Ron.',
  create_housing_meta_title: 'Créer un logement',
  create_housing_meta_description:
    'Listez vos offres de logement et connectez-vous avec locataires ou acheteurs.',
  create_secondhand_meta_title: 'Créer une seconde main',
  create_secondhand_meta_description:
    'Vendez vos objets de seconde main facilement sur le Marché Ron.',
  create_jobs_meta_title: 'Créer des emplois',
  create_jobs_meta_description:
    'Annoncez vos offres d’emploi et connectez-vous aux talents.',
  create_city_offer_meta_title: 'Créer une offre urbaine',
  create_city_offer_meta_description:
    'Faites la promotion de vos offres spécifiques à la ville auprès de la communauté Ron.',
  create_create_commercial_meta_title: 'Créer un commercial',
  create_create_commercial_meta_description:
    'Annoncez votre entreprise sur la plateforme de Ron.',
};

const de_meta = {
  community_home_meta_title: 'Ron Community',
  community_home_meta_description:
    'Entdecken Sie Ron ORP: Ihre Plattform für Community und Lifestyle.',
  rons_tips_home_meta_title: 'Rons Tipps',
  rons_tips_home_meta_description:
    'Tipps und Anleitungen für das Stadtleben und mehr entdecken.',
  market_home_meta_title: 'Ron Markt',
  market_home_meta_description:
    'Kaufen, verkaufen und verbinden Sie sich auf dem Ron Markt.',
  rons_tips_guides_meta_title: 'Guides',
  rons_tips_guides_meta_description:
    'Umfassende Guides für Ihr urbanes Leben bei Rons Tipps.',
  rons_tips_family_life_meta_title: 'Familienleben',
  rons_tips_family_life_meta_description:
    'Entdecken Sie das Beste aus dem Familienleben mit Rons Tipps.',
  rons_tips_family_life_diy_meta_title: 'DIY',
  rons_tips_family_life_diy_meta_description:
    'Entdecken Sie das Beste aus dem Familienleben mit Rons Tipps.',
  rons_tips_family_life_finance_meta_title: 'Finanz',
  rons_tips_family_life_finance_meta_description:
    'Entdecken Sie das Beste aus dem Familienleben mit Rons Tipps.',
  rons_tips_family_life_parenthood_meta_title: 'Eltern',
  rons_tips_family_life_parenthood_meta_description:
    'Entdecken Sie das Beste aus dem Familienleben mit Rons Tipps.',
  rons_tips_family_life_podcasts_meta_title: 'Podcasts',
  rons_tips_family_life_podcasts_meta_description:
    'Entdecken Sie das Beste aus dem Familienleben mit Rons Tipps.',
  rons_tips_family_life_trips_meta_title: 'Reisen',
  rons_tips_family_life_trips_meta_description:
    'Entdecken Sie das Beste aus dem Familienleben mit Rons Tipps.',
  rons_tips_family_life_inspiration_meta_title: 'Inspiration',
  rons_tips_family_life_inspiration_meta_description:
    'Entdecken Sie das Beste aus dem Familienleben mit Rons Tipps.',
  rons_tips_city_life_meta_title: 'Stadtleben',
  rons_tips_city_life_meta_description:
    'Das Beste aus dem Stadtleben mit entdecken.',
  rons_tips_whats_up_meta_title: 'Was läuft',
  rons_tips_whats_up_meta_description:
    'Bleiben Sie über Events und Neuigkeiten in Ihrer Stadt informiert.',
  rons_tips_balance_day_meta_title: 'Balance Day',
  rons_tips_balance_day_meta_description:
    'Finden Sie Balance mit praktischen Tipps von Ron.',
  rons_tips_pastime_meta_title: 'Freizeit',
  rons_tips_pastime_meta_description:
    'Entdecken Sie unterhaltsame Freizeitideen mit Rons Tipps.',
  rons_tips_ron_voyage_meta_title: 'Ron Reise',
  rons_tips_ron_voyage_meta_description:
    'Ihr Guide für spannende Reisen mit Ron.',
  rons_tips_roncast_meta_title: 'Roncast',
  rons_tips_roncast_meta_description:
    'Einschalten und inspirieren lassen mit Roncast.',
  rons_tips_rooftop_day_meta_title: 'Rooftop Day',
  rons_tips_rooftop_day_meta_description:
    'Feiern Sie den Rooftop Day mit Rons exklusiven Tipps.',
  rons_tips_streetfood_tour_meta_title: 'Streetfood Tour',
  rons_tips_streetfood_tour_meta_description:
    'Entdecken Sie Streetfood mit Rons Empfehlungen.',
  rons_tips_whats_happening_meta_title: 'Was läuft',
  rons_tips_whats_happening_meta_description:
    'Bleiben Sie über Events und Neuigkeiten in Ihrer Stadt informiert.',
  rons_tips_rons_events_meta_title: 'Ron Events',
  rons_tips_rons_events_meta_description:
    'Entdecken Sie die Ron Events und nehmen Sie an Wettbewerben teil.',
  rons_tips_food_and_shop_meta_title: 'Essen und Shoppen',
  rons_tips_food_and_shop_meta_description:
    'Die besten Orte zum Essen und Shoppen entdecken.',
  rons_tips_food_and_drinks_meta_title: 'Essen und Trinken',
  rons_tips_food_and_drinks_meta_description:
    'Tolle kulinarische Erlebnisse entdecken mit Rons Tipps.',
  rons_tips_new_in_town_meta_title: 'Neu in der Stadt',
  rons_tips_new_in_town_meta_description:
    'Entdecken Sie Ihre neue Stadt mit Rons Tipps.',
  rons_tips_shopping_meta_title: 'Einkaufen',
  rons_tips_shopping_meta_description: 'Schlau shoppen mit Rons Tipps.',
  rons_tips_contest_meta_title: 'Wettbewerb',
  rons_tips_contest_meta_description:
    'Nehmen Sie an spannenden Wettbewerben teil mit Rons Tipps.',
  rons_tips_people_meta_title: 'Leute',
  rons_tips_people_meta_description:
    'Geschichten und Einblicke in die Ron Community.',
  rons_tips_out_of_town_meta_title: 'Unterwegs',
  rons_tips_out_of_town_meta_description:
    'Entdecken Sie Orte außerhalb der Stadt mit Rons Tipps.',
  about_ron_meta_title: 'Über Ron',
  about_ron_meta_description:
    'Erfahren Sie mehr über Ron und die Vision hinter der Plattform.',
  terms_and_conditions_meta_title: 'Termes et conditions | Ron Orp',
  terms_and_conditions_meta_description:
    'Comprenez les termes et conditions d’utilisation de Ron Orp.',
  privacy_policy_meta_title: 'Ron ORP | Datenschutzrichtlinien',
  privacy_policy_meta_description:
    'Lisez notre politique de confidentialité pour comprendre comment vos données sont protégées.',
  advertise_on_ron_orp_meta_title: 'Werbung auf Ron Orp',
  advertise_on_ron_orp_meta_description:
    'Erreichen Sie Ihre Zielgruppe mit Werbung auf Ron Orp.',
  place_ad_on_website_meta_title: 'Anzeige auf der Website',
  place_ad_on_website_meta_description:
    'Schalten Sie Ihre Anzeige direkt auf der Website von Ron Orp.',
  post_in_newsletter_meta_title: 'Im Newsletter posten',
  post_in_newsletter_meta_description:
    'Platzieren Sie Ihre Anzeige im beliebten Ron-Newsletter.',
  top_placement_meta_title: 'Top-Platzierung',
  top_placement_meta_description:
    'Sorgen Sie für maximale Sichtbarkeit mit Top-Platzierung.',
  start_new_discussion_meta_title: 'Communauté | Diskussion starten',
  start_new_discussion_meta_description:
    'Engagez des discussions avec des membres de la communauté.',
  create_story_meta_title: 'Communauté | Geschichte erstellen',
  create_story_meta_description:
    'Partagez votre histoire avec la communauté Ron.',
  create_survey_meta_title: 'Umfrage erstellen | Gemeinschaft',
  create_survey_meta_description: 'Umfrage erstellen | Gemeinschaft',
  create_phototrip_meta_title: 'Communauté | Phototrip erstellen',
  create_phototrip_meta_description:
    'Montrez vos talents en photographie avec une phototrip.',
  create_together_event_meta_title: 'Communauté | Ereignis erstellen',
  create_together_event_meta_description:
    'Organisez des événements et connectez-vous avec d’autres.',
  love_registration_meta_title: 'Liebesregistrierung',
  love_registration_meta_description:
    'Finden Sie Liebe und bedeutsame Verbindungen mit Ron.',
  place_ad_meta_title: 'Anzeige schalten',
  place_ad_meta_description:
    'Faites la publicité de vos services et produits sur le Marché Ron.',
  create_events_meta_title: 'Veranstaltungen erstellen',
  create_events_meta_description:
    'Faites la promotion de vos événements sur le Marché Ron.',
  create_housing_meta_title: 'Wohnen erstellen',
  create_housing_meta_description:
    'Publiez vos offres de logement et connectez-vous mit Käufern.',
  create_secondhand_meta_title: 'Secondhand erstellen',
  create_secondhand_meta_description:
    'Vendez facilement vos articles d’occasion sur le Marché Ron.',
  create_jobs_meta_title: 'Jobs erstellen',
  create_jobs_meta_description:
    'Publiez des offres d’emploi et connectez-vous mit Talenten.',
  create_city_offer_meta_title: 'Stadtangebote erstellen',
  create_city_offer_meta_description:
    'Promouvez vos offres urbaines à la communauté Ron.',
  create_create_commercial_meta_title: 'Werbung erstellen',
  create_create_commercial_meta_description:
    'Faites la publicité de votre entreprise mit Ron.',
};

export function getSeoMeta(city) {
  const lang = getLangBasedOnCity(city);
  if (lang === 'fr') return fr_meta;
  else if (lang === 'de') return de_meta;
  else return en_meta;
}
