export function getBrunoAdIds(data = []) {
  const ads = data.filter((item) => item?.discussion_type === 'ads');
  const map = {};
  const result = [];
  for (const item of ads) {
    if (map[item.id]) {
      map[item.id] += 1;
    } else {
      map[item.id] = 1;
    }
  }

  for (const key in map) {
    if (map[key] >= 3) {
      result.push(key);
    }
  }

  return result;
}

export const sideRightSizeMappingData = [
  {
    viewport: [1024, 0],
    sizes: [
      [160, 600],
      [300, 600],
      [500, 1000],
    ],
  }, // Desktop
  {
    viewport: [768, 0],
    sizes: [[160, 600]],
  }, // Tablet
  { viewport: [0, 0], sizes: [[160, 600]] }, // Mobile
];

export const topSizeMappingData = [
  {
    viewport: [1024, 0],
    sizes: [
      [994, 118],
      [994, 250],
      [728, 90],
      [970, 250],
      [994, 500],
    ],
  },
  {
    viewport: [970, 0],
    sizes: [
      [728, 90],
      [970, 250],
    ],
  },
  { viewport: [0, 0], sizes: [[728, 90]] },
];

export const horizontalSizeMappingData = [
  {
    viewport: [1024, 0],
    sizes: [
      [336, 280],
      [300, 600],
      [300, 250],
      [320, 416],
      [320, 100],
    ],
  },
  {
    viewport: [970, 0],
    sizes: [
      [336, 280],
      [300, 600],
      [300, 250],
      [320, 416],
      [320, 100],
    ],
  },
  {
    viewport: [0, 0],
    sizes: [
      [336, 280],
      [300, 600],
      [300, 250],
      [320, 416],
      [320, 100],
    ],
  },
];

export const defaultTopSize = [728, 90];
export const defaultSideRightSize = [160, 600];
export const defaultHorizontalSize = [300, 250];

export const ronsTipsHomeGoogleAdsKeyValues = {
  channel_front: 'front',
  channel_ros: 'rons-tipps',
};

export const communityHomeGoogleAdsKeyValues = {
  channel_front: 'front',
  channel_ros: 'community',
};

export const marketHomeGoogleAdsKeyValues = {
  channel_front: 'front',
  channel_ros: 'market-places',
};

export const GOOGLE_ADS_RONS_TIPS_CHANNEL = 'Tipps';
export const GOOGLE_ADS_COMMUNITY_CHANNEL = 'Community';
export const GOOGLE_ADS_MARKET_CHANNEL = 'Markt';

export function getGoogleAdsChannel(router) {
  if (router.pathname.includes('/community')) {
    return GOOGLE_ADS_COMMUNITY_CHANNEL;
  } else if (router.pathname.includes('/market')) {
    return GOOGLE_ADS_MARKET_CHANNEL;
  } else {
    return GOOGLE_ADS_RONS_TIPS_CHANNEL;
  }
}

export function getGoogleAdsKeyValues(router) {
  if (router.pathname.includes('/community')) {
    return communityHomeGoogleAdsKeyValues;
  } else if (router.pathname.includes('/market')) {
    return marketHomeGoogleAdsKeyValues;
  } else {
    return ronsTipsHomeGoogleAdsKeyValues;
  }
}

export function getSubChannel(path) {
  if (path.includes('/market/jobs')) return 'jobs';
  else if (path.includes('/market/events')) return 'events';
  else if (path.includes('/market/cityoffer')) return 'cityoffer';
  else if (path.includes('/market/secondhand')) return 'secondhand';
  else if (path.includes('/market/housing')) return 'housing';
  else if (path.includes('/community/categories/15')) return 'love';
  else if (path.includes('/community/categories/17')) return 'phototrip';
  else if (path.includes('/community/categories/14')) return 'together';
  else if (path.includes('/community/categories/13')) return 'fragschweiz';
  else if (path.includes('/community/categories/2')) return 'ask_zurich_en';
  else if (path.includes('/community/categories/6')) return 'frag_basel';
  else if (path.includes('/community/categories/7')) return 'frag_bern';
  else if (path.includes('/community/categories/12')) return 'züri_familie';
  else if (path.includes('/community/categories/8')) return 'frag_luzern';
  else if (path.includes('/community/categories/4')) return 'ask_romandie';
  else if (path.includes('/community/categories/9')) return 'frag_st.gallen';
  else if (path.includes('/community/categories/10')) return 'frag_winterthur';
  else if (path.includes('/community/categories/3')) return 'fragzuerich';
  else if (path.includes('/community/categories/18'))
    return 'ronsters_help_ronsters';
  else if (path.includes('/community/categories/16')) return 'my_area';
  else if (path.includes('/rons-tips/guides')) return 'guides';
  else if (path.includes('/rons-tips/food-shopping'))
    return 'food-and-shopping';
  else if (path.includes('/rons-tips/people')) return 'people';
  else if (path.includes('/rons-tips/out-of-town')) return 'out-of-town';
  else if (path.includes('/rons-tips/contest')) return 'contest';
}

export const destroyAdSlots = (slotIds) => {
  const slotsToDestroy = window.googletag
    .pubads()
    .getSlots()
    .filter((slot) => slotIds.includes(slot.getSlotElementId()));

  if (slotsToDestroy.length > 0) {
    window.googletag.destroySlots(slotsToDestroy);
  }
};

export const getLanguageForGoogleAds = (lang) => {
  if (lang == 'en') return 'english';
  else if (lang == 'fr') return 'french';
  else return 'german';
};
