import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from '../../../../utils';
import { getProfileId } from '../../../../utils/authUtils';
import { instance } from '../../../axios/axios';

export const getSubscriptionsPackages = createAsyncThunk(
  `subscriptions/getSubscriptionsPackages`,
  async ({ city_id = 1, package_type, time_period }) => {
    try {
      const res = await instance.get(
        `/subscription/package-list?time_period=${time_period}&package_type=${package_type}&profile_id=${getProfileId()}&city_id=${city_id}`,
        getHeaders()
      );

      

      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const upgradeSubscriptionPackage = createAsyncThunk(
  `subscriptions/upgradeSubscriptionPackage`,
  async (payload) => {
    try {
      const res = await instance.post(
        `/subscription/upgrade-package`,
        payload,
        getHeaders()
      );

      

      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err.response);
      return err.response;
    }
  }
);

export const purchaseSubscriptionPackage = createAsyncThunk(
  `subscriptions/purchaseSubscriptionPackage`,
  async (payload) => {
    try {
      const res = await instance.post(
        `/subscription/purchase-package`,
        payload,
        getHeaders()
      );

      

      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err.response);
      return err.response;
    }
  }
);

export const cancelCurrentSubscription = createAsyncThunk(
  `subscriptions/cancelCurrentSubscription`,
  async (payload) => {
    try {
      const res = await instance.post(
        `/cancel-subscription`,
        payload,
        getHeaders()
      );

      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err.response);
      return err.response;
    }
  }
);

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: {
    packages: null,
    loading: false,
  },
  reducers: {
    setSelectedCity: (state, action) => {
      localStorage.setItem('selectedCity', JSON.stringify(action.payload));
      state.selectedCity = action.payload;
    },
  },
  extraReducers: {
    [getSubscriptionsPackages.pending]: (state) => {
      state.loading = true;
    },
    [getSubscriptionsPackages.fulfilled]: (state, action) => {
      state.packages = action?.payload;
      state.loading = false;
    },
    [getSubscriptionsPackages.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { setSelectedCity } = subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;
