import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { instance } from '../../../axios/axios';

const moduleName = 'seo';
const apiRoute = `/seo-config`;

export const getSeoConfigAsync = createAsyncThunk(
  `${moduleName}/getSeoConfigAsync`,
  async (payload) => {
    const response = await instance.get(apiRoute);

    const data = response.data[0];
    
    return data;
  }
);

const seoSlice = createSlice({
  name: 'seo',
  initialState: {
    data: null,
    loading: false,
  },
  reducers: {
    setSelectedCity: (state, action) => {
      
      state.selectedCity = action.payload;
    },
  },
  extraReducers: {
    [getSeoConfigAsync.pending]: (state) => {
      state.citiesLoading = true;
    },
    [getSeoConfigAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getSeoConfigAsync.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { setSelectedCity } = seoSlice.actions;
export default seoSlice.reducer;
