import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { getLangBasedOnCity, getLocaleString } from '../../utils';
import { checkIsAuthenticated } from '../../utils/authUtils';
import { LOGIN_SIGNUP_ROUTE } from '../../utils/routes';

export default function useLoginRoute() {
  const router = useRouter();
  const { city } = router?.query;
  const isAuthenticated = checkIsAuthenticated();
  const lang = getLangBasedOnCity(city);
  const selectedCity = useSelector(({ cities }) => cities.selectedCity);
  const localeString = getLocaleString(selectedCity?.id);

  const getLoginRoute = () => {
    if (router?.pathname === '/') {
      return LOGIN_SIGNUP_ROUTE('/', lang);
    } else if (router?.pathname === '/community/categories/[categoryId]') {
      return LOGIN_SIGNUP_ROUTE(
        `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community?city=${city}`,
        lang
      );
    } else {
      return LOGIN_SIGNUP_ROUTE(
        `${process.env.NEXT_PUBLIC_FRONTEND_URL}${router?.asPath}`,
        lang
      );
    }
  };

  const loginRoute = getLoginRoute();

  const redirectToLogin = () => {
    router.push(loginRoute);
  };

  return {
    loginRoute,
    redirectToLogin,
    isAuthenticated,
    selectedCity,
    localeString,
  };
}
