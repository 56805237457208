import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders, getSelectedCity, getUserCity } from '../../../../utils';
import { getProfileId, getUserId } from '../../../../utils/authUtils';
import { instance } from '../../../axios/axios';

export const getCurrentUserRegistered = createAsyncThunk(
  'love/getCurrentUserRegistered',
  async ({
    user_id = '',
    profile_id = '',
    city_id = getUserCity()?.id || getSelectedCity()?.id,
  } = {}) => {
    try {
      if (user_id && profile_id) {
        const res = await instance.get(
          `/forum/love/registration?user_public_id=${user_id}&req_city_id=${city_id}&profile_public_id=${profile_id}`,
          getHeaders()
        );

        return res?.data;
      }
    } catch (err) {
      console.error('Something went wrong', err);
      return null;
    }
  }
);

function getAPIStringForPreferredUsers({
  user_id,
  profile_id,
  city_id,
  sorting,
  min_age,
  max_age,
  figure,
  looking_for,
  min_height,
  max_height,
  star_sign,
  all_results,
  show_matches,
  show_likes,
  take,
  skip,
}) {
  let api = `/forum/love/registration/preferred_users?user_id=${user_id}&city_id=${city_id}&sorting=${sorting}&filter=1&figure=${figure}&looking_for=${looking_for}&min_age=${min_age}&max_age=${max_age}&min_height=${min_height}&max_height=${max_height}&star_sign=${star_sign}&all_results=${all_results}&profile_id=${profile_id}&show_matches=${show_matches}&show_likes=${show_likes}&take=${take}&skip=${skip}`;

  return api;
}

export const getPreferredUsers = createAsyncThunk(
  'love/getPreferredUsers',
  async ({
    user_id = getUserId(),
    profile_id = getProfileId(),
    city_id,
    sorting = 1,
    min_age = 18,
    max_age = 100,
    figure = 'all',
    looking_for = 'all',
    min_height = 100,
    max_height = 300,
    star_sign = 'all',
    all_results = 1,
    show_matches = 0,
    show_likes = 0,
    take = 10,
    skip = 0,
  } = {}) => {
    try {
      const cityId = getUserCity()?.id;
      if (!city_id) city_id = cityId;
      const apiString = getAPIStringForPreferredUsers({
        user_id,
        profile_id,
        city_id,
        sorting,
        min_age,
        max_age,
        figure,
        looking_for,
        min_height,
        max_height,
        star_sign,
        all_results,
        show_matches,
        show_likes,
        take,
        skip,
      });
      const res = await instance.get(apiString);
      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err);
      return null;
    }
  }
);

export const unregisterLoveUser = createAsyncThunk(
  'love/unregisterLoveUser',
  async ({ userId, profileId }) => {
    try {
      const res = await instance.delete(
        `/forum/love/unregister/${userId}?profile_id=${profileId}`,
        getHeaders()
      );
      return res;
    } catch (error) {
      console.error('Something went wrong', error);
    }
  }
);

const loveSlice = createSlice({
  name: 'love',
  initialState: {
    isCurrentUserRegistered: null,
    preferredUsers: null,
    loading: false,
    error: null,
    takeQuizModal: false,
    quizModal: false,
    psychoQuizModal: false,
    showChatPopup: true,
    likedProfile: null,
    chatPopupCount: 0,
    loadingPreferredUsers: false,
    preferredUsersParams: {},
    hasMoreLoveUsers: true,
    skip: 0
  },
  reducers: {
    setTakeQuizModal: (state, action) => {
      state.takeQuizModal = action?.payload;
    },
    setQuizModal: (state, action) => {
      state.quizModal = action?.payload;
    },
    setPsychoQuizModal: (state, action) => {
      state.psychoQuizModal = action?.payload;
    },
    setShowChatPopup: (state, action) => {
      state.showChatPopup = action?.payload;
    },
    setLikedProfile: (state, action) => {
      state.likedProfile = action?.payload;
    },
    setChatPopupCount: (state, action) => {
      state.chatPopupCount = action?.payload;
    },
    setPreferredUsersParams: (state, action) => {
      state.preferredUsersParams = action?.payload;
    },
    setHasMoreLoveUsers: (state, action) => {
      state.hasMoreLoveUsers = action?.payload;
    },
    setSkip: (state, action) => {
      state.skip = action?.payload;
    },
    resetPreferredUsers: (state) => {
      state.preferredUsers = null
    }
  },
  extraReducers: {
    [getCurrentUserRegistered.pending]: (state) => {
      state.loading = true;
    },
    [getCurrentUserRegistered.fulfilled]: (state, action) => {
      state.loading = false;
      state.isCurrentUserRegistered = action?.payload;
    },
    [getCurrentUserRegistered.rejected]: (state) => {
      state.loading = false;
    },
    [getPreferredUsers.pending]: (state) => {
      const { preferredUsers } = state;
      if (preferredUsers === null) {
        state.loadingPreferredUsers = true;
      }
    },
    [getPreferredUsers.fulfilled]: (state, action) => {
      state.loadingPreferredUsers = false;
      const { preferredUsers } = state;
      if (
        preferredUsers === null ||
        preferredUsers?.length === 0
      ) {
        state.preferredUsers = action?.payload;
      } else {
        state.preferredUsers = [...preferredUsers, ...action?.payload];
      }
    },
    [getPreferredUsers.rejected]: (state) => {
      state.loadingPreferredUsers = false;
    },
  },
});

export const {
  setPsychoQuizModal,
  setQuizModal,
  setTakeQuizModal,
  setShowChatPopup,
  setLikedProfile,
  setChatPopupCount,
  setPreferredUsersParams,
  setHasMoreLoveUsers,
  setSkip,
  resetPreferredUsers,
} = loveSlice.actions;
export default loveSlice.reducer;
