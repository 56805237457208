import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from '../../../../utils';
import { getProfileId } from '../../../../utils/authUtils';
import { instance } from '../../../axios/axios';

async function fetchTotalUsers() {
  try {
    const res = await instance.get('/count-users', getHeaders());
    return res?.data;
  } catch (error) {
    console.error('Something went wrong', error);
  }
}

async function fetchTopInfo({city_id}) {
  try {
    const res = await instance.get(
      `/dashboard/top-info?profile_id=${getProfileId()}&city_id=${city_id}`,
      getHeaders()
    );
    return res?.data;
  } catch (error) {
    console.error('Something went wrong', error);
  }
}

async function fetchNewsBox({ city_id }) {
  try {
    if (city_id) {
      const res = await instance.get(
        `/dashboard/news-box?profile_id=${getProfileId()}&city_id=${city_id}`,
        getHeaders()
      );
      return res?.data;
    }

    return null;
  } catch (error) {
    console.error('Something went wrong', error);
  }
}

export const getTotalUsers = createAsyncThunk('getTotalUsers', fetchTotalUsers);
export const getTopInfo = createAsyncThunk('getTopInfo', fetchTopInfo);
export const getNewsBox = createAsyncThunk('getNewsBox', fetchNewsBox);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    total_users: 0,
    avatar_list: null,
    top_info: null,
    news_box: null,
  },

  extraReducers: {
    [getTotalUsers.fulfilled]: (state, action) => {
      state.total_users = action?.payload?.total_users;
      state.avatar_list = action?.payload?.avatar_list;
    },

    [getTopInfo.fulfilled]: (state, action) => {
      state.top_info = action?.payload;
    },

    [getNewsBox.fulfilled]: (state, action) => {
      state.news_box = action?.payload;
    },
  },
});

export default dashboardSlice.reducer;
