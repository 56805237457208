const UnfollowDialog = ({ name, item = 'follower', withoutName = false, lang }) => {
  return (
    <div className='px-[24px] pt-[20px]'>
      <div className='flex items-center justify-between'>
        <div className='text-[20px] text-black'>
          {lang?.common?.unfollow} {item}?
        </div>
        {/* <MdClose size={20} /> */}
      </div>
      <div className='mt-4 flex text-sm'>
        {lang?.rons_tips?.are_you_sure_you_want_to_unfollow}{' '}
        {withoutName ? null : (
          <span className='font-semibold text-black'>“{name}”</span>
        )}
        ?
      </div>
    </div>
  );
};

export default UnfollowDialog;
