import dynamic from 'next/dynamic';
import Image from 'next/legacy/image';
import React from 'react';
import { cn } from '../../../../utils';
import { Skeleton } from '../Skeleton';

const MdOutlineBrokenImage = dynamic(() =>
  import('react-icons/md').then((mod) => mod.MdOutlineBrokenImage)
);

const CustomImage = ({
  src,
  alt = 'photo',
  height = 'h-[171px]',
  width = 'w-full',
  objectFit,
  className,
  fetchPriority = 'high',
  sizes = '928px',
  ...props
}) => {
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  // Check if using max-height
  const isMaxHeight = height.includes('max-h-');

  return (
    <div className={cn(`relative cursor-pointer`, width, height, className)}>
      {isError ? (
        <div
          className={cn(
            'flex h-full w-full items-center justify-center bg-gray-300',
            height,
            width,
            className
          )}
        >
          <MdOutlineBrokenImage className='text-[24px] text-low' />
        </div>
      ) : (
        <>
          {isLoading && (
            <Skeleton
              className={cn('absolute inset-0', className, height, width)}
            />
          )}
          <Image
            {...props}
            unoptimized={
              process.env.NEXT_PUBLIC_BACKEND_URL === 'http://localhost:8000'
                ? true
                : false
            }
            src={src || '/assets/Placeholder.webp'}
            alt={alt}
            layout={isMaxHeight ? 'responsive' : 'fill'}
            width={isMaxHeight ? 1920 : undefined}
            height={isMaxHeight ? 1080 : undefined}
            objectFit={objectFit ? objectFit : 'cover'}
            onError={() => setIsError(true)}
            onLoadingComplete={() => setIsLoading(false)}
            className={className}
            priority={fetchPriority === 'high'}
            sizes={sizes}
            loading={fetchPriority === 'high' ? 'eager' : 'lazy'}
          />
        </>
      )}
    </div>
  );
};

export default CustomImage;
