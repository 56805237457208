import React from 'react';
import Error400 from '../Errors/Error400';
import PageContainer from '../PageContainer/PageContainer';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, error: '', errorInfo: '' };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.error('error console', { error, errorInfo });
    this.setState({error, errorInfo})
  }

  handleReset() {
    this.setState({ hasError: false });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <PageContainer>
          <Error400 handleReset={() => this.handleReset()} />
            {/* <div>
              <p>error- {JSON.stringify(this.state.error)}</p>
              <p>errorInfo - {JSON.stringify(this.state.errorInfo)}</p>
            </div> */}
        </PageContainer>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
