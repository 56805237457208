import { format } from 'date-fns';
import { all_cities, getActiveProfileSubscription } from '.';
import {
  COMPANY_LOCAL_HERO_PACKAGE,
  COMPANY_LOCAL_PACKAGE,
  COMPANY_LOCAL_SUPERHERO_PACKAGE,
  MAX_CHAR_LIMIT_COMMERCIAL_POST_DESCRIPTION,
  MAX_CHAR_LIMIT_COMMERCIAL_POST_DESCRIPTION_WITH_IMAGE,
  VAT_PERCENTAGE,
} from './constant';

import Swal from 'sweetalert2';
import RemoveCurrentDataDialog from '../src/components/Common/AlertDialog/RemoveCurrentDataDialog';

import withReactContent from 'sweetalert2-react-content';
import {
  getProfileId,
  hasPermissionToEditDeleteCommunityMarketPosts,
} from './authUtils';
import { MARKET_HOME_ROUTE } from './routes';

const MySwal = withReactContent(Swal);

export const marketCategories = (lang) => [
  {
    id: 'events',
    name: lang?.market?.events,
    url: '/market/events',
  },
  {
    id: 'housing',
    name: lang?.market?.housing,
    url: '/market/housing',
  },
  {
    id: 'secondhand',
    name: lang?.market?.second_hand,
    url: '/market/secondhand',
  },
  {
    id: 'jobs',
    name: lang?.market?.jobs,
    url: '/market/jobs',
  },
  {
    id: 'cityoffer',
    name: lang?.market?.city_offer,
    url: '/market/cityoffer',
  },
];

export const typeOfEmployment = (lang) => [
  { id: 'internship', value: 'internship', name: lang?.market?.internship },
  {
    id: 'temporary-employment',
    value: 'temporary-employment',
    name: lang?.market?.temp_employment,
  },
  {
    id: 'permanent-position',
    value: 'permanent-position',
    name: lang?.market?.permanent_position,
  },
  { id: 'mini-job', value: 'mini-job', name: lang?.market?.mini_job },
  {
    id: 'freelance-job',
    value: 'freelance-job',
    name: lang?.market?.freelance_job,
  },
  {
    id: 'volunteer-work',
    value: 'volunteer-work',
    name: lang?.market?.volunteer_work,
  },
];

export const getTypeOfEmploymentLabel = (value, lang) => {
  return typeOfEmployment(lang).find((item) => item.value === value)?.name;
};
export const levelOfPosition = (lang) => [
  { id: 'junior', value: 'junior', name: lang?.market?.junior },
  { id: 'medium', value: 'medium', name: lang?.market?.medium },
  { id: 'senior', value: 'senior', name: lang?.market?.senior },
  {
    id: 'leading-function',
    value: 'leading-function',
    name: lang?.market?.leading_function,
  },
];

export const getLevelOfPositions = (value, lang) => {
  return levelOfPosition(lang).find((item) => item.value === value).name;
};

export const daysFilter = (lang) => [
  { value: 'Sunday', label: lang?.market?.sunday, name: 'days_filter' },
  { value: 'Monday', label: lang?.market?.monday, name: 'days_filter' },
  { value: 'Tuesday', label: lang?.market?.tuesday, name: 'days_filter' },
  { value: 'Wednesday', label: lang?.market?.wednesday, name: 'days_filter' },
  { value: 'Thursday', label: lang?.market?.thursday, name: 'days_filter' },
  { value: 'Friday', label: lang?.market?.friday, name: 'days_filter' },
  { value: 'Saturday', label: lang?.market?.saturday, name: 'days_filter' },
];

export const wantToSeeFilters = (lang) => [
  { value: 'wanted', label: lang?.market?.wanted },
  { value: 'offer', label: lang?.market?.offer },
];

export const courseTypeFilters = (lang) => [
  { value: '1', label: lang?.market?.regular },
  { value: '0', label: lang?.common?.one_time },
];

export const employmentTypeFilters = (lang) => [
  { id: 1, value: 'internship', label: lang?.market?.internship },
  {
    id: 2,
    value: 'temporary-employment',
    label: lang?.market?.temp_employment,
  },
  {
    id: 3,
    value: 'permanent-position',
    label: lang?.market?.permanent_position,
  },
  { id: 4, value: 'mini-job', label: lang?.market?.mini_job },
  { id: 5, value: 'freelance-job', label: lang?.market?.freelance_job },
  { id: 6, value: 'volunteer-work', label: lang?.market?.volunteer_work },
];

export const levelOfPositionFilters = (lang) => [
  { id: 1, value: 'junior', label: lang?.market?.junior },
  { id: 2, value: 'medium', label: lang?.market?.medium },
  { id: 3, value: 'senior', label: lang?.market?.senior },
  { id: 4, value: 'leading_function', label: lang?.market?.leading_function },
];

function getDatesOfCurrentYearExcept(excludedDates) {
  const startDate = new Date();
  const endDate = new Date().setDate(startDate.getDate() + 365);

  const excludedSet = new Set(
    excludedDates.map((date) => format(date, 'yyyy-MM-dd'))
  );

  const result = [];
  for (
    let date = startDate;
    date <= endDate;
    date = new Date(date.setDate(date.getDate() + 1))
  ) {
    if (!excludedSet.has(date.toISOString().split('T')[0])) {
      result.push(new Date(date)); // Clone the date object before pushing
    }
  }

  return result;
}

export function generateDisableDate(availableDates) {
  const disabledDates = getDatesOfCurrentYearExcept(availableDates);
  const res = [];
  for (const item of disabledDates) {
    res.push(format(item, 'yyyy-MM-dd'));
  }

  return res;
}

export function getDatesBasedOnCity(cityId) {
  const publish_dates = JSON.parse(localStorage.getItem('publish_dates')) || [];

  for (const item of publish_dates) {
    if (item?.city_id == cityId) {
      return item.dates;
    }
  }

  return [];
}
export function getSelectedCities(selectedCities) {
  return all_cities.filter((city) => selectedCities.includes(city.id));
}

export const freeCommunicationMethods = (lang) => [
  { value: 1, label: lang?.market?.use_chat, name: 'communication_methods' },
];

export const paidCommunicationMethods = (lang) => [
  { value: 2, label: lang?.common?.email, name: 'communication_methods' },
  {
    value: 3,
    label: lang?.common?.phone_number,
    name: 'communication_methods',
  },
];

export function calculateTotal() {
  const published_dates =
    JSON.parse(localStorage.getItem('publish_dates')) || [];

  let total = 0;

  for (const item of published_dates) {
    if (item?.dates) {
      const { dates } = item;
      total += dates.length * 35;
    }
  }

  return Math.round(total);
}

export const methodTooltipMsg =
  'Upgrade your current package to choose this options';

export const placeAdOnWebsite = (lang) => {
  return {
    id: 1,
    name: `<p class='font-bold text-[20px] text-high mt-[16px]'>${lang?.market?.place_advertisement_on_website}</p>`,
    description: lang?.market?.book_my_ad_in_rons_website,
    price: 30,
    place: 'website',
  };
};

export const placeAdOnNL = (lang) => {
  return {
    id: 2,
    name: `<div class='mt-[16px]'><span class='text-[20px] text-high mt-[16px] font-bold'>${lang?.market?.book_advertisements_in_the_newsletter}</span>`,
    description: lang?.market?.book_my_ad_in_rons_nl,
    price: 70,
    place: 'newsletter',
  };
};

export const placeAdOnTop = (lang) => {
  return {
    id: 2,
    name: `<p class='font-bold text-[20px] text-high mt-[16px]'>${lang?.market?.top_position_advertisement}</p>`,
    description: lang?.market?.book_my_ad_in_rons_top_placement,
    price: 100,
    place: 'top',
  };
};

export const boostAdPlans = (lang) => {
  return {
    website: lang?.market?.place_advertisement_on_website,
    newsletter: lang?.market?.book_ad_on_newsletter,
    top: lang?.market?.top_position_advertisement,
  };
};

export const datePickOptions = (lang) => [
  {
    label: lang?.market?.calendar_view_single_multiple,
    value: 'calendar',
  },
  { label: lang?.market?.date_picker_range_select, value: 'date_picker' },
];

export const boostDurations = [
  { id: '30', value: '30', name: '30 days', label: '30 days' },
  { id: '60', value: '60', name: '60 days', label: '60 days' },
  { id: '90', value: '90', name: '90 days', label: '90 days' },
  { id: '120', value: '120', name: '120 days', label: '120 days' },
  { id: '150', value: '150', name: '150 days', label: '150 days' },
  { id: '180', value: '180', name: '180 days', label: '180 days' },
  { id: '210', value: '210', name: '210 days', label: '210 days' },
  { id: '240', value: '240', name: '240 days', label: '240 days' },
  { id: '270', value: '270', name: '270 days', label: '270 days' },
  { id: '300', value: '300', name: '300 days', label: '300 days' },
  { id: '330', value: '330', name: '330 days', label: '330 days' },
  { id: '360', value: '360', name: '360 days', label: '360 days' },
];

export function getNext12Months() {
  const currentDate = new Date();
  let next12Months = [];

  for (let i = 0; i < 12; i++) {
    // Create a new date object for each month
    let newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + i,
      1
    );

    next12Months.push(
      // newDate.toLocaleString('default', { month: 'long', year: 'numeric' })
      newDate.toLocaleString('en-GB', { month: 'long', year: 'numeric' })
    );
  }

  return next12Months;
}

export function getMaxCharacterLimit(files) {
  if (files && files?.length > 0) {
    return MAX_CHAR_LIMIT_COMMERCIAL_POST_DESCRIPTION_WITH_IMAGE;
  } else {
    return MAX_CHAR_LIMIT_COMMERCIAL_POST_DESCRIPTION;
  }
}

export function hasNewGift(topNotifications) {
  if (topNotifications && topNotifications.length > 0) {
    for (const item of topNotifications) {
      if (item?.notification_type === 'giftAd' && item?.read_at === null) {
        return true;
      }
    }
  }

  return false;
}

export function showCurrentDataClearWarning(
  lang,
  msg = 'Are you sure? This will clear your selected dates.'
) {
  return MySwal.fire({
    title: <RemoveCurrentDataDialog msg={msg} lang={lang} />,
    text: '',
    icon: '',
    iconColor: '#000',
    showCancelButton: true,
    confirmButtonColor: '#000',
    confirmButtonText: lang?.market?.yes,
    cancelButtonText: lang?.common?.cancel,
    showCloseButton: true,
    customClass: {
      actions: '',
      cancelButton: 'order-1',
      confirmButton: 'order-2',
      title: 'title-style',
      closeButton: 'close-button-swal',
      popup: '!p-0',
    },
  });
}

export const showAlreadyBoostedAlert = (
  title = 'Already Boosted!',
  text = 'This post is already boosted for this city!'
) => {
  Swal.fire({
    icon: 'warning',
    iconColor: '#000',
    title,
    text,
    timer: 3000,
    showConfirmButton: false,
  });
};

export function getDisabledTill(startDate) {
  const d = new Date(startDate);
  const updatedDate = d.setDate(d.getDate() - 1);
  return updatedDate;
}

export function isCompanySubscribedUser() {
  const companyPremiumPackagesIds = [
    COMPANY_LOCAL_PACKAGE,
    COMPANY_LOCAL_HERO_PACKAGE,
    COMPANY_LOCAL_SUPERHERO_PACKAGE,
  ];
  const subscription = getActiveProfileSubscription();

  if (subscription?.active_package_id) {
    return companyPremiumPackagesIds.includes(subscription.active_package_id);
  }

  return false;
}

export function hasEditPermissionForMarketPost(res, router, city, setLoading) {
  const cityId = Number(res?.payload?.city_id);
  if (res?.payload?.author_info?.profile_id == getProfileId()) {
    setLoading(false);
  } else if (hasPermissionToEditDeleteCommunityMarketPosts(cityId)) {
    setLoading(false);
  } else {
    router.replace(MARKET_HOME_ROUTE(city));
  }
}

export function showSomethingWentWrongMessageDuringPayment(
  msg = 'Something went wrong with your payment. Please try with different payment method'
) {
  return Swal.fire({
    icon: 'error',
    iconColor: '#000',
    title: 'OOPS!',
    text: msg,
    // timer: 3000,
    showConfirmButton: true,
    confirmButtonColor: '#000',
  });
}

export const getSortingDropdownItems = (lang, postType) => {
  const dropdownItems = [
    { name: lang?.common?.sort_by_default, value: 'desc' },
    { name: lang?.common?.price_low_to_high, value: 'PriceLowToHigh' },
    { name: lang?.common?.price_high_to_low, value: 'PriceHighToLow' },
  ];

  const eventDropdownItems = [
    ...dropdownItems,
    {
      name: lang?.market?.earliest_event_date,
      value: 'EarliestEventDate',
    },
  ];

  const jobsDropdownItems = [
    { name: lang?.common?.sort_by_default, value: 'desc' },
  ];

  if (postType === 'events') {
    return eventDropdownItems;
  } else if (postType === 'jobs') {
    return jobsDropdownItems;
  }

  return dropdownItems;
};

export const categoryPageCardData = (lang) => {
  return {
    title: lang?.market?.post_type_home_page,
    description: lang?.market?.top_placement_home_subtitle,
    type: 'home',
  };
};

export const homePageCardData = (lang) => {
  return {
    title: lang?.market?.category_list_page,
    description: lang?.market?.top_placement_category_list_subtitle,
    type: 'category',
  };
};

export const calculateVat = (totalAmount) => {
  let result = (totalAmount * VAT_PERCENTAGE) / (100 + VAT_PERCENTAGE);
  return result.toFixed(5);
};

export const getDefaultPublicationPeriod = () => {
  const postCreationDate = new Date();
  const add30DaysToCurrentDate = postCreationDate.setDate(
    postCreationDate.getDate() + 29
  );
  const convertedNumberToDate = new Date(add30DaysToCurrentDate);
  return format(convertedNumberToDate, 'yyyy-MM-dd');
};

const boostPostPrice = {
  nlPrice: 35,
  websitePrice: 25,
  topPlacementPrice: 55,
};
const boostPostText = (lang) => {
  return {
    nlText: lang?.market?.boost_nl_text,
    websiteText: lang?.market?.boost_website_text,
    topplacementText: lang?.market?.boost_topPlacement_text,
  };
};

export function getBoostPostPrice(ad_place) {
  if (ad_place === 'newsletter') {
    return boostPostPrice.nlPrice.toFixed(2);
  } else if (ad_place === 'website') {
    return boostPostPrice.websitePrice.toFixed(2);
  } else {
    return boostPostPrice.topPlacementPrice.toFixed(2);
  }
}
export function getBoostPostText(ad_place, lang) {
  if (ad_place === 'newsletter') {
    return boostPostText(lang).nlText;
  } else if (ad_place === 'website') {
    return boostPostText(lang).websiteText;
  } else if (ad_place === 'top') {
    return boostPostText(lang).topplacementText;
  } else {
    return '';
  }
}

export const amenities = (lang) => [
  { value: 'apartment', label: lang?.market?.apartment || 'Apartment' },
  {
    value: 'apartment_in_a_new_building',
    label:
      lang?.market?.apartment_in_a_new_building ||
      'Apartment in a new building',
  },
  {
    value: 'apartment_in_a_old_building',
    label:
      lang?.market?.apartment_in_a_old_building ||
      'Apartment in an old building',
  },
  {
    value: 'attic_apartment',
    label: lang?.market?.attic_apartment || 'Attic apartment',
  },
  {
    value: 'balcony_terrace_patio',
    label: lang?.market?.balcony_terrace_patio || 'Balcony/Terrace/Patio',
  },
  {
    value: 'community_room',
    label: lang?.market?.community_room || 'Community room',
  },
  {
    value: 'child_friendly',
    label: lang?.market?.child_friendly || 'Child friendly',
  },
  { value: 'fireplace', label: lang?.market?.fireplace || 'Fireplace' },
  { value: 'loft', label: lang?.market?.loft || 'Loft' },
  { value: 'minergie', label: lang?.market?.minergie || 'Minergie' },
  {
    value: 'electric_car_charge_station',
    label:
      lang?.market?.electric_car_charge_station ||
      'Electric car charging station',
  },
  {
    value: 'garage_parking_space',
    label: lang?.market?.garage_parking_space || 'Garage/Parking space',
  },
  { value: 'lift', label: lang?.market?.lift || 'Lift' },
  {
    value: 'new_building',
    label: lang?.market?.new_building || 'New building',
  },
  {
    value: 'furnished_apartment',
    label: lang?.market?.furnished_apartment || 'Furnished apartment',
  },
  {
    value: 'holiday_apartment',
    label: lang?.market?.holiday_apartment || 'Holiday apartment',
  },
  { value: 'maisonette', label: lang?.market?.maisonette || 'Maisonette' },
  {
    value: 'old_building',
    label: lang?.market?.old_building || 'Old building',
  },
  {
    value: 'pets_allowed',
    label: lang?.market?.pets_allowed || 'Pets allowed',
  },
  {
    value: 'penthouse_apartment',
    label: lang?.market?.penthouse_apartment || 'Penthouse apartment',
  },
  {
    value: 'wheelchair_access',
    label: lang?.market?.wheelchair_access || 'Wheelchair access',
  },
  {
    value: 'studio_bedsit',
    label: lang?.market?.studio_bedsit || 'Studio/bedsit',
  },
  {
    value: 'terrace_apartment',
    label: lang?.market?.terrace_apartment || 'Terrace apartment',
  },
];

export const getAmenityLabel = (value, lang) => {
  return amenities(lang)?.find((amenity) => amenity.value === value)?.label;
};
export const getEventBoosterDescriptionLines = ({
  noImage,
  titleLines,
  isSponsored = false,
  isEventBooster = false,
}) => {

  if (noImage) {
    if (isSponsored) {
      switch (titleLines) {
        case 1:
          return 7;
        case 2:
          return 5;
        case 3:
          return 4;
      }}
      else {
        switch (titleLines) {
          case 1:
            return 9;
          case 2:
            return 8;
          case 3:
            return 8;
        }
      }
    } 
   else {
    switch (titleLines) {
      case 1:
        return 0;
      case 2:
        return 0;
      default:
        return 0;
    }
  }
};

export const getShortGreyboxPostDescriptionLines = ({
  noImage,
  titleLines,
  isSponsored = false,
}) => {

  if (noImage) {
    if (isSponsored) {
      switch (titleLines) {
        case 1:
          return 8;
        case 2:
          return 6;
        case 3:
          return 5;
        default:
          return 5;
      }
    } else {
      switch (titleLines) {
        case 1:
          return 8;
        case 2:
          return 8;
        case 3:
          return 8;
        default:
          return 7;
      }
    }
  } else {
    switch (titleLines) {
      case 1:
        return 3;
      case 2:
        return 2;
      case 3:
        return 2;
      default:
        return 2;
    }
  }
};

export const getLongGreyboxPostDescriptionLines = ({
  noImage,
  titleLines,
  isSpecial,
}) => {
  if (noImage) {
    if (isSpecial) {
      switch (titleLines) {
        case 1:
          return 7;
        case 2:
          return 5;
        case 3:
          return 4;
        default:
          return 4;
      }
    } else {
      switch (titleLines) {
        case 1:
          return 8;
        case 2:
          return 7;
        case 3:
          return 5;
        default:
          return 5;
      }
    }
  } else {
    switch (titleLines) {
      case 1:
        return 1;
      case 2:
        return 0;
      case 3:
        return 0;
      default:
        return 0;
    }
  }
};



export const getDescriptionLineClamp = (descriptionLines) => {
  return descriptionLines
    ? `line-clamp-${descriptionLines} max-h-[${Math.ceil(descriptionLines * 22.4)}px]`
    : 'hidden';
};

export const translatePricingOptions = (lang, option) => {
  switch (option) {
    case 'free':
      return lang?.market?.free || 'Free';
    case 'price':
      return lang?.market?.price || 'Price';
    case 'borrow':
      return lang?.market?.borrow || 'Borrow';
    case 'trading':
      return lang?.market?.trading || 'Trading';
    case 'bidding':
      return lang?.market?.bidding || 'Bidding';
    case 'donation':
      return lang?.market?.donation || 'Donation';
    case 'onRequest':
      return lang?.market?.on_request || 'On request';
    default:
      return option;
  }
};

export function getTogetherEvents() {
  const foundCategories = localStorage.getItem('categories');
  if (foundCategories.includes('id')) {
    const communityCategories = JSON.parse(localStorage.getItem('categories'));
    return communityCategories.find((item) => item?.id == 14);
  }

  return {
    id: 14,
    name: 'Together Events',
  };
}

export function isGermanCity(cityId) {
  const germanCities = [2, 4, 5, 6, 7, 8, 9];
  return germanCities.includes(cityId);
}
