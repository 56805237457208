const RemoveCurrentDataDialog = ({ msg, lang }) => {
  return (
    <div className='!z-[1000] px-[24px] pt-[20px]'>
      <div className='flex items-center justify-between'>
        <div className='text-[20px] text-black'>
          {lang?.common?.are_you_sure}
        </div>
        {/* <MdClose size={20} /> */}
      </div>
      <div className='mt-4 flex text-sm'>{msg}</div>
    </div>
  );
};

export default RemoveCurrentDataDialog;
