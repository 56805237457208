import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from '../../../../utils';
import { getProfileId } from '../../../../utils/authUtils';
import cryptr from '../../../../utils/cryptr';
import { instance } from '../../../axios/axios';

const moduleName = 'common';

export const fileUploadAsync = createAsyncThunk(
  `${moduleName}/fileUploadAsync`,
  async ({ file }, { rejectWithValue }) => {
    try {
      const token = cryptr.decrypt(localStorage.getItem('access_token'));
      const response = await instance.post(
        `/file-upload`,
        { photo_file: file, profile_id: getProfileId() },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFileAsync = createAsyncThunk(
  `${moduleName}/deleteFileAsync`,
  async ({ fileId }) => {
    try {
      await instance.delete(`/delete-file/${fileId}`, getHeaders());
    } catch (err) {
      console.error('Something went worng', err);
    }
  }
);

export const getLanguageAsync = createAsyncThunk(
  `${moduleName}/getLanguageAsync`,
  async ({ city_id = 1 }) => {
    try {
      const response = await instance.get(
        `/front-translataion?city_id=${city_id}`,
        getHeaders()
      );

      

      const data = response.data;

      return data;
    } catch (err) {
      console.error('Something went wrong');
      return null;
    }
  }
);

export const getSubscriberDataAsync = createAsyncThunk(
  `${moduleName}/getSubscriberDataAsync`,
  async (payload) => {
    
    try {
      const response = await instance.get(
        `/get-register-users?city_id=${payload.city_id}&type=${payload?.type}`,
        getHeaders()
      );
      const data = response.data;

      return data;
    } catch (err) {
      console.error('Something went wrong');
      return null;
    }
  }
);

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    uploadedFile: null,
    selectedCity: null,
    cities: null,
    citiesLoading: false,
    loading: false,
    showFileLoader: false,
    uploadError: null,
    skip: 0,
    take: 10,
    loginFirstModalOpen: false,
    isHoveringInfoWindow: null,
    hoveredMarker: null,
    activeMarker: null,
    newPostLocation: null,
    language: null /* Set the default to null after backend is synced */,
    upgradeProfileModal: false,
    subscriberData: null,
    likeData: null,
    highlightItem: null,
    loadingLanguage: false,
    hideTopNav: false,
  },
  reducers: {
    setSelectedCity: (state, action) => {
      state.selectedCity = action.payload;
    },
    setShowFileLoader: (state, action) => {
      state.showFileLoader = action.payload;
    },
    setCustomImageError: (state, action) => {
      state.uploadError = {
        type: 'error',
        msg: action.payload,
      };
    },
    setSkip: (state) => {
      state.skip = 0; // This should be updated
    },
    setTake: (state) => {
      state.take += 10;
    },
    setLoginFirstModalOpen: (state, action) => {
      state.loginFirstModalOpen = action.payload;
    },
    setIsHoveringInfoWindow: (state, action) => {
      state.isHoveringInfoWindow = action.payload;
    },
    setHoveredMarker: (state, action) => {
      state.hoveredMarker = action.payload;
    },
    setActiveMarker: (state, action) => {
      state.activeMarker = action.payload;
    },
    setNewPostLocation: (state, action) => {
      state.isHoveringInfoWindow = action.payload;
    },
    setUpgradeProfileModal: (state, action) => {
      state.upgradeProfileModal = action.payload;
    },
    setCachedLanguage: (state, action) => {
      state.language = action.payload;
    },
    setLikeData: (state, action) => {
      state.likeData = action.payload;
    },
    setHighlightItem: (state, action) => {
      state.highlightItem = action.payload;
    },
    setHideTopNav: (state, action) => {
      state.hideTopNav = action.payload;
    },
  },
  extraReducers: {
    [fileUploadAsync.pending]: (state) => {
      state.loading = true;
    },
    [fileUploadAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.uploadedFile = action.payload;
      state.uploadError = null;
    },
    [fileUploadAsync.rejected]: (state, action) => {
      state.loading = false;
      state.uploadError = action.payload;
    },
    [deleteFileAsync.pending]: (state) => {
      state.loading = true;
    },
    [deleteFileAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteFileAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [getLanguageAsync.pending]: (state, action) => {
      state.loadingLanguage = true;
    },

    [getLanguageAsync.fulfilled]: (state, action) => {
      state.language = action?.payload;
      state.loadingLanguage = false;
    },
    [getLanguageAsync.rejected]: (state, action) => {
      state.loadingLanguage = false;
    },

    [getSubscriberDataAsync.pending]: (state, action) => {
      state.loading = true;
    },

    [getSubscriberDataAsync.fulfilled]: (state, action) => {
      state.subscriberData = action?.payload;
      state.loading = false;
    },
    [getSubscriberDataAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { setShowFileLoader } = commonSlice.actions;

export const {
  setCustomImageError,
  setSkip,
  setTake,
  setLoginFirstModalOpen,
  setIsHoveringInfoWindow,
  setHoveredMarker,
  setActiveMarker,
  setNewPostLocation,
  setUpgradeProfileModal,
  setCachedLanguage,
  setLikeData,
  setHighlightItem,
  setHideTopNav
} = commonSlice.actions;
export default commonSlice.reducer;
