import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAccountType, getHeaders } from '../../../../utils';
import { instance } from '../../../axios/axios';
import { getCityId } from '../../../../utils/community';
import { getProfileId, getUserId } from '../../../../utils/authUtils';

const moduleName = 'footer';
const apiRoute = `/footer-content`;

export const getPageSectionDataAsync = createAsyncThunk(
  `${moduleName}/getPageSectionDataAsync`,
  async (payload) => {
    const response = await instance.get(
      `/footer-content?page=${payload.page}&city_id=${getCityId()}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);

export const getCitywiseSubscriberDataAsync = createAsyncThunk(
  `${moduleName}/getCitywiseSubscriberDataAsync`,
  async (payload) => {
    const response = await instance.get(
      `/get-citywise-newsletter-subscription`
    );

    const data = response.data;

    return data;
  }
);
export const getCitywisePackageAsync = createAsyncThunk(
  `${moduleName}/getCitywisePackageAsync`,
  async ({ cityId, ad_type }) => {
    const response = await instance.get(
      `/get-market-custom-package?city_id=${cityId}&ad_type=${ad_type}`
    );

    const data = response.data;

    return data;
  }
);

export const getCustomersOfRonAsync = createAsyncThunk(
  `${moduleName}/getCustomersOfRonAsync`,
  async ({ cityId }) => {
    const response = await instance.get(
      `/get-ron-customers?user_id=${getUserId()}&profile_id=${getProfileId()}&city_id=${cityId}`
    );
    const data = response.data;
    return data;
  }
);

export const getUpgradedCustomers = createAsyncThunk(
  `${moduleName}/getUpgradedCustomers`,
  async ({ cityId }) => {
    const response = await instance.get(
      `/get-subscribe-users?account_type=${
        getAccountType().toLowerCase() || 'individual'
      }&city_id=${cityId}`
    );

    const data = response.data;
    return data;
  }
);
export const getAboutRonDataAsync = createAsyncThunk(
  `${moduleName}/getAboutRonDataAsync`,
  async () => {
    const response = await instance.get(
      `/get-abouts-info?city_id=${getCityId()}`
    );
    const data = response.data;
    return data;
  }
);
export const getGtcDataAsync = createAsyncThunk(
  `${moduleName}/getGtcDataAsync`,
  async () => {
    const response = await instance.get(
      `/get-terms-and-condition?city_id=${getCityId()}`
    );
    const data = response.data;
    return data;
  }
);

export const getPrivacyPolicyAsync = createAsyncThunk(
  `${moduleName}/getPrivacyPolicyAsync`,
  async () => {
    const response = await instance.get(
      `/get-privacy-policy?city_id=${getCityId()}`
    );
    const data = response.data;
    return data;
  }
);

const footerSlice = createSlice({
  name: moduleName,
  initialState: {
    pageSectionData: null,
    subscriberData: null,
    aboutRonData: null,
    gtcData: null,
    privacyData: null,
    packages: null,
    customers: null,
    loading: false,
    customPackageCart: [],
    gtcLoading: false,
  },
  reducers: {
    addToCustomPackageCart: (state, action) => {
      state.customPackageCart = [...state.customPackageCart, action?.payload];
    },
    clearCustomPackageCart: (state) => {
      state.customPackageCart = [];
    },
    removeFromCustomPackageCart: (state, action) => {
      const temp = [...state.customPackageCart];
      temp.splice(action.payload, 1);
      state.customPackageCart = temp;
    },
  },
  extraReducers: {
    [getPageSectionDataAsync.pending]: (state) => {
      state.loading = true;
    },
    [getPageSectionDataAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.pageSectionData = action.payload;
    },
    [getPageSectionDataAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getCitywiseSubscriberDataAsync.pending]: (state) => {
      state.loading = true;
    },
    [getCitywiseSubscriberDataAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.subscriberData = action.payload;
    },
    [getCitywiseSubscriberDataAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getCitywisePackageAsync.pending]: (state) => {
      state.loading = true;
    },
    [getCitywisePackageAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.packages = action.payload;
    },
    [getCitywisePackageAsync.rejected]: (state) => {
      state.loading = false;
    },

    [getCustomersOfRonAsync.pending]: (state) => {
      state.loading = true;
    },
    [getCustomersOfRonAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.customers = action.payload;
    },
    [getCustomersOfRonAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getAboutRonDataAsync.pending]: (state) => {
      state.loading = true;
    },
    [getAboutRonDataAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.aboutRonData = action.payload;
    },
    [getAboutRonDataAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getGtcDataAsync.pending]: (state) => {
      state.gtcLoading = true;
    },
    [getGtcDataAsync.fulfilled]: (state, action) => {
      state.gtcLoading = false;
      state.gtcData = action.payload;
    },
    [getGtcDataAsync.rejected]: (state) => {
      state.gtcLoading = false;
    },
    [getPrivacyPolicyAsync.pending]: (state) => {
      state.loading = true;
    },
    [getPrivacyPolicyAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.privacyData = action.payload;
    },
    [getPrivacyPolicyAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getUpgradedCustomers.pending]: (state) => {
      state.loading = true;
    },
    [getUpgradedCustomers.fulfilled]: (state, action) => {
      state.loading = false;
      state.customers = action.payload;
    },
    [getUpgradedCustomers.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const {
  addToCustomPackageCart,
  clearCustomPackageCart,
  removeFromCustomPackageCart,
} = footerSlice.actions;

export default footerSlice.reducer;
