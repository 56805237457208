import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from '../../../../utils';
import { getProfileId, getUserId } from '../../../../utils/authUtils';
import { instance } from '../../../axios/axios';

const moduleName = 'follow';
const apiRoute = `/forum/follow`;

export const makePostFavouriteAsync = createAsyncThunk(
  `${moduleName}/makePostFavouriteAsync`,
  async ({ postId }) => {
    
    const response = await instance.post(
      `/market/post-favourite?post_id=${postId}`,
      {},
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);
export const blockSpamAsync = createAsyncThunk(
  `${moduleName}/blockSpamAsync`,
  async (payload) => {
    
    const response = await instance.post(
      `/user-spam-block`,
      payload,
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);
export const followPostAsync = createAsyncThunk(
  `${moduleName}/followPostAsync`,
  async ({ postId, moduleName }) => {
    
    const response = await instance.post(
      `/${moduleName}/post-follow?post_id=${postId}&profile_id=${getProfileId()}`,
      {},
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);
export const alertPostAsync = createAsyncThunk(
  `${moduleName}/alertPostAsync`,
  async ({ payload, moduleName }) => {
    
    const response = await instance.post(
      `/${moduleName}/post-alert`,
      payload,
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);
export const followAuthorAsync = createAsyncThunk(
  `${moduleName}/followAuthorAsync`,
  async ({ authorId, profileId }) => {
    
    const response = await instance.post(
      `/user-follow?follow_user_id=${authorId}&follow_profile_id=${profileId}&profile_id=${getProfileId()}`,
      {},
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);
export const alertUserAsync = createAsyncThunk(
  `${moduleName}/alertUserAsync`,
  async (payload) => {
    
    const response = await instance.post(
      `/user-following-alert`,
      payload,
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);
export const alertCategoryAsync = createAsyncThunk(
  `${moduleName}/alertCategoryAsync`,
  async (payload) => {
    
    const response = await instance.post(
      `/ron-tips/category-follow-alert`,
      payload,
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);
export const getfollowingsAsync = createAsyncThunk(
  `${moduleName}/getfollowingsAsync`,
  async ({ profile_id = '' }) => {
    const response = await instance.get(
      `/user-follow-check?profile_id=${getProfileId() || profile_id}`,
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);

export const followCategoryAsync = createAsyncThunk(
  `${moduleName}/followCategoryAsync`,
  async ({ categoryId, subCategoryId = null }) => {
    
    const response = await instance.post(
      `/forum/category-follow?category_id=${categoryId}&sub_category_id=${subCategoryId}&profile_id=${getProfileId()}`,
      {},
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);
export const followMarketFiltersAsync = createAsyncThunk(
  `${moduleName}/followMarketFiltersAsync`,
  async (payload) => {
    
    const response = await instance.post(
      `/market/filter-follow`,
      payload,
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);
export const unFollowMarketFiltersAsync = createAsyncThunk(
  `${moduleName}/unFollowMarketFiltersAsync`,
  async (payload) => {
    
    const response = await instance.post(
      `/market/filter-unfollow`,
      payload,
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);
export const toogleFilterAlertAsync = createAsyncThunk(
  `${moduleName}/toogleFilterAlertAsync`,
  async (payload) => {
    
    const response = await instance.post(
      `/market/filter-alert`,
      payload,
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);

export const followRonsTipsPostAsync = createAsyncThunk(
  `${moduleName}/followRonsTipsPostAsync`,
  async ({ postId }) => {
    
    const response = await instance.post(
      `/ron-tips/post-follow?post_id=${postId}&user_id=${getUserId()}&profile_id=${getProfileId()}`,
      {},
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);

const followSlice = createSlice({
  name: 'follow',
  initialState: {
    data: null,
    filtersToFollow: null,
    followings: [],
    loading: false,
    error: null,
  },
  reducers: {
    setCommonFilters: (state, action) => {
      
      state.commonFilters = action.payload;
    },
    setFiltersToFollow: (state, action) => {
      
      state.filtersToFollow = action.payload;
    },
  },
  extraReducers: {
    [makePostFavouriteAsync.pending]: (state) => {
      state.loading = true;
    },
    [makePostFavouriteAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [makePostFavouriteAsync.rejected]: (state, action) => {
      state.error = action.error?.message;
    },
    [followPostAsync.pending]: (state) => {
      state.loading = true;
    },
    [followPostAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [followPostAsync.rejected]: (state, action) => {
      state.error = action.error?.message;
    },
    [alertPostAsync.pending]: (state) => {
      state.loading = true;
    },
    [alertPostAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [alertPostAsync.rejected]: (state, action) => {
      state.error = action.error?.message;
    },
    [blockSpamAsync.pending]: (state) => {
      state.loading = true;
    },
    [blockSpamAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [blockSpamAsync.rejected]: (state, action) => {
      state.error = action.error?.message;
    },
    [followAuthorAsync.pending]: (state) => {
      state.loading = true;
    },
    [followAuthorAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [followAuthorAsync.rejected]: (state, action) => {
      state.error = action.error?.message;
    },
    [alertUserAsync.pending]: (state) => {
      state.loading = true;
    },
    [alertUserAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [alertUserAsync.rejected]: (state, action) => {
      state.error = action.error?.message;
    },
    [alertCategoryAsync.pending]: (state) => {
      state.loading = true;
    },
    [alertCategoryAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [alertCategoryAsync.rejected]: (state, action) => {
      state.error = action.error?.message;
    },
    [getfollowingsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getfollowingsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.followings = action.payload;
    },
    [getfollowingsAsync.rejected]: (state, action) => {
      state.error = action.error?.message;
    },
    [followCategoryAsync.pending]: (state) => {
      state.loading = true;
    },
    [followCategoryAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [followCategoryAsync.rejected]: (state, action) => {
      state.error = action.error?.message;
    },
    [followMarketFiltersAsync.pending]: (state) => {
      state.loading = true;
    },
    [followMarketFiltersAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [followMarketFiltersAsync.rejected]: (state, action) => {
      state.error = action.error?.message;
    },

    [unFollowMarketFiltersAsync.pending]: (state) => {
      state.loading = true;
    },
    [unFollowMarketFiltersAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [unFollowMarketFiltersAsync.rejected]: (state, action) => {
      state.error = action.error?.message;
    },

    [toogleFilterAlertAsync.pending]: (state) => {
      state.loading = true;
    },
    [toogleFilterAlertAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [toogleFilterAlertAsync.rejected]: (state, action) => {
      state.error = action.error?.message;
    },
  },
});

export const { setCommonFilters, setFiltersToFollow } = followSlice.actions;
export default followSlice.reducer;
