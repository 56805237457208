import dynamic from 'next/dynamic';

const MdOutlineCancel = dynamic(() =>
  import('react-icons/md').then((mod) => mod.MdOutlineCancel)
);

const DeleteDialog = ({ lang }) => {
  return (
    <div className='flex items-center gap-4 border-b p-4 md:p-8'>
      <MdOutlineCancel className='min-w-[48px] text-[48px] text-red-400 sm:text-[64px] md:min-w-[64px]' />
      <div className='flex flex-col items-center gap-2'>
        <p className='text-[22px] font-semibold'>
          {lang?.common?.are_you_sure_you_want_to_delete ||
            'Are you sure you want to delete?'}
        </p>
        <p className='text-[16px]'>
          {lang?.common?.you_wont_be_able_to_revert_this ||
            "You won't be able to revert this!"}
        </p>
      </div>
    </div>
  );
};



export default DeleteDialog;
