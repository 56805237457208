import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import qs from 'qs';
import { checkIsMobile, getHeaders } from '../../../../utils';
import { getProfileId, getUserId } from '../../../../utils/authUtils';
import { getCityId } from '../../../../utils/community';
import { getTogetherEvents } from '../../../../utils/market';
import { instance } from '../../../axios/axios';

const moduleName = 'marketPost';
const apiRoute = `/market/market-discussion`;

export const getMarketPostsAsync = createAsyncThunk(
  `${moduleName}/getMarketPostsAsync`,
  async ({ discussion_type }) => {
    let apiStr = `/market/posts?is_mobile=${checkIsMobile()}&city_id=${getCityId()}&user_id=${getUserId()}&profile_id=${getProfileId()}`;
    if (discussion_type) {
      apiStr = `/market/posts/${discussion_type}?is_mobile=${checkIsMobile()}&city_id=${getCityId()}&user_id=${getUserId()}&profile_id=${getProfileId()}`;
    }
    const response = await instance.get(apiStr, getHeaders());

    const data = response.data;
    return data;
  }
);

export const getMarketPostByIdAsync = createAsyncThunk(
  `${moduleName}/getMarketPostByIdAsync`,
  async ({ postId }) => {
    const userId = getUserId();
    const response = await instance.get(
      `/market/market-discussion/${postId}?is_mobile=${checkIsMobile()}&user_id=${getUserId()}&profile_id=${getProfileId()}&city_id=${getCityId()}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);
export const getCrowdifyPostByIdAsync = createAsyncThunk(
  `${moduleName}/getCrowdifyPostByIdAsync`,
  async (payload) => {
    const response = await instance.get(
      `/market/category/cityoffer/crowdify?post_id=${
        payload?.postId
      }&user_id=${getUserId()}&profile_id=${getProfileId()}&city_id=${getCityId()}&crowdify_post_type= ${
        payload?.crowdify_post_type
      }&is_social=${payload?.is_social}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);
export const getLatestAdvertisementsAsync = createAsyncThunk(
  `${moduleName}/getLatestAdvertisementsAsync`,
  async () => {
    const response = await instance.get(
      `/market/discussion-post?take=3&user_id=${getUserId()}&profile_id=${getProfileId()}&city_id=${getCityId()}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);

export const getRelatedMarketPostsAsync = createAsyncThunk(
  `${moduleName}/getRelatedMarketPostsAsync`,
  async ({ isCrowdifyPost, postId, take, skip, cityId, sorting_seed = 1 }) => {
    const apiRoute = isCrowdifyPost
      ? `/market/crowdify-related-posts/${postId}?is_mobile=${checkIsMobile()}&user_id=${getUserId()}&profile_id=${getProfileId()}&pageSize=${take}&page=${skip}&city_id=${cityId}&sorting_seed=${sorting_seed}`
      : `/market/related-posts/${postId}?user_id=${getUserId()}&profile_id=${getProfileId()}&pageSize=${take}&page=${skip}&is_mobile=${checkIsMobile()}&city_id=${cityId}&sorting_seed=${sorting_seed}`;
    const response = await instance.get(apiRoute, getHeaders());

    const data = response.data;
    return data;
  }
);

export const getMarketTagsAsync = createAsyncThunk(
  `${moduleName}/getMarketTagsAsync`,
  async ({ filter }) => {
    const response = await instance.get(
      `/market/tags?city_id=${getCityId()}&filter_by=${filter}`,
      getHeaders()
    );

    const data = response.data;
    return data;
  }
);

export const getMarketCategoriesAsync = createAsyncThunk(
  `${moduleName}/getMarketCategoriesAsync`,
  async ({ postType, check_zero_posts = 1, show_together_events = true }) => {
    const response = await instance.get(
      `/market/categories?city_id=${getCityId()}&user_id=${getUserId()}&profile_id=${getProfileId()}&post_type=${postType}&check_zero_posts=${check_zero_posts}`,
      getHeaders()
    );

    const data = response.data?.filter((item) => item?.is_admin_only === 0);
    if (postType === 'events' && show_together_events) {
      data?.push({
        id: 'together_events',
        name: getTogetherEvents()?.name,
      });
    }

    return data;
  }
);

export const searchMarketPostsAsync = createAsyncThunk(
  `${moduleName}/searchMarketPostsAsync`,
  async (payload) => {
    const { sorting_seed = 1, ad_count_id = [], cityId } = payload;
    const response = await instance.get(
      `/${payload.postType}/search?is_mobile=${checkIsMobile()}&keyword=${payload.keyword}&take=${payload.take}&skip=${payload.skip}&discussion_type=${payload.discussion_type}&sorting_seed=${sorting_seed}&ad_count_id=${JSON.stringify(ad_count_id)}&city_id=${cityId}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);

export const getSearchSuggestionsAsync = createAsyncThunk(
  `${moduleName}/getSearchSuggestionsAsync`,
  async (payload) => {
    const response = await instance.get(
      `/${payload.postType}/search?keyword=${payload.keyword}&take=${payload.take}&skip=${payload.skip}&discussion_type=${payload.discussion_type}&is_suggestion=${1}&city_id=${getCityId()}`,
      getHeaders()
    );

    const data = response.data;
    return data;
  }
);

export const getMarketCategoryByIdAsync = createAsyncThunk(
  `${moduleName}/getMarketCategoryByIdAsync`,
  async ({ categoryId }) => {
    const response = await instance.get(
      `/market/category/${categoryId}?city_id=${getCityId()}`,
      getHeaders()
    );

    const data = response.data;
    return data;
  }
);
export const getPostTypePostsAsync = createAsyncThunk(
  `${moduleName}/getPostTypePostsAsync`,
  async ({ postType, cityId }) => {
    const response = await instance.get(
      `/market/category-posts?is_mobile=${checkIsMobile()}&city_id=${cityId}&user_id=${getUserId()}&profile_id=${getProfileId()}&type=${postType}`,
      getHeaders()
    );

    const data = response.data;
    return data;
  }
);
export const getFavouritePostsAsync = createAsyncThunk(
  `${moduleName}/getFavouritePostsAsync`,
  async ({
    postType,
    take,
    skip,
    sort_by,
    tag_id,
    sorting_seed = 1,
    ad_count_id = [],
    cityId,
  }) => {
    const response = await instance.get(
      `/market/favourite-posts?type=${postType}&take=${take}&skip=${skip}&sort_by=${sort_by}&tag_id=${tag_id}&sorting_seed=${sorting_seed}&ad_count_id=${JSON.stringify(ad_count_id)}&city_id=${cityId}&is_mobile=${checkIsMobile()}`,
      getHeaders()
    );

    const data = response.data;
    return data;
  }
);

export const getPostTypeTipsPostsAsync = createAsyncThunk(
  `${moduleName}/getPostTypeTipsPostsAsync`,
  async ({ postType, take, skip, cityId }) => {
    const response = await instance.get(
      `/market/admin-tips?type=${postType}&take=${take}&skip=${skip}&city_id=${cityId}&is_mobile=${checkIsMobile()}`,
      getHeaders()
    );

    const data = response.data;
    return data;
  }
);

export const getPostTypeCategoryPostsAsync = createAsyncThunk(
  `${moduleName}/getPostTypeCategoryPostsAsync`,
  async ({ postType, categoryId, payload, take, skip, sorting_seed = 1 }) => {
    // Filter out null or undefined values from the payload object
    const filteredPayload = Object.fromEntries(
      Object.entries(payload).filter(([key, value]) => value != null)
    );

    if (categoryId) filteredPayload.category_id = categoryId;

    // Convert the filtered payload object to query parameters
    const queryParams = qs.stringify(filteredPayload);

    // Construct the URL with the query parameters
    const url = `/market/category/${postType}?${queryParams}&pageSize=${take}&page=${skip}&sorting_seed=${sorting_seed}&is_mobile=${checkIsMobile()}&city_id=${getCityId()}`;

    const response = await instance.get(url, getHeaders());

    const data = response.data;
    return data;
  }
);
export const checkFilterExistsAsync = createAsyncThunk(
  `${moduleName}/checkFilterExistsAsync`,
  async ({ postType, payload }) => {
    // Filter out null or undefined values from the payload object
    const filteredPayload = Object.fromEntries(
      Object.entries(payload).filter(([key, value]) => value != null)
    );

    // Convert the filtered payload object to query parameters
    const queryParams = qs.stringify(filteredPayload);

    // Construct the URL with the query parameters
    const url = `/market/category/check-filter-exists?discussion_type=${postType}&${queryParams}`;

    const response = await instance.get(url, getHeaders());

    const data = response.data;
    return data;
  }
);

export const createMarketPostAsync = createAsyncThunk(
  `${moduleName}/createMarketPostAsync`,
  async (payload) => {
    const response = await instance.post(`${apiRoute}`, payload, getHeaders());

    const data = response;
    return data;
  }
);
export const editMarketPostAsync = createAsyncThunk(
  `${moduleName}/editMarketPostAsync`,
  async ({ postId, data }) => {
    try {
      const response = await instance.put(
        `${apiRoute}/${postId}`,
        data,
        getHeaders()
      );

      return response;
    } catch (error) {
      return error;
    }
  }
);
export const deleteMarketPostAsync = createAsyncThunk(
  `${moduleName}/deleteCommentAsync`,
  async ({ postId }) => {
    try {
      await instance.delete(`${apiRoute}/${postId}`, getHeaders());
    } catch (err) {
      console.error('Something went worng', err);
    }
  }
);

export const bidPostAsync = createAsyncThunk(
  `${moduleName}/bidPostAsync`,
  async (payload) => {
    const response = await instance.post(
      `/market/bidding`,
      payload,
      getHeaders()
    );

    const data = response;
    return data;
  }
);
export const closeBiddingAsync = createAsyncThunk(
  `${moduleName}/closeBiddingAsync`,
  async (payload) => {
    const response = await instance.post(
      `/market/closing-bid`,
      payload,
      getHeaders()
    );

    const data = response;
    return data;
  }
);

export const getBiddersAsync = createAsyncThunk(
  `${moduleName}/getBiddersAsync`,
  async (payload) => {
    const skip = 0;
    const response = await instance.get(
      `/market/bidding-list/${payload?.postId}?skip=${skip}&take=${payload?.take}`,
      getHeaders()
    );

    const data = response.data;
    return data;
  }
);

export const getAvailableDatesForNewsletterPosting = createAsyncThunk(
  `${moduleName}/getAvailableDatesForNewsletterPosting`,
  async ({ postId = null, cityId, languageArea = 'local', type = '' }) => {
    try {
      const response = await instance.get(
        `/market/check-available-date?post_id=${postId}&city_id=${cityId}&language_area=${languageArea}&type=${type}`,
        getHeaders()
      );
      return response?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const getBookedDatesForWebsitePosting = createAsyncThunk(
  `${moduleName}/getBookedDatesForWebsitePosting`,
  async ({ cityId }) => {
    try {
      const response = await instance.get(
        `/market/check-website-book-date?city_id=${cityId}`,
        getHeaders()
      );
      return response?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const commercialPostBillCalculate = createAsyncThunk(
  `${moduleName}/commercialPostBillCalculate`,
  async ({ payload }) => {
    try {
      const response = await instance.post(
        `/market/commercial-post-calculate`,
        payload,
        getHeaders()
      );
      return response?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const boostPostWebsiteBillCalculate = createAsyncThunk(
  `${moduleName}/boostPostWebsiteBillCalculate`,
  async ({ payload }) => {
    try {
      const response = await instance.post(
        `/market/boost-post-website-calculate`,
        payload,
        getHeaders()
      );
      return response?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const boostPostTopPlaceBillCalculate = createAsyncThunk(
  `${moduleName}/boostPostTopPlaceBillCalculate`,
  async ({ payload }) => {
    try {
      const response = await instance.post(
        `/market/boost-post-topplace-calculate`,
        payload,
        getHeaders()
      );
      return response?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const boostPostNewsletterBillCalculate = createAsyncThunk(
  `${moduleName}/boostPostNewsletterBillCalculate`,
  async ({ payload }) => {
    try {
      const response = await instance.post(
        `/market/boost-post-newsletter-calculate`,
        payload,
        getHeaders()
      );
      return response?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const customPackageCalculate = createAsyncThunk(
  `${moduleName}/customPackageCalculate`,
  async ({ payload }) => {
    try {
      const response = await instance.post(
        `/market/custom-package-calculate`,
        payload,
        getHeaders()
      );

      return response?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const submitBoostCommercialPost = createAsyncThunk(
  `${moduleName}/submitBoostCommercialPost`,
  async (payload) => {
    try {
      const response = await instance.post(
        `/market/boost-post-submit`,
        payload,
        getHeaders()
      );

      return response?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const getTopPlacePosts = createAsyncThunk(
  `${moduleName}/getTopPlacePosts`,
  async ({ cityId, postType }) => {
    try {
      const response = await instance.get(
        `/market/topplace-home-posts?city_id=${cityId}&discussion_type=${postType}`,
        getHeaders()
      );

      return response?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const getWebsitePosts = createAsyncThunk(
  `${moduleName}/getWebsitePosts`,
  async ({ cityId }) => {
    try {
      const response = await instance.get(
        `/market/website-boost-posts?city_id=${cityId}`,
        getHeaders()
      );

      return response?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const getCustomPackages = createAsyncThunk(
  `${moduleName}/getCustomPackages`,
  async ({ ad_type = 'website' }) => {
    try {
      const response = await instance.get(
        `/market/custom-package-purchase-list?type=${ad_type}`,
        getHeaders()
      );

      return response?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const boostCommercialPostCheckout = createAsyncThunk(
  `${moduleName}/boostCommercialPostCheckout`,
  async (payload) => {
    try {
      const response = await instance.post(
        `/market/boost-post-checkout`,
        payload,
        getHeaders()
      );

      return response?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const marketPostCheckout = createAsyncThunk(
  `${moduleName}/marketPostCheckout`,
  async (payload) => {
    try {
      const response = await instance.post(
        `/market/market-post-checkout`,
        payload,
        getHeaders()
      );

      return response?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const checkBoostExists = createAsyncThunk(
  `${moduleName}/checkBoostExists`,
  async ({ city_id, post_id, ad_type, placement }) => {
    try {
      const response = await instance.get(
        `/market/check-boost-exists?city_id=${city_id}&post_id=${post_id}&advertisement_type=${ad_type}&placement=${placement}`,
        getHeaders()
      );

      return response?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const getMyArchivedPosts = createAsyncThunk(
  `${moduleName}/getMyArchivedPosts`,
  async ({ take = 10, skip = 0 }) => {
    const response = await instance.get(
      `/market/my-archive-posts?profile_id=${getProfileId()}&city_id=${getCityId()}&take=${take}&skip=${skip}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);

export const getTopPlaceMentPrice = createAsyncThunk(
  `${moduleName}/getTopPlaceMentPrice`,
  async ({ city_id, post_id }) => {
    const response = await instance.get(
      `/market/top-placement-price?city_id=${city_id}&post_id=${post_id}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);

export const checkAlreadyPostedInHousing = createAsyncThunk(
  `${moduleName}/checkAlreadyPostedInHousing`,
  async () => {
    const response = await instance.get(
      `/market/check-housing-post-exists?user_id=${getUserId()}&profile_id=${getProfileId()}`,
      getHeaders()
    );

    const data = response.data;
    return data;
  }
);

const marketSlice = createSlice({
  name: 'market',
  initialState: {
    data: null,
    categories: null,
    singleCategory: null,
    subcategories: null,
    favPostsloading: false,
    posts: null,
    favPosts: null,
    postTypeTipsPosts: null,
    postTypeTipsPostsLoading: false,
    searchPostsLoading: false,
    searchedResult: null,
    searchSuggestions: null,
    postTypePosts: null,
    filterExists: false,
    postTypeCategoryPosts: null,
    postTypeCategoryPostsLoading: false,
    singlePost: null,
    relatedPosts: null,
    latestAdvertisements: null,
    bidders: null,
    categories: null,
    availableDatesForNewsletterPosting: null,
    filters: null,
    bookedDatesForWebsitePosting: [],
    exclusivePosts: null,
    websitePosts: null,
    websitePostsLoading: false,
    publicationPlan: null,
    postTypePostsLoading: false,
    customPackages: null,
    boostExists: null,
    searchloading: null,
  },
  reducers: {
    setSubcategories: (state, action) => {
      state.subcategories = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    concatePosts: (state, action) => {
      state.postTypeCategoryPosts = action.payload;
    },
    concateFavPosts: (state, action) => {
      state.favPosts = action.payload;
    },
    setSearchSuggestions: (state, action) => {
      state.searchSuggestions = action.payload;
    },
    setPublicationPlan: (state, action) => {
      state.publicationPlan = action.payload;
    },
  },
  extraReducers: {
    [getMarketTagsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getMarketTagsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.tags = action.payload;
    },
    [getMarketTagsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getMarketCategoriesAsync.pending]: (state) => {
      state.loading = true;
    },
    [getMarketCategoriesAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.categories = action.payload;
    },
    [getMarketCategoriesAsync.rejected]: (state) => {
      state.loading = false;
    },
    [searchMarketPostsAsync.pending]: (state) => {
      state.searchPostsLoading = true;
    },
    [searchMarketPostsAsync.fulfilled]: (state, action) => {
      state.searchPostsLoading = false;
      state.searchedResult = action.payload;
    },
    [searchMarketPostsAsync.rejected]: (state) => {
      state.searchPostsLoading = false;
    },
    [getSearchSuggestionsAsync.pending]: (state) => {
      state.searchloading = true;
    },
    [getSearchSuggestionsAsync.fulfilled]: (state, action) => {
      state.searchloading = false;
      state.searchSuggestions = action.payload;
    },
    [getSearchSuggestionsAsync.rejected]: (state) => {
      state.searchloading = false;
    },
    [getMarketCategoryByIdAsync.pending]: (state) => {
      state.loading = true;
    },
    [getMarketCategoryByIdAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.singleCategory = action.payload;
    },
    [getMarketCategoryByIdAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getMarketPostsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getMarketPostsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.posts = action.payload;
    },
    [getMarketPostsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getLatestAdvertisementsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getLatestAdvertisementsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.latestAdvertisements = action.payload;
    },
    [getLatestAdvertisementsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getPostTypePostsAsync.pending]: (state) => {
      state.postTypePostsLoading = true;
    },
    [getPostTypePostsAsync.fulfilled]: (state, action) => {
      state.postTypePostsLoading = false;
      state.postTypePosts = action.payload;
    },
    [getPostTypePostsAsync.rejected]: (state) => {
      state.postTypePostsLoading = false;
    },
    [getFavouritePostsAsync.pending]: (state) => {
      state.favPostsloading = true;
    },
    [getFavouritePostsAsync.fulfilled]: (state, action) => {
      state.favPostsloading = false;
      state.favPosts = action.payload;
    },
    [getFavouritePostsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getPostTypeTipsPostsAsync.pending]: (state) => {
      state.postTypeTipsPostsLoading = true;
    },
    [getPostTypeTipsPostsAsync.fulfilled]: (state, action) => {
      state.postTypeTipsPostsLoading = false;
      state.postTypeTipsPosts = action.payload;
    },
    [getPostTypeTipsPostsAsync.rejected]: (state) => {
      state.postTypeTipsPostsLoading = false;
    },
    [getPostTypeCategoryPostsAsync.pending]: (state) => {
      state.postTypeCategoryPostsLoading = true;
    },
    [getPostTypeCategoryPostsAsync.fulfilled]: (state, action) => {
      state.postTypeCategoryPostsLoading = false;
      state.postTypeCategoryPosts = action.payload;
    },
    [getPostTypeCategoryPostsAsync.rejected]: (state) => {
      state.postTypeCategoryPostsLoading = false;
    },
    [checkFilterExistsAsync.pending]: (state) => {
      state.loading = true;
    },
    [checkFilterExistsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.filterExists = action.payload;
    },
    [checkFilterExistsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getMarketPostByIdAsync.pending]: (state) => {
      state.loading = true;
    },
    [getMarketPostByIdAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.singlePost = action.payload;
    },
    [getMarketPostByIdAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getCrowdifyPostByIdAsync.pending]: (state) => {
      state.loading = true;
    },
    [getCrowdifyPostByIdAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.singlePost = action.payload;
    },
    [getCrowdifyPostByIdAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getRelatedMarketPostsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getRelatedMarketPostsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.relatedPosts = action.payload;
    },
    [getRelatedMarketPostsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [createMarketPostAsync.pending]: (state) => {
      state.loading = true;
    },
    [createMarketPostAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [createMarketPostAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error?.message;
    },
    [editMarketPostAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editMarketPostAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [editMarketPostAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error?.message;
    },
    [deleteMarketPostAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteMarketPostAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteMarketPostAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    [bidPostAsync.pending]: (state) => {
      state.loading = true;
    },
    [bidPostAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [bidPostAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error?.message;
    },
    [closeBiddingAsync.pending]: (state) => {
      state.loading = true;
    },
    [closeBiddingAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [closeBiddingAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error?.message;
    },
    [getBiddersAsync.pending]: (state) => {
      state.loading = true;
    },
    [getBiddersAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.bidders = action.payload;
    },
    [getBiddersAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getAvailableDatesForNewsletterPosting.pending]: (state, action) => {
      state.loading = true;
    },
    [getAvailableDatesForNewsletterPosting.fulfilled]: (state, action) => {
      state.availableDatesForNewsletterPosting = action?.payload;
      state.loading = false;
    },
    [getAvailableDatesForNewsletterPosting.rejected]: (state, action) => {
      state.availableDatesForNewsletterPosting = null;
      state.loading = false;
    },
    [getBookedDatesForWebsitePosting.pending]: (state, action) => {
      state.loading = true;
    },
    [getBookedDatesForWebsitePosting.fulfilled]: (state, action) => {
      state.bookedDatesForWebsitePosting = action?.payload;
      state.loading = false;
    },
    [getBookedDatesForWebsitePosting.rejected]: (state, action) => {
      state.bookedDatesForWebsitePosting = null;
      state.loading = false;
    },
    [getTopPlacePosts.pending]: (state, action) => {
      state.loading = true;
    },
    [getTopPlacePosts.fulfilled]: (state, action) => {
      state.exclusivePosts = action?.payload;
      state.loading = false;
    },
    [getTopPlacePosts.rejected]: (state, action) => {
      state.loading = false;
    },
    [getWebsitePosts.pending]: (state, action) => {
      state.websitePostsLoading = true;
    },
    [getWebsitePosts.fulfilled]: (state, action) => {
      state.websitePosts = action?.payload;
      state.websitePostsLoading = false;
    },
    [getWebsitePosts.rejected]: (state, action) => {
      state.websitePostsLoading = false;
    },
    [getCustomPackages.pending]: (state, action) => {
      state.loading = true;
      state.customPackages = null;
    },
    [getCustomPackages.fulfilled]: (state, action) => {
      state.loading = false;
      state.customPackages = action?.payload;
    },
    [getCustomPackages.rejected]: (state, action) => {
      state.loading = false;
      state.customPackages = null;
    },
    [checkBoostExists.pending]: (state, action) => {
      state.boostExists = null;
    },
    [checkBoostExists.fulfilled]: (state, action) => {
      state.boostExists = action?.payload;
    },
    [checkBoostExists.rejected]: (state, action) => {
      state.boostExists = null;
    },
  },
});

export const {
  setSubcategories,
  setFilters,
  concatePosts,
  concateFavPosts,
  setSearchSuggestions,
  setPublicationPlan,
} = marketSlice.actions;
export default marketSlice.reducer;
