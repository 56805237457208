import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import Cookies from 'js-cookie';
import { getRefreshToken } from '../../utils';
import cryptr from '../../utils/cryptr';

const BASE_URL = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api`;

export const instance = axios.create({
  baseURL: BASE_URL,
});

export const Axios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
});

const refreshAuthLogic = async (failedRequest) => {
  instance
    .post('/token/refresh', {
      refresh_token: getRefreshToken(),
    })
    .then((tokenRefreshResponse) => {
      const accessToken = cryptr.encrypt(tokenRefreshResponse.data.app_token);
      const refreshToken = cryptr.encrypt(
        tokenRefreshResponse.data.refresh_token
      );
      failedRequest.response.config.headers.Authorization = `Bearer ${accessToken}`;

      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', refreshToken);

      return Promise.resolve();
    })
    .catch((error) => {
      localStorage.clear();
      Cookies.remove('userData');
      window.location.replace(`${process.env.NEXT_PUBLIC_BACKEND_URL}/logout`);

      return Promise.reject(error);
    });
};

createAuthRefreshInterceptor(instance, refreshAuthLogic);
