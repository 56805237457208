import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { getUserDetails } from '../../../utils';
import { PRIVATE_PATHS } from '../../../utils/authUtils';
import { RONS_TIPS_ROUTE, YOU_ARE_GREAT_ROUTE } from '../../../utils/routes';
import { useAuth } from '../../hooks/useAuth';
import useLoginRoute from '../../hooks/useLoginRoute';
import GlobalLoader from '../Common/GlobalLoader';

export { RouteGuard };

function RouteGuard({ children }) {
  useAuth();

  const router = useRouter();
  const { loginRoute } = useLoginRoute();
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    if (router.isReady) {
      const userDetails = getUserDetails();
      if (router.pathname === '/' && userDetails?.user_id) {
        router.replace(RONS_TIPS_ROUTE(userDetails?.city_name)).then((res) => {
          if (res) setAuthorized(true);
        });
      } else if (router.pathname === '/' && userDetails === null) {
        setAuthorized(true);
      }
    }
  }, [router.isReady]);

  useEffect(() => {
    // on initial load - run auth check

    authCheck(router.asPath);

    // on route change start - hide page content by setting authorized to false
    // const hideContent = () => setAuthorized(false);
    // router.events.on('routeChangeStart', hideContent);

    // on route change complete - run auth check
    router.events.on('routeChangeComplete', authCheck);

    // unsubscribe from events in useEffect return function
    return () => {
      //   router.events.off('routeChangeStart', hideContent);
      router.events.off('routeChangeComplete', authCheck);
    };
  }, []);

  function checkUserCreds(isPrivatePath) {
    const userDetails = getUserDetails();

    const userData = JSON.parse(localStorage.getItem('userData')) || null;

    if (isPrivatePath && userData === null && userDetails === null) {
      router.replace(loginRoute);
    } else if (
      isPrivatePath &&
      userData?.user_id &&
      !userData?.has_active_profile_details
    ) {
      router.replace(YOU_ARE_GREAT_ROUTE(userData?.city?.name));
    } else if (
      isPrivatePath &&
      userData?.user_id &&
      userData?.has_active_profile_details
    ) {
      setAuthorized(true);
    } else if (!isPrivatePath && router.pathname !== '/') {
      setAuthorized(true);
    }
    // else if (router.pathname !== '/') {
    //   setAuthorized(true);
    // }
  }

  async function authCheck(url) {
    // redirect to login page if accessing a private page and not logged in

    const path = url.split('?')[0];
    const isPrivatePath = PRIVATE_PATHS.includes(path);

    checkUserCreds(isPrivatePath);
  }

  return authorized ? (
    children
  ) : (
    <div className='flex h-screen items-center justify-center'>
      <GlobalLoader />
    </div>
  );
}
