import { createContext, useContext } from 'react';
import {
  ronsTipsCategories,
  ronsTipsCategoryIds,
  ronsTipsFoodAndShopSubCategoryIds,
  ronsTipsGuidesSubCategoryIds,
} from '../../utils/ronsTips';

export const RonsTipsContext = createContext();

export function RonsTipsProvider({ children }) {
  const ronsTipsValue = {
    ronsTipsCategoryIds,
    ronsTipsGuidesSubCategoryIds,
    ronsTipsFoodAndShopSubCategoryIds,
    ronsTipsCategories,
  };
  return (
    <RonsTipsContext.Provider value={ronsTipsValue}>
      {children}
    </RonsTipsContext.Provider>
  );
}

export const useRonsTipsUtils = () => useContext(RonsTipsContext);
