import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { checkIsMobile, getAccountType, getHeaders } from '../../../../utils';
import { getProfileId, getUserId } from '../../../../utils/authUtils';
import { getCityId } from '../../../../utils/community';
import { instance } from '../../../axios/axios';

const moduleName = 'forumPost';
const apiRoute = `/forum/community-discussion`;

export const getUserPostsAsync = createAsyncThunk(
  `${moduleName}/getUserPostsAsync`,
  async (payload) => {
    const response = await instance.get(
      `/user-posts?module_type=${payload.moduleType}&user_public_id=${payload.userId}&profile_public_id=${payload.profileId}&take=${payload.take}&skip=${payload.skip}&is_own_profile=${payload.is_own_profile}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);
export const getUserFavoritePostsAsync = createAsyncThunk(
  `${moduleName}/getUserFavoritePostsAsync`,
  async (payload) => {
    const { sorting_seed = 1, ad_count_id = [] } = payload;
    const response = await instance.get(
      `/user-favourite-posts?module_type=${
        payload.moduleType
      }&user_id=${getUserId()}&profile_id=${getProfileId()}&take=${
        payload.take
      }&skip=${payload.skip}&sorting_seed=${sorting_seed}&ad_count_id=${JSON.stringify(ad_count_id)}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);

export const getForumPostsAsync = createAsyncThunk(
  `${moduleName}/getForumPostsAsync`,
  async () => {
    const userId = getUserId();
    const response = await instance.get(
      `/forum/posts?is_mobile=${checkIsMobile()}&city_id=${getCityId()}&user_id=${userId ? userId : ''}&profile_id=${getProfileId()}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);
export const getRelatedForumPostsAsync = createAsyncThunk(
  `${moduleName}/getRelatedForumPostsAsync`,
  async ({
    postId,
    take,
    skip,
    cityId = 1,
    sorting_seed = 1,
    ad_count_id = [],
  }) => {
    const userId = getUserId();
    const response = await instance.get(
      `/forum/related-posts/${postId}?is_mobile=${checkIsMobile()}&user_id=${userId}&profile_id=${getProfileId()}&take=${take}&skip=${skip}&city_id=${cityId}&sorting_seed=${sorting_seed}&ad_count_id=${JSON.stringify(ad_count_id)}`,
      getHeaders()
    );

    const data = response.data;
    return data;
  }
);

export const getPostsByTagAsync = createAsyncThunk(
  `${moduleName}/getPostsByTagAsync`,
  async (payload) => {
    const { sorting_seed = 1, ad_count_id = [], cityId } = payload;
    const response = await instance.get(
      `/${payload.postType}/tag-posts?is_mobile=${checkIsMobile()}&tag_id=${payload?.tagId}&user_id=${getUserId()}&profile_id=${getProfileId()}&take=${payload.take}&skip=${payload.skip}&sorting_seed=${sorting_seed}&ad_count_id=${JSON.stringify(ad_count_id)}&city_id=${cityId}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);
export const getPhototripPostsByCityAsync = createAsyncThunk(
  `${moduleName}/getPhototripPostsByCityAsync`,
  async ({
    city_id,
    category_id,
    filter,
    take = 3,
    skip = 0,
    sorting_seed = 1,
    ad_count_id = [],
  }) => {
    const response = await instance.get(
      `/forum/phototrip-posts?is_mobile=${checkIsMobile()}&city_id=${city_id}&category_id=${category_id}&user_id=${getUserId()}&profile_id=${getProfileId()}&filter=${filter}&take=${take}&skip=${skip}&sorting_seed=${sorting_seed}&ad_count_id=${JSON.stringify(ad_count_id)}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);
export const getForumPostsByCategoryAsync = createAsyncThunk(
  `${moduleName}/getForumPostsByCategoryAsync`,
  async ({
    city_id,
    category_id,
    filter,
    take,
    skip,
    sorting_seed = 1,
    ad_count_id = [],
  }) => {
    const userId = getUserId();
    const response = await instance.get(
      `/forum/category-posts?is_mobile=${checkIsMobile()}&user_id=${userId}&profile_id=${getProfileId()}&category_id=${category_id}&city_id=${city_id}&filter=${filter}&take=${take}&skip=${skip}&sorting_seed=${sorting_seed}&ad_count_id=${JSON.stringify(ad_count_id)}`,
      getHeaders()
    );

    const data = response.data[0];

    return data;
  }
);
export const getForumPostByIdAsync = createAsyncThunk(
  `${moduleName}/getForumPostByIdAsync`,
  async ({ postId }) => {
    const userId = getUserId();
    const response = await instance.get(
      `/forum/posts/${postId}?user_id=${userId}&profile_id=${getProfileId()}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);

export const getPhototripCategoriesAsync = createAsyncThunk(
  `${moduleName}/getPhototripCategoriesAsync`,
  async ({ city_id }) => {
    const response = await instance.get(
      `/forum/phototrip-category?city_id=${city_id}&profile_id=${getProfileId()}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);
function addUrlsToCategory(categories) {
  let temp_cat = [...categories];
  const final_cat = [];

  for (const item of temp_cat) {
    item.url = `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/categories/${item?.id}`;
    if (getAccountType() === 'Company' && item?.id === 15) {
      continue;
    } else {
      final_cat.push(item);
    }
  }

  return final_cat;
}

export const getCommunityCategoriesAsync = createAsyncThunk(
  `${moduleName}/getCommunityCategoriesAsync`,
  async ({ city_id }) => {
    const response = await instance.get(
      `/forum/categories?city_id=${getCityId()}`,
      getHeaders()
    );

    const data = response.data;
    if (data && data?.length > 0) {
      const categories = addUrlsToCategory(data);
      localStorage.setItem('categories', JSON.stringify(categories));
      return categories;
    }

    return data;
  }
);
export const getCommunityTagsAsync = createAsyncThunk(
  `${moduleName}/getCommunityTagsAsync`,
  async (payload) => {
    const { city_id, filter, community_category_id, take=500, skip=0 } = payload;
    try {
      const response = await instance.get(
        `/forum/tags?city_id=${city_id}&filter_by=${filter}&community_category_id=${community_category_id}&take=${take}&skip=${skip}`,
        getHeaders()
      );

      const data = response.data;

      return data;
    } catch (err) {
      console.error('Something went wrong in tags', err);
      return null;
    }
  }
);

export const getFeaturedForumPostsAsync = createAsyncThunk(
  `${moduleName}/getFeaturedForumPostsAsync`,
  async () => {
    const userId = getUserId();
    const response = await instance.get(
      `/forum/feature-posts?city_id=${getCityId()}&user_id=${userId}&profile_id=${getProfileId()}`
    );
    const data = response.data;
    return data;
  }
);
export const getSliderForumPostsAsync = createAsyncThunk(
  `${moduleName}/getSliderForumPostsAsync`,
  async ({ city_id = 2 }) => {
    const userId = getUserId();
    const response = await instance.get(
      `/forum/slider-posts?city_id=${city_id}&user_id=${userId}&profile_id=${getProfileId()}`
    );
    const data = response.data;
    return data;
  }
);
export const getEventParticipantsAsync = createAsyncThunk(
  `${moduleName}/getEventParticipantsAsync`,
  async ({ postId, skip = 0, take = 10 }) => {
    try {
      const response = await instance.get(
        `/forum/together-participant-list/${postId}?skip=${skip}&take=${take}`,
        getHeaders()
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const participateEventAsync = createAsyncThunk(
  `${moduleName}/participateEventAsync`,
  async (payload) => {
    const response = await instance.post(
      `/forum/together-participant`,
      payload,
      getHeaders()
    );

    const data = response;
    return data;
  }
);
export const EditForumPostAsync = createAsyncThunk(
  `${moduleName}/EditForumPostAsync`,
  async ({ postId, data }) => {
    const response = await instance.put(
      `${apiRoute}/${postId}`,
      data,
      getHeaders()
    );

    return response;
  }
);
export const createForumPostAsync = createAsyncThunk(
  `${moduleName}/createForumPostAsync`,
  async (payload) => {
    const response = await instance.post(`${apiRoute}`, payload, getHeaders());

    const data = response;
    return data;
  }
);

export const deleteForumPostAsync = createAsyncThunk(
  `${moduleName}/deleteCommentAsync`,
  async ({ postId }) => {
    try {
      await instance.delete(`${apiRoute}/${postId}`, getHeaders());
    } catch (err) {
      console.error('Something went worng', err);
    }
  }
);

export const getDuplicatePostsTitleAsync = createAsyncThunk(
  `${moduleName}/getDuplicatePostsTitleAsync`,
  async ({ postTitle }) => {
    try {
      const res = await instance.get(
        `/forum/post-title-check?title=${postTitle}`,
        getHeaders()
      );
      return res;
    } catch (err) {
      console.error('Something went worng', err);
    }
  }
);

export const getForYouPostsAsync = createAsyncThunk(
  `${moduleName}/getForYouPostsAsync`,
  async ({
    postType,
    filter,
    skip,
    take,
    sorting_seed = 1,
    ad_count_id = [],
    cityId,
  }) => {
    try {
      const userId = getUserId();
      const res = await instance.get(
        `/${postType}/for-you-posts?is_mobile=${checkIsMobile()}&filter=${filter}&skip=${skip}&take=${take}&user_id=${userId}&profile_id=${getProfileId()}&city_id=${cityId}&sorting_seed=${sorting_seed}&ad_count_id=${JSON.stringify(ad_count_id)}`,
        getHeaders()
      );

      return res?.data;
    } catch (err) {
      console.error('Something went worng', err);
      return null;
    }
  }
);

export const getSurveyParticipantsList = createAsyncThunk(
  `${moduleName}/getSurveyParticipantsList`,
  async ({ postId }) => {
    try {
      // const userId = getUserId()
      const res = await instance.get(
        `/forum/survey-participant-list/${postId}`,
        getHeaders()
      );

      return res?.data;
    } catch (err) {
      console.error('Something went worng', err);
      return null;
    }
  }
);

export const getFeatureSliderPostCount = createAsyncThunk(
  `${moduleName}/getFeatureSliderPostCount`,
  async () => {
    try {
      const res = await instance.get(
        `/forum/check-feature-slider-limit?city_id=${getCityId()}`,
        getHeaders()
      );
      return res?.data;
    } catch (err) {
      return null;
    }
  }
);

const communitySlice = createSlice({
  name: 'community',
  initialState: {
    data: null,
    userPostsLoading: false,
    userPosts: null,
    posts: null,
    relatedPosts: null,
    singlePost: null,
    tags: null,
    categories: null,
    phototripCategories: null,
    featuredPosts: null,
    sliderPosts: null,
    categoryPosts: null,
    phototripPosts: null,
    tagPosts: null,
    eventParticipants: null,
    loading: false,
    forYouPostsLoading: false,
    error: null,
    forYouPosts: null,
    surveyParticipants: null,
    featureSliderPostCount: null,
    tagPostsLoading: false,
  },
  reducers: {
    setCommunityTagsToNull: (state) => {
      state.tags = null;
    },
    setCommunityCategoriesFromLocalStorage: (state) => {
      state.categories = JSON.parse(localStorage.getItem('categories')) || null;
    },
  },
  extraReducers: {
    [getPhototripCategoriesAsync.pending]: (state) => {
      state.loading = true;
    },
    [getPhototripCategoriesAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.phototripCategories = action.payload;
    },
    [getPhototripCategoriesAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getCommunityCategoriesAsync.pending]: (state) => {
      state.loading = true;
    },
    [getCommunityCategoriesAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.categories = action.payload;
    },
    [getCommunityCategoriesAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getCommunityTagsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getCommunityTagsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.tags = action.payload;
    },
    [getCommunityTagsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getPostsByTagAsync.pending]: (state) => {
      state.tagPostsLoading = true;
    },
    [getPostsByTagAsync.fulfilled]: (state, action) => {
      state.tagPostsLoading = false;
      state.tagPosts = action.payload;
    },
    [getPostsByTagAsync.rejected]: (state) => {
      state.tagPostsLoading = false;
    },
    [getForumPostByIdAsync.pending]: (state) => {
      state.loading = true;
    },
    [getForumPostByIdAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.singlePost = action.payload;
    },
    [getForumPostByIdAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getFeaturedForumPostsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getFeaturedForumPostsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.featuredPosts = action.payload;
    },
    [getFeaturedForumPostsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getSliderForumPostsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getSliderForumPostsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.sliderPosts = action.payload;
    },
    [getSliderForumPostsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getForumPostsByCategoryAsync.pending]: (state) => {
      state.loading = true;
    },
    [getForumPostsByCategoryAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.categoryPosts = action.payload;
    },
    [getForumPostsByCategoryAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getPhototripPostsByCityAsync.pending]: (state) => {
      state.loading = true;
    },
    [getPhototripPostsByCityAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.phototripPosts = action.payload;
    },
    [getPhototripPostsByCityAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getForumPostsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getForumPostsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.posts = action.payload;
    },
    [getForumPostsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getRelatedForumPostsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getRelatedForumPostsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.relatedPosts = action.payload;
    },
    [getRelatedForumPostsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getUserPostsAsync.pending]: (state) => {
      state.userPostsLoading = true;
    },
    [getUserPostsAsync.fulfilled]: (state, action) => {
      state.userPostsLoading = false;
      state.userPosts = action.payload;
    },
    [getUserPostsAsync.rejected]: (state) => {
      state.userPostsLoading = false;
    },
    [getUserFavoritePostsAsync.pending]: (state) => {
      state.userFavPostsLoading = true;
    },
    [getUserFavoritePostsAsync.fulfilled]: (state, action) => {
      state.userFavPostsLoading = false;
      state.userFavoritePosts = action.payload;
    },
    [getUserFavoritePostsAsync.rejected]: (state) => {
      state.userFavPostsLoading = false;
    },
    [createForumPostAsync.pending]: (state) => {
      state.loading = true;
    },
    [getEventParticipantsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.eventParticipants = action.payload;
    },
    [getEventParticipantsAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error?.message;
    },
    [getEventParticipantsAsync.pending]: (state) => {
      state.loading = true;
    },
    [participateEventAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [participateEventAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error?.message;
    },
    [participateEventAsync.pending]: (state) => {
      state.loading = true;
    },
    [EditForumPostAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [EditForumPostAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error?.message;
    },
    [createForumPostAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [createForumPostAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error?.message;
    },
    [deleteForumPostAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteForumPostAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteForumPostAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    [getDuplicatePostsTitleAsync?.rejected]: (state, action) => {
      state.error = action?.error?.message;
    },
    [getForYouPostsAsync.fulfilled]: (state, action) => {
      state.forYouPosts = action?.payload;
      state.forYouPostsLoading = false;
    },
    [getForYouPostsAsync.pending]: (state, action) => {
      state.forYouPostsLoading = true;
    },
    [getForYouPostsAsync.rejected]: (state, action) => {
      state.forYouPostsLoading = false;
      state.error = action?.error?.message;
    },
    [getSurveyParticipantsList.fulfilled]: (state, action) => {
      state.surveyParticipants = action?.payload;
      state.loading = false;
    },
    [getSurveyParticipantsList.pending]: (state, action) => {
      state.loading = true;
    },
    [getSurveyParticipantsList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    },
    [getFeatureSliderPostCount.pending]: (state, action) => {
      state.loading = true;
    },
    [getFeatureSliderPostCount.fulfilled]: (state, action) => {
      state.featureSliderPostCount = action?.payload;
      state.loading = false;
    },
    [getFeatureSliderPostCount.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    },
  },
});

export const {
  setCommunityTagsToNull,
  setCommunityCategoriesFromLocalStorage,
} = communitySlice.actions;
export default communitySlice.reducer;
