import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { checkIsMobile, getHeaders, getUserCity } from '../../../../utils';
import { getProfileId, getUserId } from '../../../../utils/authUtils';
import { instance } from '../../../axios/axios';
import { getCityId } from '../../../../utils/community';

export const getRonsTipsCategoryPosts = createAsyncThunk(
  'ronstips/getRonsTipsCategoryPosts',
  async ({ cityId = null, take = 2, skip = 0 }) => {
    try {
      let res;
      if (cityId === null)
        res = await instance.get(
          `/rons-tips/category-posts?is_mobile=${checkIsMobile()}&take=${take}&skip=${skip}`,
          getHeaders()
        );
      else
        res = await instance.get(
          `/rons-tips/category-posts?is_mobile=${checkIsMobile()}&city_id=${cityId}&user_id=${getUserId()}&profile_id=${getProfileId()}&take=${take}&skip=${skip}`,
          getHeaders()
        );

      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err);
      return null;
    }
  }
);

export const getRonsTipsCategoryPostsForPages = createAsyncThunk(
  'ronstips/getRonsTipsCategoryPostsForPages',
  async ({
    cityId = null,
    categoryId,
    skip = null,
    take = null,
    sorting_seed = 1,
  }) => {
    try {
      let res;

      if (cityId === null)
        res = await instance.get(
          `/rons-tips/category-posts/${categoryId}?is_mobile=${checkIsMobile()}`,
          getHeaders()
        );
      else if (skip !== null && take !== null && cityId !== null) {
        res = await instance.get(
          `/rons-tips/category-posts/${categoryId}?is_mobile=${checkIsMobile()}&city_id=${cityId}&skip=${skip}&take=${take}&sorting_seed=${sorting_seed}`,
          getHeaders()
        );
      } else
        res = await instance.get(
          `/rons-tips/category-posts/${categoryId}?is_mobile=${checkIsMobile()}&city_id=${cityId}`,
          getHeaders()
        );

      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err);
      return null;
    }
  }
);

export const getRonsTipsCategoryMorePostsForPages = createAsyncThunk(
  'ronstips/getRonsTipsCategoryMorePostsForPages',
  async ({
    cityId = null,
    categoryId,
    skip = null,
    take = null,
    sorting_seed = 1,
    page_offset = 0,
    is_category_with_subcategory = false,
  }) => {
    try {
      let res;

      let initialAPIStr = '';

      if (!is_category_with_subcategory) {
        initialAPIStr = '/rons-tips/subcategory-posts';
      } else {
        initialAPIStr = '/rons-tips/category-posts/more';
      }

      if (cityId === null)
        res = await instance.get(
          `${initialAPIStr}/${categoryId}?is_mobile=${checkIsMobile()}`,
          getHeaders()
        );
      else if (skip !== null && take !== null && cityId !== null) {
        res = await instance.get(
          `${initialAPIStr}/${categoryId}?is_mobile=${checkIsMobile()}&city_id=${cityId}&skip=${skip}&take=${take}&sorting_seed=${sorting_seed}&page_offset=${page_offset}`,
          getHeaders()
        );
      } else
        res = await instance.get(
          `${initialAPIStr}/${categoryId}?is_mobile=${checkIsMobile()}&city_id=${cityId}`,
          getHeaders()
        );

      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err);
      return null;
    }
  }
);

export const getContestParticipantsList = createAsyncThunk(
  `ronstips/getContestParticipantsList`,
  async ({ postId }) => {
    try {
      const res = await instance.get(
        `/ron-tips/post-participant?post_id=${postId}`,
        getHeaders()
      );

      return res?.data;
    } catch (err) {
      console.error('Something went worng', err);
      return null;
    }
  }
);

export const getCityWriterFaq = createAsyncThunk(
  `ronstips/getCityWriterFaq`,
  async () => {
    try {
      const { id } = getUserCity();
      const res = await instance.get(
        `/core/citywriter-faqs?city_id=${id}`,
        getHeaders()
      );
      return res?.data;
    } catch (err) {
      console.error('Something went worng', err);
      return null;
    }
  }
);

export const applyForCityWriter = createAsyncThunk(
  `ronstips/applyForCityWriter`,
  async ({ body }) => {
    try {
      const res = await instance.post(
        '/core/citywriter-apply',
        body,
        getHeaders()
      );
      return res?.data;
    } catch (err) {
      console.error('Something went worng', err);
      return null;
    }
  }
);

export const getFollowedCategoriesAndSubcategories = createAsyncThunk(
  `ronstips/getFollowedCategoriesAndSubcategories`,
  async (profileId = '') => {
    try {
      const id = profileId || getProfileId();
      const res = await instance.get(
        `/ron-tips/category-follow?profile_id=${id}`,
        getHeaders()
      );
      return res?.data;
    } catch (err) {
      console.error('Something went worng', err);
      return null;
    }
  }
);

export const followRonsTipsCategoryAndSubcategory = createAsyncThunk(
  `ronstips/followRonsTipsCategoryAndSubcategory`,
  async ({ categoryId = undefined, subcategoryId = undefined }) => {
    try {
      const id = getProfileId();
      let api_str = '';
      if (subcategoryId)
        api_str = `/ron-tips/category-follow?category_id=${subcategoryId}&profile_id=${id}`;
      else if (categoryId)
        api_str = `/ron-tips/category-follow?category_id=${categoryId}&profile_id=${id}`;
      const res = await instance.post(api_str, {}, getHeaders());
      return res?.data;
    } catch (err) {
      console.error('Something went worng', err);
      return null;
    }
  }
);

export const getRonsTipsForYouPostsAsync = createAsyncThunk(
  `ronstips/getRonsTipsForYouPostsAsync`,
  async ({ city_id }) => {
    try {
      const userId = getUserId();
      const res = await instance.get(
        `/ron-tips/for-you-posts?is_mobile=${checkIsMobile()}&user_id=${userId}&profile_id=${getProfileId()}&city_id=${city_id}`,
        getHeaders()
      );

      return res?.data;
    } catch (err) {
      console.error('Something went worng', err);
      return null;
    }
  }
);

export const getListicleDetailsAsync = createAsyncThunk(
  `ronstips/getListicleDetailsAsync`,
  async ({ listicleId }) => {
    try {
      const res = await instance.get(
        `/cms/listicle-preview/${listicleId}`,
        getHeaders()
      );
      return res?.data;
    } catch (err) {
      console.error('Something went worng', err);
      return null;
    }
  }
);

export const getRelatedRonsTipsPostsAsync = createAsyncThunk(
  `ronstips/getRelatedRonsTipsPostsAsync`,
  async ({
    postId,
    take,
    skip,
    cityId = 1,
    sorting_seed = 1,
    ad_count_id = [],
  }) => {
    const apiRoute = `/rons-tips/related-posts/${postId}?is_mobile=${checkIsMobile()}&user_id=${getUserId()}&profile_id=${getProfileId()}&take=${take}&skip=${skip}&city_id=${cityId}&sorting_seed=${sorting_seed}&ad_count_id=${JSON.stringify(ad_count_id)}`;
    const response = await instance.get(apiRoute, getHeaders());

    const data = response.data;

    return data;
  }
);

export const getNewsLetterArchiveItems = createAsyncThunk(
  `ronstips/getNewsLetterArchiveItems`,
  async ({ take = 10, skip = 0, searchKey = '', dateFilter = '' }) => {
    try {
      const apiRoute = `/cms/newsletters-archive?city_id=${getCityId()}&date=${dateFilter}&take=${take}&skip=${skip}&search=${searchKey}`;
      const response = await instance.get(apiRoute, getHeaders());

      const data = response.data;

      return data;
    } catch (error) {
      console.error('Something went wrong', error);
      return error;
    }
  }
);

const ronstipsSlice = createSlice({
  name: 'ronstips',
  initialState: {
    ronsTipsCategoryPosts: null,
    ronsTipsCategoryPostsForPages: null,
    loading: false,
    error: null,
    contestParticipants: null,
    citywriterFaq: null,
    cityWrierApplicationStatus: null,
    followedCategoriesAndSubcategories: null,
    forYouPosts: null,
    listicleDetails: null,
    newsletterArchiveItems: null,
    newsletterArchiveItemsLoading: false,
  },
  reducers: {
    resetRonsTipsPosts: (state) => {
      state.ronsTipsCategoryPosts = null;
    },
    resetNewsLetterArchiveItems: (state) => {
      state.newsletterArchiveItems = null;
    },
  },
  extraReducers: {
    [getRonsTipsCategoryPosts.pending]: (state) => {
      // state.ronsTipsCategoryPosts = null;
      // state.loading = true;
    },
    [getRonsTipsCategoryPosts.fulfilled]: (state, action) => {
      state.ronsTipsCategoryPosts = {
        ...state.ronsTipsCategoryPosts,
        ...action.payload,
      };
      state.loading = false;
    },
    [getRonsTipsCategoryPosts.rejected]: (state, action) => {
      state.error = action?.error;
      state.loading = false;
    },
    [getRonsTipsCategoryPostsForPages.pending]: (state) => {
      state.loading = true;
    },
    [getRonsTipsCategoryPostsForPages.fulfilled]: (state, action) => {
      state.loading = false;
      state.ronsTipsCategoryPostsForPages = action?.payload;
    },
    [getRonsTipsCategoryPostsForPages.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.error;
    },

    [getContestParticipantsList.fulfilled]: (state, action) => {
      state.contestParticipants = action?.payload;
      state.loading = false;
    },
    [getContestParticipantsList.pending]: (state, action) => {
      state.loading = true;
    },
    [getContestParticipantsList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    },
    [getCityWriterFaq.fulfilled]: (state, action) => {
      state.citywriterFaq = action?.payload;
      state.loading = false;
    },
    [getCityWriterFaq.pending]: (state, action) => {
      state.loading = true;
    },
    [getCityWriterFaq.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    },
    [getFollowedCategoriesAndSubcategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.followedCategoriesAndSubcategories = action?.payload;
    },
    [getFollowedCategoriesAndSubcategories.pending]: (state, action) => {
      // state.loading = true;
    },
    [getFollowedCategoriesAndSubcategories.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    },
    [getRonsTipsForYouPostsAsync.fulfilled]: (state, action) => {
      state.forYouPosts = action?.payload;
      state.loading = false;
    },
    [getRonsTipsForYouPostsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getRonsTipsForYouPostsAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    },
    [getListicleDetailsAsync.fulfilled]: (state, action) => {
      state.listicleDetails = action?.payload;
      state.loading = false;
    },
    [getListicleDetailsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getListicleDetailsAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    },
    [getNewsLetterArchiveItems.pending]: (state, action) => {
      state.newsletterArchiveItemsLoading = true;
    },
    [getNewsLetterArchiveItems.fulfilled]: (state, action) => {
      const { newsletterArchiveItems } = state;
      state.newsletterArchiveItemsLoading = false;
      if (newsletterArchiveItems?.length > 0) {
        state.newsletterArchiveItems = [
          ...state?.newsletterArchiveItems,
          ...action?.payload,
        ];
      } else {
        state.newsletterArchiveItems = action?.payload;
      }
    },
    [getNewsLetterArchiveItems.rejected]: (state, action) => {
      state.newsletterArchiveItemsLoading = false;
    },
  },
});

export const { resetRonsTipsPosts, resetNewsLetterArchiveItems } =
  ronstipsSlice.actions;
export default ronstipsSlice.reducer;
