import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from '../../../../utils';
import { getProfileId } from '../../../../utils/authUtils';
import { instance } from '../../../axios/axios';

export const getMyFollowingsAsync = createAsyncThunk(
  `getMyFollowingsAsync`,
  async (payload) => {
    try {
      const response = await instance.get(
        `/user-following-list?profile_id=${getProfileId()}&keyword=${payload.keyword}&take=${payload.take}&skip=${payload.skip}`,
        getHeaders()
      );

      

      const data = response.data;

      return data;
    } catch (err) {
      console.error('Something went wrong', err)
    }
  }
);

export const getMyFollowersAsync = createAsyncThunk(
  `getMyFollowersAsync`,
  async (payload) => {
    try {
      const response = await instance.get(
        `/user-followers-list?profile_id=${getProfileId()}&keyword=${
          payload.keyword
        }&take=${payload.take}&skip=${payload.skip}`,
        getHeaders()
      );

      

      const data = response.data;

      return data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);
export const getMyFollowedCategoriesAsync = createAsyncThunk(
  `getMyFollowedCategoriesAsync`,
  async (payload) => {
    try {
      const response = await instance.get(
        `/ron-tips/category-follow?profile_id=${getProfileId()}`,
        getHeaders()
      );

      const data = response.data;

      return data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);
export const getMyFollowedFiltersAsync = createAsyncThunk(
  `getMyFollowedFiltersAsync`,
  async (payload) => {
    const profileId = getProfileId();

    try {
      const response = await instance.get(
        `/market/get-filter-follow?discussion_type=${payload?.discussion_type}&profile_id=${profileId}`,
        getHeaders()
      );

      

      const data = response.data;

      return data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const getMyFollowedPostsAsync = createAsyncThunk(
  `getMyFollowedPostsAsync`,
  async (payload) => {
    try {
      const response = await instance.get(
        `/${
          payload?.moduleName
        }/post-follow-list?profile_id=${getProfileId()}&keyword=${
          payload.keyword
        }&take=${payload.take}&skip=${payload.skip}`,
        getHeaders()
      );

      

      const data = response.data;

      return data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const unFollowPostAsync = createAsyncThunk(
  `unFollowPostAsync`,
  async ({ post_id }) => {
    const profileId = getProfileId();

    const body = {
      profile_id: profileId,
      post_id,
    };

    try {
      const response = await instance.post(
        `/community-post-remove-follow`,
        body,
        getHeaders()
      );

      

      const data = response.data;

      return data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const unFollowUserAsync = createAsyncThunk(
  `unFollowUserAsync`,
  async ({ following_user_id, following_profile_id }) => {
    const profileId = getProfileId();

    const body = {
      profile_id: profileId,
      following_user_id,
      following_profile_id,
    };

    try {
      const response = await instance.post(
        `/user-remove-following`,
        body,
        getHeaders()
      );

      

      const data = response.data;

      return data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const removeUserFromFollowingAsync = createAsyncThunk(
  `removeUserFromFollowingAsync`,
  async ({ follower_user_id, follower_profile_id }) => {
    const profileId = getProfileId();

    const body = {
      profile_id: profileId,
      follower_user_id,
      follower_profile_id,
    };

    try {
      const response = await instance.post(
        `/user-remove-follower`,
        body,
        getHeaders()
      );

      

      const data = response.data;

      return data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

const myFollowsSlice = createSlice({
  name: 'myFollows',
  initialState: {
    myFollowings: null,
    myFollowers: null,
    followedPosts: null,
    followedFilters: [],
    myfollowedFilters: null,
    myfollowedCategories: [],
    loading: false,
    error: null,
  },
  reducers: {
    setSelectedCity: (state, action) => {
      
      state.selectedCity = action.payload;
    },
    setFollowedFilters: (state, action) => {
      state.followedFilters = action.payload;
    },
  },
  extraReducers: {
    [getMyFollowingsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getMyFollowingsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.myFollowings = action.payload;
      state.error = null;
    },
    [getMyFollowingsAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getMyFollowersAsync.pending]: (state) => {
      state.loading = true;
    },
    [getMyFollowersAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.myFollowers = action.payload;
    },
    [getMyFollowersAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getMyFollowedCategoriesAsync.pending]: (state) => {
      state.loading = true;
    },
    [getMyFollowedCategoriesAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.myfollowedCategories = action.payload;
      state.error = null;
    },
    [getMyFollowedCategoriesAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getMyFollowedFiltersAsync.pending]: (state) => {
      state.loading = true;
    },
    [getMyFollowedFiltersAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.myfollowedFilters = action.payload;
      state.error = null;
    },
    [getMyFollowedFiltersAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getMyFollowedPostsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getMyFollowedPostsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.followedPosts = action.payload;
      state.error = null;
    },
    [getMyFollowedPostsAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setSelectedCity, setFollowedFilters } = myFollowsSlice.actions;
export default myFollowsSlice.reducer;
