import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from '../../../../utils';
import { getProfileId, getUserId } from '../../../../utils/authUtils';
import { instance } from '../../../axios/axios';

const moduleName = 'forumPost';
const apiRoute = `/forum/reply`;

export const getRepliesAsync = createAsyncThunk(
  `${moduleName}/getRepliesAsync`,
  async ({ postId, commentId, postType }) => {
    const response = await instance.get(
      `/${postType}/comment-reply?post_id=${postId}&comment_id=${commentId}&user_id=${getUserId()}&profile_id=${getProfileId()}&skip=0&take=10`,
      getHeaders()
    );

    return response.data;
  }
);

export const postReplyAsync = createAsyncThunk(
  `${moduleName}/postReplyAsync`,
  async ({ payload, postType }) => {
    const response = await instance.post(
      `/${postType}/reply`,
      payload,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);
export const updateReplyAsync = createAsyncThunk(
  `${moduleName}/updateReplyAsync`,
  async ({ replyId, payload, postType }) => {
    const response = await instance.put(
      `/${postType}/comment-reply-update/${replyId}`,
      payload,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);
export const deleteReplyAsync = createAsyncThunk(
  `${moduleName}/deleteReplyAsync`,
  async ({ replyId, postType }) => {
    const response = await instance.delete(
      `/${postType}/comment/${replyId}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);

export const likeReplyAsync = createAsyncThunk(
  `${moduleName}/likeReplyAsync`,
  async ({ postId, replyId, postType }) => {
    const response = await instance.post(
      `/${postType}/comment-like?post_id=${postId}&comment_id=${replyId}&profile_id=${getProfileId()}`,
      {},
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);

const replySlice = createSlice({
  name: 'reply',
  initialState: {
    data: null,
    replies: null,
    loading: false,
    error: null,
  },
  reducers: {
    setReplies: (state, action) => {
      state.replies = null;
    },
  },
  extraReducers: {
    [getRepliesAsync.pending]: (state) => {
      state.loading = true;
    },
    [getRepliesAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.replies = action.payload;
    },
    [getRepliesAsync.rejected]: (state) => {
      state.loading = false;
    },
    [postReplyAsync.pending]: (state) => {
      state.loading = true;
    },
    [postReplyAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [postReplyAsync.rejected]: (state, action) => {
      state.error = action?.error?.message;
    },
    [updateReplyAsync.pending]: (state) => {
      state.loading = true;
    },
    [updateReplyAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateReplyAsync.rejected]: (state, action) => {
      state.error = action?.error?.message;
    },
    [deleteReplyAsync.pending]: (state) => {
      state.loading = true;
    },
    [deleteReplyAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteReplyAsync.rejected]: (state, action) => {
      state.error = action?.error?.message;
    },
    [likeReplyAsync.pending]: (state) => {
      state.loading = true;
    },
    [likeReplyAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [likeReplyAsync.rejected]: (state, action) => {
      state.error = action?.error?.message;
    },
  },
});

export const { setReplies } = replySlice.actions;
export default replySlice.reducer;
