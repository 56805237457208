export default function GlobalLoader() {
  return (
    <video
      autoPlay
      loop
      muted
      playsInline
      className='h-[87px] w-[200px] md:h-[131px] md:w-[300px] 2xl:h-[262px] 2xl:w-[600px]'
    >
      <source src='/assets/global_loader.mp4' type='video/mp4' />
      Your browser does not support the video tag.
    </video>
  );
}
