import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { checkIsMobile, getHeaders } from '../../../../utils';
import { instance } from '../../../axios/axios';

const moduleName = 'ads';

export const getRonTipsHomeAdsAsync = createAsyncThunk(
  `${moduleName}/getRonTipsHomeAdsAsync`,
  async ({cityId}) => {
    const response = await instance.get(
      `/ron-tips/home-ads?city_id=${cityId}&is_mobile=${checkIsMobile()}`
    );
    const data = response.data;
    return data;
  }
);

export const getMarketHomeAdsAsync = createAsyncThunk(
  `${moduleName}/getMarketHomeAdsAsync`,
  async ({cityId}) => {
    const response = await instance.get(
      `/market/home-ads?city_id=${cityId}&is_mobile=1`
    );
    const data = response.data;
    return data;
  }
);

export const postAdImpression = createAsyncThunk(
  `${moduleName}/postAdImpression`,
  async (payload) => {
    
    const response = await instance.post(`/ad-click`, payload, getHeaders());
    
    const data = response?.data;
    return data;
  }
);

const adsSlice = createSlice({
  name: 'ads',
  initialState: {
    ronsTipsHomeAds: null,
    marketHomeAds: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [getRonTipsHomeAdsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getRonTipsHomeAdsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.ronsTipsHomeAds = action.payload;
    },
    [getRonTipsHomeAdsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getMarketHomeAdsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getMarketHomeAdsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.marketHomeAds = action.payload;
    },
    [getMarketHomeAdsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [postAdImpression.pending]: (state) => {
      state.loading = true;
    },
    [postAdImpression.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [postAdImpression.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default adsSlice.reducer;
