import { getUserCity, getUserDetails } from '.';
import cryptr from './cryptr';

export const getProfileData = () => {
  return getUserDetails();
};
export const getLoggedInUser = () => {
  if (typeof window !== 'undefined') {
    return getUserDetails();
  } else return null;
};
export const checkIfPostEditable = ({
  authorId = '',
  authorProfileId = '',
  cityId,
}) => {
  if (typeof window !== 'undefined') {
    const loggedInUserId = getUserDetails()?.user_id;
    const loggedInUserProfileId = getUserDetails()?.profile_id;
    const isAdmin = hasPermissionToEditDeleteCommunityMarketPosts(cityId);
    return (
      (authorId === loggedInUserId &&
        authorProfileId === loggedInUserProfileId) ||
      isAdmin
    );
  } else return false;
};
export const checkIfAuthorIsLoggedInUser = ({
  authorId = '',
  authorProfileId = '',
}) => {
  if (typeof window !== 'undefined') {
    const loggedInUserId = getUserDetails()?.user_id;
    const loggedInUserProfileId = getUserDetails()?.profile_id;

    return (
      authorId === loggedInUserId && authorProfileId == loggedInUserProfileId
    );
  } else return false;
};

export const checkIsUnknownUser = (authorInfo) => {
  if (typeof window !== 'undefined') {
    if (
      authorInfo &&
      (!authorInfo?.name || authorInfo?.name === 'Unknown User')
    ) {
      return true;
    } else {
      return false;
    }
  } else return false;
};

export const checkIsAuthenticated = () => {
  if (typeof window !== 'undefined') {
    const userData = localStorage.getItem('userData') || null;

    if (userData === 'undefined' || userData === 'null' || userData === null) {
      return false;
    } else {
      return true;
    }
  } else return false;
};

export const checkIsAuthenticatedWithUserDetails = () => {
  if (typeof window !== 'undefined') {
    const userData = localStorage.getItem('userData') || null;

    if (userData === 'undefined' || userData === 'null' || userData === null) {
      return false;
    } else if (userData && userData?.has_active_profile_details) {
      return true;
    }
  } else return false;
};

export const checkIfCompanyProfileExists = () => {
  if (typeof window !== 'undefined') {
    const userDetails = getUserDetails();
    const companyProfile = userDetails
      ? userDetails?.profiles.find((item) => item.account_type === 'Company')
      : null;

    if (companyProfile) return companyProfile;
    else return null;
  }
  return null;
};

export const checkIsUserWithoutAddress = () => {
  if (typeof window !== 'undefined') {
    const location = getUserDetails()?.location;
    if (location) return false;
  } else return true;
};
export const getUserLocation = () => {
  if (typeof window !== 'undefined') {
    const userDetails = getUserDetails();
    return userDetails?.location;
  } else return null;
};

export const getMapCenter = () => {
  if (typeof window !== 'undefined') {
    const userDetails = getUserDetails();
    const selectedCity = JSON.parse(localStorage.getItem('selectedCity'));
    if (userDetails?.location)
      return {
        address: userDetails?.location?.address,
        position: {
          lat: userDetails?.location?.latitude,
          lng: userDetails?.location?.longitude,
        },
      };
    else
      return {
        address: selectedCity?.label,
        position: selectedCity?.location,
      };
  } else return null;
};

export const getUserSubscription = () => {
  if (typeof window !== 'undefined') {
    return getUserDetails()?.subscription;
  } else return null;
};

export const redirectIfNotLoggedIn = () => {
  if (typeof window !== 'undefined') {
    const userData = getUserDetails();
    if (userData) return false;
    else
      window.location.replace(
        `${process.env.NEXT_PUBLIC_BACKEND_URL}/login/azure`
      );
  } else
    window.location.replace(
      `${process.env.NEXT_PUBLIC_BACKEND_URL}/login/azure`
    );
};

export const redirectToLogIn = () => {
  window.location.replace(`${process.env.NEXT_PUBLIC_BACKEND_URL}/login/azure`);
};

export const checkIsAdmin = () => {
  if (typeof window !== 'undefined') {
    const user = getUserDetails();
    return user ? user.is_admin == 1 : false;
  } else return false;
};

export const checkIsSuperAdmin = () => {
  if (typeof window !== 'undefined') {
    const userDetails = getUserDetails();
    return userDetails ? userDetails?.roles.includes('SuperAdmin') : false;
  } else return false;
};

export const getUserId = () => {
  if (typeof window !== 'undefined') {
    const user = getUserDetails();
    return user ? user.user_id : null;
  }
  return null;
};
export const getProfileId = () => {
  if (typeof window !== 'undefined') {
    const user = getUserDetails();
    return user ? user?.profile_id : '';
  }
  return '';
};

export const getUserPublicId = () => {
  if (typeof window !== 'undefined') {
    const user = getUserDetails();
    return user ? user.user_public_id : null;
  }
  return null;
};
export const getProfilePublicId = () => {
  if (typeof window !== 'undefined') {
    const user = getUserDetails();
    return user ? user?.profile_public_id : '';
  }
  return '';
};

export const setUserData = (userData) => {
  const accessToken = cryptr.encrypt(userData?.app_token);
  const refreshToken = cryptr.encrypt(userData?.refresh_token);

  delete userData['app_token'];
  delete userData['refresh_token'];
  delete userData['token_expires_at'];
  localStorage.setItem('userData', JSON.stringify(userData));

  localStorage.setItem('access_token', accessToken);
  localStorage.setItem('refresh_token', refreshToken);
  localStorage.setItem('selectedCity', JSON.stringify(userData?.city));
};

const getRoleLabel = (role, lang) => {
  const roleMappings = {
    writer: lang?.common?.writer || 'Writer',
    user: lang?.common?.user || 'User',
    SuperAdmin: lang?.common?.super_admin || 'Super Admin',
    NationalPublisher: lang?.common?.national_publisher || 'National Publisher',
    LocalPublisher: lang?.common?.local_publisher || 'Local Publisher',
    Lector: lang?.common?.lector || 'Lector',
    cityWriterNational:
      lang?.common?.city_writer_national || 'City Writer National',
    CityWriter: lang?.common?.city_writer || 'City Writer',
    AdminTraslation: lang?.common?.admin_translation || 'Admin Translation',
    AdminSales: lang?.common?.admin_sales || 'Admin Sales',
    AdminPublisher: lang?.common?.admin_publisher || 'Admin Publisher',
    AdminEcommerce: lang?.common?.admin_ecommerce || 'Admin E-commerce',
  };

  return roleMappings[role] || role;
};

export function getPrimaryRole(roles, lang) {
  if (!roles || roles.length === 0) {
    return null;
  }

  if (roles.length === 1) {
    return getRoleLabel(roles[0], lang);
  }

  // Filter out "user" and return the first other role
  const filteredRoles = roles.filter((role) => role !== 'user');

  // If no roles left after filtering, return "user" as a fallback
  return filteredRoles.length > 0
    ? getRoleLabel(filteredRoles[0], lang)
    : 'User';
}

export const PRIVATE_PATHS = [
  '/account-settings',
  '/my-favorites',
  '/community/start-discussion',
  '/community/start-discussion/create-story',
  '/community/start-discussion/create-survey',
  '/community/start-discussion/create-phototrip',
  '/community/start-discussion/create-event',
  '/community/start-discussion/edit-story',
  '/community/start-discussion/edit-survey',
  '/community/start-discussion/edit-phototrip',
  '/community/start-discussion/edit-event',
  '/market/place-ad',
  '/market/place-ad/cityoffer',
  '/market/place-ad/commercial',
  '/market/place-ad/events',
  '/market/place-ad/housing',
  '/market/place-ad/jobs',
  '/market/place-ad/secondhand',
  '/market/edit-ad/secondhand/[id]',
  '/market/edit-ad/jobs/[id]',
  '/market/edit-ad/events/[id]',
  '/market/edit-ad/housing/[id]',
  '/market/edit-ad/cityoffer/[id]',
  '/rons-tips/for-you',
  '/community/for-you',
  '/market/for-you',
];

export const isLocalPublisher = () => {
  const userDetails = getUserDetails();
  if (userDetails !== null && userDetails?.roles?.includes('LocalPublisher')) {
    return true;
  }

  return false;
};

export const isNationalPublisher = () => {
  const userDetails = getUserDetails();
  if (
    userDetails !== null &&
    userDetails?.roles?.includes('NationalPublisher')
  ) {
    return true;
  }

  return false;
};

export const hasPermissionToEditDeleteCommunityMarketPosts = (postCityId) => {
  const nationalCities = [2, 4, 5, 6, 7, 8, 9];
  const publisherCityId = Number(getUserCity()?.id);
  if (checkIsSuperAdmin()) {
    return true;
  } else if (isLocalPublisher() && publisherCityId === postCityId) {
    return true;
  } else if (
    isNationalPublisher() &&
    nationalCities.includes(publisherCityId) &&
    nationalCities.includes(postCityId)
  ) {
    return true;
  }

  return false;
};

export const hasPermissionToEditDeleteRonsTipsPosts = (postCityId, isPostAuthor) => {
  if (isPostAuthor) return true;
  const nationalCities = [2, 4, 5, 6, 7, 8, 9];
  const publisherCityId = Number(getUserCity()?.id);
  if (checkIsSuperAdmin()) {
    return true;
  } else if (isLocalPublisher() && postCityId.includes(publisherCityId)) {
    return true;
  } else if (
    isNationalPublisher() &&
    nationalCities.includes(publisherCityId) &&
    postCityId.some((city) => nationalCities.includes(city))
  ) {
    return true;
  }

  return false;
};
