import { getUserDetails, numberToBase64 } from '.';
import { getProfileId, getProfilePublicId, getUserPublicId } from './authUtils';

export const SUBSCRIPTION_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/account-settings?city=${city}&tab=my_subscription`;

export const ACCOUNT_SETTINGS_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/account-settings?city=${city}&tab=general_settings`;

export const ACCOUNT_SETTINGS_ALERTS_AND_NOTIFICATIONS_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/account-settings?city=${city}&tab=alerts_and_notification`;

export const ACCOUNT_SETTINGS_NEWSLETTER_CITY = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/account-settings?city=${city}&tab=newsletter_city`;

export const PROFILE_ROUTE = ({ userId, profileId, city }) =>
  `${
    process.env.NEXT_PUBLIC_FRONTEND_URL
  }/profile?userId=${userId ? userId : getUserPublicId()}&profileId=${profileId ? profileId : getProfilePublicId()}&city=${city}&tab=General+Info`;

export const PROFILE_ROUTE_LOVE = ({ userId, profileId, city }) =>
  `${
    process.env.NEXT_PUBLIC_FRONTEND_URL
  }/profile?userId=${userId ? userId : getUserPublicId()}&profileId=${profileId ? profileId : getProfilePublicId()}&city=${city}&tab=Love`;

export const MY_PROFILE_ROUTE = (city) =>
  `${
    process.env.NEXT_PUBLIC_FRONTEND_URL
  }/profile?userId=${getUserPublicId()}&profileId=${getProfilePublicId()}&city=${city}&tab=General+Info`;

export const EDIT_PROFILE_ROUTE = ({ userId, profileId, city }) =>
  `${
    process.env.NEXT_PUBLIC_FRONTEND_URL
  }/edit-profile?userId=${userId ? userId : getUserPublicId()}&profileId=${profileId ? profileId : getProfilePublicId()}&city=${city}`;

export const MY_POSTS_ROUTE = (city) =>
  `${
    process.env.NEXT_PUBLIC_FRONTEND_URL
  }/profile?userId=${getUserPublicId()}&profileId=${getProfilePublicId()}&city=${city}&tab=Posts`;

export const MY_ADVERTISEMENTS_ROUTE = (city) =>
  `${
    process.env.NEXT_PUBLIC_FRONTEND_URL
  }/profile?userId=${getUserPublicId()}&profileId=${getProfilePublicId()}&city=${city}&tab=Posts&module=market`;

export const LOGIN_SIGNUP_ROUTE = (redirectUrl = '/', lang) => {
  if (redirectUrl === '/')
    return `${process.env.NEXT_PUBLIC_BACKEND_URL}/login/azure?lang=${lang}`;
  else
    return `${process.env.NEXT_PUBLIC_BACKEND_URL}/login/azure?redirect=${redirectUrl}&lang=${lang}`;
};

export const BACKEND_LOGOUT_ROUTE = () =>
  `${process.env.NEXT_PUBLIC_BACKEND_URL}/logout`;

export const CREATE_NEW_PROFILE_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/add-new-profile?city=${city}`;

export const CREATE_NEW_COMPANY_PROFILE_ROUTE = (city, redirectTo = '') => {
  if (redirectTo) {
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/add-new-profile?city=${city}&companyProfile=1&redirectTo=subscription`;
  } else {
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/add-new-profile?city=${city}&companyProfile=1`;
  }
};

export const COMPANY_PROFILE_SUBSCRIPTION_ROUTE = (city) => {
  if (typeof window !== 'undefined') {
    const userDetails = getUserDetails();
    const companyProfile = userDetails
      ? userDetails?.profiles.find((item) => item.account_type === 'Company')
      : null;

    if (companyProfile)
      return `${
        process.env.NEXT_PUBLIC_FRONTEND_URL
      }/account-settings?tab=my_subscription&userId=${getUserPublicId()}&profileId=${
        companyProfile.profile_public_id
      }&city=${city}`;
    else
      return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/add-new-profile?city=${city}&companyProfile=1`;
  }
  return null;
};
export const CHAT_ROUTE = ({ userId, profileId, city }) => {
  if (profileId && userId) {
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/chats?user_id=${userId}&profile_id=${profileId}&city=${city}`;
  }
    
  else return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/chats?city=${city}`;
};

export const NOTIFICATION_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/notifications?city=${city}`;

export const MY_AREA_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/categories/16?category=My+Area&city=${city}`;

export const TOGETHER_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/categories/14?category=Together&city=${city}&tag_filter=most_popular`;

export const RONSTAR_HELP_RONSTAR_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/categories/18?category=Ronstar+Help+Ronstar&city=${city}&tag_filter=most_popular`;

export const LOVE_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/categories/15?category=Love&city=${city}`;

export const RONS_TIPS_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips?city=${city}`;

export const SECOND_HAND_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/secondhand?city=${city}&tag_filter=most_popular`;

export const CITY_OFFER_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/cityoffer?city=${city}&tag_filter=most_popular`;

export const HOUSING_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/housing?city=${city}&tag_filter=most_popular`;

export const JOBS_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/jobs?city=${city}&tag_filter=most_popular`;

export const EVENTS_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/events?city=${city}&tag_filter=most_popular`;

export const CREATE_STORY_ROUTE = (city, topic = '') => {
  if (topic) {
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/start-discussion/create-story?city=${city}&topic=${topic}`;
  }

  return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/start-discussion/create-story?city=${city}`;
};

export const COMMUNITY_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community?city=${city}`;

export const COMMUNITY_CATEGORY_ROUTE = ({ city, category }) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/categories/${category?.id}?category=${category?.name}&city=${city}`;

export const FOR_YOU_COMMUNITY_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/for-you?city=${city}`;

export const FOR_YOU_RONS_TIPS_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/for-you?city=${city}`;

export const FOR_YOU_MARKET_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/for-you?city=${city}`;

export const CREATE_COMMERCIAL_POST_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/place-ad/commercial?city=${city}`;

export const MY_FOLLOWS_ROUTE = (city, tab = 'Users') =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/my-follows?tab=${tab}&city=${city}`;

export const ECOMMERCE_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/advertise-on-ron-orp?city=${city}`;

export const CREATE_JOBS_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/place-ad/jobs?city=${city}`;

export const CREATE_HOUSING_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/place-ad/housing?city=${city}`;

export const CREATE_SECONDHAND_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/place-ad/secondhand?city=${city}`;

export const CREATE_CITY_OFFER_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/place-ad/cityoffer?city=${city}`;

export const PAYMENT_ROUTE = ({
  payment_leadger_id,
  amount,
  email,
  first_name = 'null',
  last_name = 'null',
  company_name = 'null',
  city,
  redirect_to,
  post_id,
  payment_types = 'onetime',
  recurring_intervals = 'monthly',
}) => {
  if (post_id) {
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/payment?first_name=${first_name}&last_name=${last_name}&company_name=${company_name}&email=${email}&payment_leadger_id=${payment_leadger_id}&qs=${numberToBase64(amount)}&city=${city}&redirect_to=${redirect_to}&post_id=${post_id}&payment_types=${payment_types}&ri=${btoa(recurring_intervals)}`;
  } else if (payment_types === 'recurring') {
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/payment?first_name=${first_name}&last_name=${last_name}&company_name=${company_name}&email=${email}&payment_leadger_id=${payment_leadger_id}&qs=${numberToBase64(amount)}&city=${city}&redirect_to=${redirect_to}&payment_types=${payment_types}&ri=${btoa(recurring_intervals)}&profile_id=${getProfileId()}`;
  } else {
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/payment?first_name=${first_name}&last_name=${last_name}&company_name=${company_name}&email=${email}&payment_leadger_id=${payment_leadger_id}&qs=${numberToBase64(amount)}&city=${city}&redirect_to=${redirect_to}&payment_types=${payment_types}&profile_id=${getProfileId()}&ri=${btoa(recurring_intervals)}`;
  }
};

export const DONATION_ROUTE = ({
  amount,
  first_name,
  last_name,
  email,
  post_id,
  city,
  redirect_to,
  payment_types = 'onetime',
  recurring_intervals = 'monthly',
}) => {
  if (post_id) {
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/payment?first_name=${first_name}&last_name=${last_name}&email=${email}&qs=${numberToBase64(amount)}&city=${city}&redirect_to=${redirect_to}&post_id=${post_id}&payment_types=${payment_types}&ri=${btoa(recurring_intervals)}&donation=1`;
  } else {
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/payment?first_name=${first_name}&last_name=${last_name}&email=${email}&qs=${numberToBase64(amount)}&city=${city}&redirect_to=${redirect_to}&payment_types=${payment_types}&ri=${btoa(recurring_intervals)}&donation=1`;
  }
};

export const MARKET_POST_DETAILS_ROUTE = (city, postId) => {
  // if (backUrl)
  //   return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/posts/${postId}?city=${city}&backUrl=${backUrl}`;
  // else
  return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/posts/${postId}?city=${city}`;
};

export const MARKET_HOME_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market?city=${city}`;

export const RONS_TIPS_CATEGORY_PAGE_ROUTE = (category, city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/${category}?city=${city}`;

export const TOP_PLACEMENT_FOOTER_PAGE = (city) => {
  return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/top-placement?city=${city}`;
};

export const POST_IN_NL_FOOTER_PAGE = (city) => {
  return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/post-in-newsletter?city=${city}`;
};

export const POST_IN_WEBSITE_FOOTER_PAGE = (city) => {
  return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/place-ad-on-website?city=${city}`;
};

export const MARKET_PLACE_AD_ROUTE = (city) => {
  return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/place-ad?city=${city}`;
};
export const CREATE_POST_LINK = ({ moduleName, city }) => {
  if (moduleName === 'community') {
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/start-discussion?city=${city}`;
  } else if (moduleName === 'market') {
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/place-ad?city=${city}`;
  } else {
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/place-ad?city=${city}`;
  }
};
export const POST_DETAILS_LINK = ({ moduleName, city, postId }) => {
  if (moduleName === 'community' || moduleName === 'forum') {
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/posts/${postId}?city=${city}`;
  } else if (moduleName === 'market') {
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/posts/${postId}?city=${city}`;
  } else {
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/${postId}?city=${city}`;
  }
};

export const MY_FAVOURITES_ROUTE = (city) => {
  return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/my-favorites?city=${city}`;
};

export const MARKET_POST_CHECKOUT_ROUTE = (params) => {
  const {
    postId,
    postTitle,
    amount,
    payment_purpose,
    items,
    city,
    prefix,
    vat_amount,
  } = params;
  return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/checkout?post_id=${postId}&post_title=${postTitle}&qs=${numberToBase64(amount)}&payment_purpose=${payment_purpose}&items=${items}&prefix=${prefix}&city=${city}&vt=${vat_amount}`;
};

export const USER_PROFILE_ROUTE = ({ city, user_id, profile_id }) => {
  return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/profile?user_id=${user_id}&profile_id=${profile_id}&city=${city}`;
};

export const MY_PAID_ADS_ROUTE = (city, tab = 'boosted-ads') => {
  return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/my-paid-ads?city=${city}&tab=${tab}`;
};

export const YOU_ARE_GREAT_ROUTE = (city) => {
  return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/sign-up/you-are-great?city=${city}`;
};

export const PROFILE_LINK = (authorInfo) => {
  return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/profile?userId=${authorInfo?.user_public_id}&profileId=${authorInfo?.profile_public_id}&tab=General+Info`;
};

export const MY_PROFILE_LOVE_ROUTE = (city) =>
  `${
    process.env.NEXT_PUBLIC_FRONTEND_URL
  }/profile?userId=${getUserPublicId()}&profileId=${getProfilePublicId()}&city=${city}&tab=Love`;

export const SEARCH_PAGE_LINK = ({
  moduleName,
  postTypeId,
  searchText,
  city,
}) => {
  if (moduleName === 'market')
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/search?postType=${postTypeId}&keyword=${searchText}&city=${city}`;
  else
    return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${moduleName}/search?keyword=${searchText}&city=${city}`;
};

export const PORTFOLIO_ROUTE = ({ userId, profileId, city }) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/profile?userId=${userId}&profileId=${profileId}&city=${city}&tab=Portfolio`;

export const PORTFOLIO_DETAILS_ROUTE = ({
  userId,
  profileId,
  city,
  portfolioId,
}) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/profile/portfolio/${portfolioId}?userId=${userId}&profileId=${profileId}&city=${city}&tab=Portfolio`;

export const EDIT_PORTFOLIO_ROUTE = ({
  userId,
  profileId,
  city,
  portfolioId,
}) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/profile/portfolio/edit/${portfolioId}?userId=${userId}&profileId=${profileId}&city=${city}&tab=Portfolio`;

export const DONATION_PAGE_ROUTE = (city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/donate?city=${city}`;

export const INVOICE_PAGE_ROUTE = (invoiceId, city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/invoice/${invoiceId}?city=${city}`;

export const BOOST_POST_PAGE_ROUTE = (postId, city) =>
  `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/boost-post/${postId}?city=${city}`;
