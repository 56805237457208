import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from '../../../../utils';
import { getProfileId } from '../../../../utils/authUtils';
import { instance } from '../../../axios/axios';

export const getUsersAsync = createAsyncThunk(
  'chat/getUsersAsync',
  async ({ filter, keyword = '', take = 10, skip = 0 }) => {
    try {
      let res = await instance.get(
        `/chats-users?keyword=${keyword}&take=${take}&skip=${skip}`,
        getHeaders()
      );
      if (filter === 'favorite') {
        res = await instance.get(
          `/chats-users?is_favorite=1&keyword=${keyword}&take=${take}&skip=${skip}`,
          getHeaders()
        );
      }

      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err);
      return null;
    }
  }
);

export const getUserChatsAsync = createAsyncThunk(
  'chat/getUserChatsAsync',
  async ({ friend_id, take = 10, page = 1 }) => {
    try {
      const res = await instance.get(
        `/user-chats/${friend_id}?page=${page}&take=${take}`,
        getHeaders()
      );

      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err);
      return null;
    }
  }
);

export const getChatUserDetailsAsync = createAsyncThunk(
  'chat/getChatUserDetailsAsync',
  async ({ profile_id }) => {
    try {
      const res = await instance.get(
        `/chat-user-details?profile_id=${profile_id}`,
        getHeaders()
      );

      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err);
      return null;
    }
  }
);

export const getBlockedUsersListAsync = createAsyncThunk(
  'chat/getBlockedUsersListAsync',
  async ({ skip = 0, take = 10 }) => {
    try {
      const res = await instance.get(
        `/chat-block-list?profile_id=${getProfileId()}&take=${take}&skip=${skip}`,
        getHeaders()
      );

      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err);
      return null;
    }
  }
);

export const sendMessageAsync = createAsyncThunk(
  'chat/sendMessageAsync',
  async (data) => {
    try {
      const res = await instance.post('/chats', data, getHeaders());
      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const chatUserBlockReport = createAsyncThunk(
  'chat/chatUserBlockReport',
  async (data) => {
    try {
      const res = await instance.post(
        '/user-chat-block-unblock',
        data,
        getHeaders()
      );
      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const chatUserFavourite = createAsyncThunk(
  'chat/chatUserFavortite',
  async (data) => {
    try {
      const res = await instance.post(
        '/chats-favorite-unfavorite',
        data,
        getHeaders()
      );
      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

export const chatReact = createAsyncThunk('chat/chatReact', async (data) => {
  try {
    const res = await instance.post('/chat-react', data, getHeaders());
    return res?.data;
  } catch (err) {
    console.error('Something went wrong', err);
  }
});

export const chatMakeSeen = createAsyncThunk(
  'chat/chatMakeSeen',
  async ({ id }) => {
    try {
      const res = await instance.put(`/chat-make-seen/${id}`, {}, getHeaders());

      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err);
    }
  }
);

function getUniqueUsers(array) {
  const uniqueObjects = Array.from(
    array.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()
  );
  return uniqueObjects;
}

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    userChats: null,
    users: null,
    loading: false,
    loadingUsers: false,

    totalUsers: 0,
    totalChatsCount: 0,
    blockedUsers: [],
    availableChatContacts: null,
    showNewMessageAlert: false,
    loadingUserChats: false
  },
  reducers: {
    setMessageToUserChats: (state, action) => {
      // const { isAlreadyExists } = state;
      let temp = state.userChats;
      const alreadyExists = temp.filter(
        (item) => item.id === action.payload.id
      );
      if (temp !== null && !alreadyExists.length) {
        temp.push(action.payload);
      } else temp = [action?.payload];
      state.userChats = temp;
    },

    searchChatUsers: (state, action) => {
      const searchStr = action?.payload;
      if (searchStr !== '') {
        state.users = null;
      }
    },

    resetUsers: (state) => {
      state.users = null;
    },

    resetUserChats: (state) => {
      state.userChats = null;
    },

    resetBlockedUsers: (state) => {
      state.blockedUsers = [];
    },

    setAvailableChatContacts: (state, action) => {
      state.availableChatContacts = action?.payload;
    },

    updateMostRecentChat: (state, action) => {
      state.users = action?.payload;
    },
    updateIsSeen: (state, action) => {
      const data = state?.users || [];
      const id = action?.payload?.chat_id;
      const updatedData = JSON.parse(JSON.stringify(data)).map((item) => {
        if (item.chat_id === id) {
          item.is_seen = true;
        }

        return item;
      });

      state.users = updatedData;
    },
    setShowNewMessageAlert: (state, action) => {
      state.showNewMessageAlert = action?.payload;
    },
  },
  extraReducers: {
    [getUsersAsync.pending]: (state, action) => {
      state.loadingUsers = true;
    },
    [getUsersAsync.fulfilled]: (state, action) => {
      if (state.users === null) {
        state.users = action.payload?.users;
      } else {
        const { users } = state;
        if (users?.length && action?.payload?.users) {
          state.users = getUniqueUsers([...users, ...action.payload?.users]);
        } else if (Array.isArray(action?.payload?.users)) {
          state.users = getUniqueUsers([...action.payload?.users]);
        }
      }
      state.totalUsers = action.payload?.total;
      state.loadingUsers = false;
    },
    [getUsersAsync.rejected]: (state, action) => {
      state.users = action.payload;
      state.loadingUsers = false;
    },
    [getUserChatsAsync.pending]: (state, action) => {
      state.loading = true;
      state.loadingUserChats = true;
    },
    [getUserChatsAsync.fulfilled]: (state, action) => {
      if (state.userChats === null) {
        state.userChats = action.payload?.chats;
      } else {
        const { userChats } = state;
        if (userChats?.length) {
          state.userChats = [...action.payload?.chats, ...userChats];
        } else {
          state.userChats = action.payload?.chats;
        }
      }

      state.totalChatsCount = action.payload?.total;
      state.loading = false;
      state.loadingUserChats = false;
    },
    [getUserChatsAsync.rejected]: (state, action) => {
      state.userChats = action.payload;
      state.loading = false;
      state.loadingUserChats = false
    },
    [sendMessageAsync.fulfilled]: (state, action) => {
      const temp = state.userChats;
      if (state.userChats !== null) temp.push(action?.payload);
      // state.userChats = temp
    },
    [chatUserBlockReport.pending]: (state, action) => {
      state.loading = true;
    },
    [chatUserBlockReport.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [chatUserBlockReport.rejected]: (state, action) => {
      state.loading = false;
    },
    [getBlockedUsersListAsync.fulfilled]: (state, action) => {
      if (Array.isArray(action?.payload) && state?.blockedUsers?.length === 0) {
        state.blockedUsers = action.payload;
      } else if (
        Array.isArray(action?.payload) &&
        state?.blockedUsers?.length > 0
      ) {
        state.blockedUsers = getUniqueUsers([
          ...state.blockedUsers,
          ...action.payload,
        ]);
      }
    },
  },
});

export const {
  setMessageToUserChats,
  searchChatUsers,
  resetUsers,
  resetUserChats,
  resetBlockedUsers,
  setAvailableChatContacts,
  updateMostRecentChat,
  updateIsSeen,
  setShowNewMessageAlert,
} = chatSlice.actions;
export default chatSlice.reducer;
