import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders, setUserDataInCookies } from '../../../../utils';
import {
  getProfileId,
  getProfilePublicId,
  getUserId,
  getUserPublicId,
  setUserData,
} from '../../../../utils/authUtils';
import { Axios, instance } from '../../../axios/axios';
import { encrypt } from '../../../../utils/cryptr';
import { getCityId } from '../../../../utils/community';

const moduleName = 'userProfile';
const apiRoute = `/user-details`;

export const getUserDataAsync = createAsyncThunk(
  `${moduleName}/getUserDataAsync`,
  async () => {
    try {
      const response = await Axios.get('/api/user');
      return response.data;
    } catch (err) {
      return err;
    }
  }
);

export const getProfileDataAsync = createAsyncThunk(
  `${moduleName}/getProfileDataAsync`,
  async (payload) => {
    try {
      const profileId = payload?.profileId
        ? payload?.profileId
        : getProfilePublicId();

      const response = profileId
        ? await instance.get(
            `${apiRoute}?profile_public_id=${profileId}&city_id=${payload?.cityId ? payload?.cityId : getCityId()}`,
            getHeaders()
          )
        : await instance.get(`${apiRoute}`, getHeaders());

      return response.data;
    } catch (err) {
      console.error('Something went wrong', err);
      return null;
    }
  }
);

export const switchProfileAsync = createAsyncThunk(
  `${moduleName}/switchProfileAsync`,
  async (payload) => {
    const response = await instance.post(
      `/user-switch-profile`,
      payload,
      getHeaders()
    );
    const data = response?.data;
    return data;
  }
);

export const getPublicProfileDataAsync = createAsyncThunk(
  `${moduleName}/getPublicProfileDataAsync`,
  async ({ userId, profileId }) => {
    const response = await instance.get(
      `/public-user-details?user_public_id=${userId}&profile_public_id=${profileId}&login_user_public_id=${getUserPublicId()}&login_profile_public_id=${getProfilePublicId()}`
    );

    const data = response?.data;
    return data;
  }
);

export const createProfileDataAsync = createAsyncThunk(
  `${moduleName}/createProfileDataAsync`,
  async (payload) => {
    const response = await instance.post(`${apiRoute}`, payload, getHeaders());

    const userData = response.data.data;
    if (response.status === 200) {
      const res = await instance.get(
        `${apiRoute}?profile_public_id=${userData?.profile_public_id}`,
        getHeaders()
      );
      const userDetails = res?.data;
      localStorage.setItem('userDetails', encrypt(JSON.stringify(userDetails)));
    }
    const data = response;
    return data;
  }
);
export const addNewProfileDataAsync = createAsyncThunk(
  `${moduleName}/addNewProfileDataAsync`,
  async (payload) => {
    const response = await instance.post(
      `/user-new-profile`,
      payload,
      getHeaders()
    );

    if (response.status === 200) {
      const response = await instance.get(
        `${apiRoute}?profile_id=${getProfileId()}`,
        getHeaders()
      );
      localStorage.setItem(
        'userDetails',
        encrypt(JSON.stringify(response.data))
      );
    }
    const data = response?.data;
    return data;
  }
);
export const addNewPortfolioAsync = createAsyncThunk(
  `${moduleName}/addNewPortfolioAsync`,
  async (payload) => {
    const response = await instance.post(
      `/user-portfolio`,
      payload,
      getHeaders()
    );

    const data = response?.data;
    return data;
  }
);

export const applyCityWriterAsync = createAsyncThunk(
  `${moduleName}/applyCityWriterAsync`,
  async (payload) => {
    const response = await instance.get(
      `/apply-citywriter?user_id=${getUserId()}`,
      getHeaders()
    );

    const data = response;

    return data;
  }
);

export const deleteAccountAsync = createAsyncThunk(
  `${moduleName}/deleteAccountAsync`,
  async (payload) => {
    const response = await instance.post(
      `/delete-user-account`,
      payload,
      getHeaders()
    );
    const data = response;
    return data;
  }
);
export const deleteProfileAsync = createAsyncThunk(
  `${moduleName}/deleteProfileAsync`,
  async (payload) => {
    const response = await instance.post(
      `/delete-user-profile`,
      payload,
      getHeaders()
    );

    const data = response;
    return data;
  }
);

export const updateAlbumAsync = createAsyncThunk(
  `${moduleName}/updateAlbumAsync`,
  async (payload) => {
    const response = await instance.put(
      `/user-album?profile_id=${getProfileId()}`,
      payload,
      getHeaders()
    );

    return response;
  }
);

export const getAlbumAsync = createAsyncThunk(
  `${moduleName}/getAlbumAsync`,
  async (payload) => {
    const response = await instance.get(
      `/user-album?user_public_id=${payload.userId}&profile_public_id=${payload.profileId}`,
      payload,
      getHeaders()
    );

    return response.data;
  }
);

export const checkUserPostExists = createAsyncThunk(
  `${moduleName}/checkUserPostExists`,
  async (payload) => {
    const response = await instance.get(
      `/user-posts-exists?user_public_id=${payload.userId}&profile_public_id=${payload.profileId}`,
      getHeaders()
    );

    const data = response.data;
    return data;
  }
);

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: {
    publicUser: null,
    data: null,
    userData: null,
    isAuthenticated: false,
    userDetails: null,
    isAuthenticatedWithData: false,
    loading: false,
    openAllProfilesModal: false,
    confirmProfileSwitchModal: false,
    switchingProfile: null,
    album: null,
    userPostExists: null,
  },
  reducers: {
    setOpenAllProfilesModal: (state, action) => {
      state.openAllProfilesModal = action.payload;
    },
    setConfirmProfileSwitchModal: (state, action) => {
      state.confirmProfileSwitchModal = action.payload;
    },
    setSwitchingProfile: (state, action) => {
      state.switchingProfile = action.payload;
    },
    logout: (state, action) => {
      state.userData = null;
      state.isAuthenticated = false;
      state.userDetails = null;
      state.isAuthenticatedWithData = false;
      localStorage.clear();
    },
  },
  extraReducers: {
    [getUserDataAsync.pending]: (state) => {
      state.loading = true;
    },
    [getUserDataAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.userData = action.payload;
      if (action?.payload !== null && action?.payload?.user_id) {
        setUserData(action.payload);
        setUserDataInCookies(action.payload);
      }
    },
    [getUserDataAsync.rejected]: (state) => {
      state.isAuthenticated = false;
      state.loading = false;
    },
    [getProfileDataAsync.pending]: (state) => {
      state.loading = true;
    },
    [getProfileDataAsync.fulfilled]: (state, action) => {
      if (action?.payload?.user_id) {
        const userDetails = action?.payload;
        localStorage.setItem(
          'userDetails',
          encrypt(JSON.stringify(userDetails))
        );
        setUserDataInCookies({
          user_id: userDetails?.user_id,
          profile_id: userDetails?.profile_id,
        });
        state.isAuthenticatedWithData = true;
        state.userDetails = userDetails;
        state.loading = false;
      } else {
        state.loading = false;
      }
    },
    [getProfileDataAsync.rejected]: (state) => {
      state.isAuthenticatedWithData = false;
      state.loading = false;
    },
    [getPublicProfileDataAsync.pending]: (state) => {
      state.loading = true;
    },
    [getPublicProfileDataAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.publicUser = action.payload;
    },
    [getPublicProfileDataAsync.rejected]: (state) => {
      state.loading = false;
    },
    [createProfileDataAsync.pending]: (state) => {
      state.loading = true;
    },
    [createProfileDataAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [createProfileDataAsync.rejected]: (state) => {
      state.loading = false;
    },
    [switchProfileAsync.pending]: (state) => {
      state.loading = true;
    },
    [switchProfileAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [switchProfileAsync.rejected]: (state) => {
      state.loading = false;
    },
    [addNewProfileDataAsync.pending]: (state) => {
      state.loading = true;
    },
    [addNewProfileDataAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [addNewProfileDataAsync.rejected]: (state) => {
      state.loading = false;
    },
    [applyCityWriterAsync.pending]: (state) => {
      state.loading = true;
    },
    [applyCityWriterAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [applyCityWriterAsync.rejected]: (state) => {
      state.loading = false;
    },
    [deleteAccountAsync.pending]: (state) => {
      state.loading = true;
    },
    [deleteAccountAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteAccountAsync.rejected]: (state) => {
      state.loading = false;
    },
    [deleteProfileAsync.pending]: (state) => {
      state.loading = true;
    },
    [deleteProfileAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteProfileAsync.rejected]: (state) => {
      state.loading = false;
    },
    [updateAlbumAsync.pending]: (state) => {
      state.loading = true;
    },
    [updateAlbumAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateAlbumAsync.rejected]: (state) => {
      state.loading = false;
    },
    [updateAlbumAsync.pending]: (state) => {
      state.loading = true;
    },
    [updateAlbumAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateAlbumAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getAlbumAsync.pending]: (state) => {
      state.loading = true;
    },
    [getAlbumAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.album = action.payload;
    },
    [getAlbumAsync.rejected]: (state) => {
      state.loading = false;
    },
    [checkUserPostExists.fulfilled]: (state, action) => {
      state.userPostExists = action.payload;
    },
  },
});

export const {
  setOpenAllProfilesModal,
  setConfirmProfileSwitchModal,
  setSwitchingProfile,
  logout,
} = userProfileSlice.actions;
export default userProfileSlice.reducer;
