import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AllSubcategoryFollowDialog from '../src/components/Common/AlertDialog/AllSubcategoryFollowDialog';

const MySwal = withReactContent(Swal);

export const ronsTipsCategoryIds = {
  guides: 2,
  food_and_shop: 3,
  people: 4,
  out_of_town: 5,
  contest: 6,
  family_life: 23,
};

export const rons_tips_categories_with_subcategpries = [2, 3, 23];

export const ronsTipsGuidesSubCategoryIds = {
  roncast: 7,
  city_life: 8,
  rooftop_day: 9,
  streetfood_tour: 10,
  balance_day: 11,
  ron_voyage: 12,
  pastime: 13,
  whats_up: 14,
  whats_happening: 21,
  rons_events: 22,
};

export const ronsTipsFoodAndShopSubCategoryIds = {
  food_and_drinks: 15,
  shopping: 16,
  new_in_town: 17,
};

export const ronsTipsFamilyLifeSubCategoryIds = {
  diy: 24,
  finance: 25,
  parenthood: 26,
  podcasts: 27,
  trips: 28,
  inspiration: 29,
};

export const ronsTipsCategories = (lang) => [
  {
    id: 2,
    name: lang?.rons_tips?.guides,
    routePath: '/rons-tips/guides',
  },
  {
    id: 3,
    name: lang?.rons_tips?.food_and_shopping,
    routePath: ' /rons-tips/food-shopping',
  },
  {
    id: 4,
    name: lang?.rons_tips?.people,
    routePath: '/rons-tips/people',
  },
  {
    id: 5,
    name: lang?.rons_tips?.out_of_town,
    routePath: '/rons-tips/out-of-town',
  },
  {
    id: 6,
    name: lang?.rons_tips?.contest,
    routePath: '/rons-tips/contest',
  },
  {
    id: 23,
    name: lang?.rons_tips?.familylife || 'Family Life',
    routePath: '/rons-tips/family-life',
  },
];

export function showAllSubcategoryFollowAlert({ isFollowed, lang }) {
  return MySwal.fire({
    title: <AllSubcategoryFollowDialog isFollowed={isFollowed} lang={lang} />,
    text: '',
    icon: '',
    iconColor: '#000',
    showCancelButton: true,
    confirmButtonColor: '#000',
    confirmButtonText: lang?.market?.yes,
    cancelButtonText: lang?.common?.cancel,
    showCloseButton: true,
    customClass: {
      actions: '',
      cancelButton: 'order-1',
      confirmButton: 'order-2',
      title: 'title-style',
      closeButton: 'close-button-swal',
      popup: '!p-0',
    },
  });
}

export function getRonsTipsCategoryNames(categories) {
  const result = [];
  if (categories && categories?.length > 0) {
    for (const item of categories) {
      result.push(item?.name);
    }
  }

  return result.length > 0 ? result.join(' - ') : '';
}

export const guidesSubNav = (lang) => [
  {
    id: 1,
    name: lang?.rons_tips?.city_life,
    url: '/rons-tips/guides/city-life',
  },
  {
    id: 2,
    name: lang?.rons_tips?.roncast,
    url: '/rons-tips/guides/roncast',
  },
  // {
  //   id: 3,
  //   name: lang?.rons_tips?.rooftop_day,
  //   url: '/rons-tips/guides/rooftop-day',
  // },
  // {
  //   id: 4,
  //   name: lang?.rons_tips?.streetfood_tour,
  //   url: '/rons-tips/guides/streetfood-tour',
  // },
  // {
  //   id: 5,
  //   name: lang?.rons_tips?.balance_day,
  //   url: '/rons-tips/guides/balance-day',
  // },
  // {
  //   id: 6,
  //   name: lang?.rons_tips?.ron_voyage,
  //   url: '/rons-tips/guides/ron-voyage',
  // },
  {
    id: 7,
    name: lang?.rons_tips?.pastime,
    url: '/rons-tips/guides/pastime',
  },
  // {
  //   id: 8,
  //   name: lang?.rons_tips?.whats_up,
  //   url: '/rons-tips/guides/whats-up',
  // },
  {
    id: 9,
    name: lang?.rons_tips?.rons_events || 'Ron Events',
    url: '/rons-tips/guides/rons-events',
  },
  {
    id: 10,
    name: lang?.rons_tips?.whats_happening || 'What\'s Happening',
    url: '/rons-tips/guides/whats-happening',
  },
];

export const foodAndShoopingSubNav = (lang) => [
  {
    id: 1,
    name: lang?.rons_tips?.food_and_drinks,
    url: '/rons-tips/food-shopping/food-drinks',
  },
  {
    id: 2,
    name: lang?.rons_tips?.shopping,
    url: '/rons-tips/food-shopping/shopping',
  },
  {
    id: 3,
    name: lang?.rons_tips?.new_in_town,
    url: '/rons-tips/food-shopping/new-in-town',
  },
];

export const familyLifeSubNav = (lang) => [
  {
    id: 1,
    name: lang?.rons_tips?.diy || 'DIY',
    url: '/rons-tips/family-life/diy',
  },
  {
    id: 2,
    name: lang?.rons_tips?.rons_tips_finance || 'Finance',
    url: '/rons-tips/family-life/finance',
  },
  {
    id: 3,
    name: lang?.rons_tips?.parenthood || 'Parenthood',
    url: '/rons-tips/family-life/parenthood',
  },
  {
    id: 4,
    name: lang?.rons_tips?.podcasts || 'Podcasts',
    url: '/rons-tips/family-life/podcasts',
  },
  {
    id: 5,
    name: lang?.rons_tips?.trips || 'Trips',
    url: '/rons-tips/family-life/trips',
  },
  {
    id: 6,
    name: lang?.rons_tips?.inspiration || 'Inspiration',
    url: '/rons-tips/family-life/inspiration',
  },
];

export const getRonsTipsDetailsPageUrl = (data, city) => {
  if (data?.is_listicle) {
    return `/rons-tips/listicle/${data?.listicle_id}?city=${city}`;
  } else return `/rons-tips/${data?.id}?city=${city}`;
};

export function checkIsCategoryWithSubCategory(categoryId) {
  return rons_tips_categories_with_subcategpries.includes(categoryId);
}

// Function to group data by publish_date month and year
function groupByMonthAndYear(data) {
  return data.reduce((acc, item) => {
    // Extract year and month from publish_date
    const date = new Date(item.publish_date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two-digit month
    const key = `${year}-${month}`; // Format key as "YYYY-MM"

    // Initialize group if not already present
    if (!acc[key]) {
      acc[key] = {
        year: year,
        month: month,
        data: [],
      };
    }
    // Add the current item to the group
    acc[key].data.push(item);
    return acc;
  }, {});
}

// Main function to process data
export function processData(data) {
  return groupByMonthAndYear(data);
}

export const DOMPURIFY_ALLOWED_TAGS = [
  'a',
  'abbr',
  'acronym',
  'address',
  'area',
  'article',
  'aside',
  'audio',
  'b',
  'bdi',
  'bdo',
  'big',
  'blink',
  'blockquote',
  'body',
  'br',
  'button',
  'canvas',
  'caption',
  'center',
  'cite',
  'code',
  'col',
  'colgroup',
  'content',
  'data',
  'datalist',
  'dd',
  'decorator',
  'del',
  'details',
  'dfn',
  'dir',
  'div',
  'dl',
  'dt',
  'element',
  'em',
  'fieldset',
  'figcaption',
  'figure',
  'font',
  'footer',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'head',
  'header',
  'hgroup',
  'hr',
  'html',
  'i',
  'iframe',
  'img',
  'input',
  'ins',
  'kbd',
  'label',
  'legend',
  'li',
  'main',
  'map',
  'mark',
  'marquee',
  'menu',
  'menuitem',
  'meter',
  'nav',
  'nobr',
  'ol',
  'optgroup',
  'option',
  'output',
  'p',
  'picture',
  'pre',
  'progress',
  'q',
  'rp',
  'rt',
  'ruby',
  's',
  'samp',
  'section',
  'select',
  'shadow',
  'small',
  'source',
  'spacer',
  'span',
  'strike',
  'strong',
  'style',
  'sub',
  'summary',
  'sup',
  'table',
  'tbody',
  'td',
  'template',
  'textarea',
  'tfoot',
  'th',
  'thead',
  'time',
  'tr',
  'track',
  'tt',
  'u',
  'ul',
  'var',
  'video',
  'wbr',
];
