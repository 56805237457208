import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from '../../../../utils';
import { getProfileId } from '../../../../utils/authUtils';
import { getCityId } from '../../../../utils/community';
import { instance } from '../../../axios/axios';

const moduleName = 'tags';
const apiRoute = `/core/tags`;

export const getTagsAsync = createAsyncThunk(
  `${moduleName}/getTagsAsync`,
  async ({ keyword = null, tag_module = 'all', is_follow = null, take = 5, skip = 0, is_default = "" }) => {
    let url = is_follow
      ? `${apiRoute}?keyword=${keyword}&is_follow=${is_follow}&tag_module=${tag_module}&city_id=${getCityId()}&take=${take}&skip=${skip}`
      : `${apiRoute}?keyword=${keyword}&tag_module=${tag_module}&city_id=${getCityId()}&take=${take}&skip=${skip}&is_default=${is_default}`;

    url = getProfileId() ? url + `&profile_id=${getProfileId()}` : url;

    const response = await instance.get(url, getHeaders());
    const data = response.data;
    return data;
  }
);

export const getTagSectionTagsAsync = createAsyncThunk(
  `${moduleName}/getTagSectionTagsAsync`,
  async (payload) => {
    const {
      moduleApiPath,
      filter,
      categoryId,
      take = 500,
      skip = 0,
    } = payload;
    try {
      const response = await instance.get(
        `/${moduleApiPath}/tags?city_id=${getCityId()}&filter_by=${filter}&take=${take}&skip=${skip}`,
        getHeaders()
      );
      // const response = await instance.get(
      //   `/${moduleApiPath}/tags?city_id=${getCityId()}&filter_by=${filter}&category_id=${categoryId}&take=${take}&skip=${skip}`,
      //   getHeaders()
      // );

      const data = response.data;

      return data;
    } catch (err) {
      console.error('Something went wrong in tags', err);
      return null;
    }
  }
);

export const getInterestsAsync = createAsyncThunk(
  `${moduleName}/getInterestsAsync`,
  async () => {
    const response = await instance.get(
      `${apiRoute}?city_id=${getCityId()}`,
      getHeaders()
    );
    const data = response.data;
    return data;
  }
);

export const getFollowedTagsCountAsync = createAsyncThunk(
  `${moduleName}/getFollowedTagsCountAsync`,
  async (payload) => {
    const response = await instance.get(
      `/core/following-tags-count?profile_id=${getProfileId()}&city_id=${getCityId()}`,
      getHeaders()
    );
    const data = response.data;
    return data;
  }
);

export const getSearchedTagsAsync = createAsyncThunk(
  `${moduleName}/getSearchedTagsAsync`,
  async ({ keyword = null, tag_module = 'community',take=10, skip=0, post_type = '', category_id = '' }) => {
    try {
      const response = await instance.get(
        keyword
          ? `/core/search-tags?keyword=${keyword}&tag_module=${tag_module}&take=${take}&skip=${skip}&city_id=${getCityId()}&post_type=${post_type}&category_id=${category_id}`
          : `/core/search-tags?tag_module=${tag_module}&take=${take}&skip=${skip}&city_id=${getCityId()}&post_type=${post_type}&category_id=${category_id}`,
        getHeaders()
      );

      const data = response.data;
      
      return data;
    } catch (err) {
      console.error('Something went wrong in tags', err);
      return null;
    }
  }
);
export const getTagSuggestionsAsync = createAsyncThunk(
  `${moduleName}/getTagSuggestionsAsync`,
  async (payload) => {
    try {
      
      const response = await instance.get(
        `/core/tag-suggestions?tag_module=${payload?.tag_module}&postType=${payload?.postType}&city_id=${getCityId()}&category_id=${payload?.category_id || ''}`,
        getHeaders()
      );

      const data = response.data;
      
      return data;
    } catch (err) {
      console.error('Something went wrong in tags', err);
      return null;
    }
  }
);
export const followTagsAsync = createAsyncThunk(
  `${moduleName}/followTagsAsync`,
  async (payload) => {
    try {
      
      const response = await instance.post(
        `/core/tags-follow?profile_id=${getProfileId()}`,
        payload,
        getHeaders()
      );

      const data = response;
      
      return data;
    } catch (err) {
      console.error('Something went wrong in tags', err);
      return null;
    }
  }
);
export const unFollowTagsAsync = createAsyncThunk(
  `${moduleName}/unFollowTagsAsync`,
  async (payload) => {
    try {
      
      const response = await instance.post(
        `/core/tags-unfollow?profile_id=${getProfileId()}`,
        payload,
        getHeaders()
      );

      const data = response;
      
      return data;
    } catch (err) {
      console.error('Something went wrong in tags', err);
      return null;
    }
  }
);

const tagsSlice = createSlice({
  name: 'tags',
  initialState: {
    tags: null,
    tagSectionTags: null,
    interests: null,
    followedTagsCount: 0,
    searchedTags: [],
    tagSuggestions: [],
    tagsLoading: false,
    loading: false,
  },
  reducers: {
    setSelectedCity: (state, action) => {
      localStorage.setItem('selectedCity', JSON.stringify(action.payload));
      state.selectedCity = action.payload;
    },
  },
  extraReducers: {
    [getTagsAsync.pending]: (state) => {
      state.tagsLoading = true;
    },
    [getTagsAsync.fulfilled]: (state, action) => {
      state.tagsLoading = false;
      state.tags = action.payload;
    },
    [getTagsAsync.rejected]: (state) => {
      state.loading = false;
    },

    [getTagSectionTagsAsync.pending]: (state) => {
      state.tagsLoading = true;
    },
    [getTagSectionTagsAsync.fulfilled]: (state, action) => {
      state.tagsLoading = false;
      state.tagSectionTags = action.payload;
    },
    [getTagSectionTagsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getInterestsAsync.pending]: (state) => {
      state.tagsLoading = true;
    },
    [getInterestsAsync.fulfilled]: (state, action) => {
      state.tagsLoading = false;
      state.interests = action.payload;
    },
    [getInterestsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getFollowedTagsCountAsync.pending]: (state) => {
      state.tagsLoading = true;
    },
    [getFollowedTagsCountAsync.fulfilled]: (state, action) => {
      state.tagsLoading = false;
      state.followedTagsCount = action.payload;
    },
    [getFollowedTagsCountAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getSearchedTagsAsync.pending]: (state) => {
      state.tagsLoading = true;
    },
    [getSearchedTagsAsync.fulfilled]: (state, action) => {
      state.tagsLoading = false;
      state.searchedTags = action.payload;
    },
    [getSearchedTagsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getTagSuggestionsAsync.pending]: (state) => {
      state.tagsLoading = true;
    },
    [getTagSuggestionsAsync.fulfilled]: (state, action) => {
      state.tagsLoading = false;
      state.tagSuggestions = action.payload;
    },
    [getTagSuggestionsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [followTagsAsync.pending]: (state) => {
      state.tagsLoading = true;
    },
    [followTagsAsync.fulfilled]: (state, action) => {
      state.tagsLoading = false;
    },
    [followTagsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [unFollowTagsAsync.pending]: (state) => {
      state.tagsLoading = true;
    },
    [unFollowTagsAsync.fulfilled]: (state, action) => {
      state.tagsLoading = false;
    },
    [unFollowTagsAsync.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { setSelectedCity } = tagsSlice.actions;
export default tagsSlice.reducer;
