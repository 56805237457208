import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import error_400 from '../../../public/assets/icons/400_error.svg';
import CustomImage from '../Common/CustomImage/CustomImage';

const Error400 = ({ handleReset }) => {
  const lang = useSelector(({ common }) => common?.language);
  const router = useRouter();
  const [currentPath] = useState(router.pathname);
  useEffect(() => {
    if (currentPath !== router.pathname) {
      handleReset();
    }
  }, [router.pathname]);
  return (
    <div className='flex min-h-[50vh] flex-col items-center justify-center py-[48px] px-[16px]'>
      <CustomImage
        src={error_400}
        alt='500 error'
        width='w-[277px]'
        height='h-[150px]'
      />
      <div className='mt-[16px]'>
        <h3 className='w-full text-center text-[22px] font-semibold text-high sm:max-w-[665px] sm:text-[36px]'>
          {lang?.common?.server_digital_tongue_twister_disaster}
        </h3>
        <p className='mt-[16px] w-full text-center  text-[14px] leading-[30px] text-mid sm:max-w-[665px] sm:text-[18px]'>
          {lang?.common?.error400_msg}
        </p>
        <div className='mt-[32px] flex justify-center '>
          <button
            className='w-[120px] cursor-pointer rounded-[100px] bg-primary px-[12px] py-[8px] text-body-1-bold text-white sm:w-[204px] sm:px-[32px] sm:py-[20px]'
            onClick={handleReset}
          >
            {lang?.common?.reload_page}
          </button>
          <Link
            href='https://ronorp-help.net/kontakt/'
            target='_blank'
            rel='noopener noreferrer'
            className='w-[120px] cursor-pointer px-[8px] py-[8px] text-body-1-bold text-high sm:w-[204px] sm:px-[32px] sm:py-[20px]'
          >
            {lang?.common?.contact_support}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error400;
