// src/contexts/AdsContext.js
import { createContext, useContext } from 'react';

import {
  getBrunoAdIds,
  getGoogleAdsChannel,
  getGoogleAdsKeyValues,
  communityHomeGoogleAdsKeyValues,
  defaultHorizontalSize,
  GOOGLE_ADS_COMMUNITY_CHANNEL,
  GOOGLE_ADS_MARKET_CHANNEL,
  GOOGLE_ADS_RONS_TIPS_CHANNEL,
  horizontalSizeMappingData,
  marketHomeGoogleAdsKeyValues,
  ronsTipsHomeGoogleAdsKeyValues,
} from '../../utils/adsUtils';

export const AdsContext = createContext();

export function AdsProvider({ children }) {
  const adsValue = {
    getBrunoAdIds,
    getGoogleAdsChannel,
    getGoogleAdsKeyValues,
    communityHomeGoogleAdsKeyValues,
    defaultHorizontalSize,
    GOOGLE_ADS_COMMUNITY_CHANNEL,
    GOOGLE_ADS_MARKET_CHANNEL,
    GOOGLE_ADS_RONS_TIPS_CHANNEL,
    horizontalSizeMappingData,
    marketHomeGoogleAdsKeyValues,
    ronsTipsHomeGoogleAdsKeyValues,
  };

  return <AdsContext.Provider value={adsValue}>{children}</AdsContext.Provider>;
}

export const useAds = () => useContext(AdsContext);
