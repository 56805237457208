import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from '../../../../utils';
import { getProfileId } from '../../../../utils/authUtils';
import { instance } from '../../../axios/axios';

const moduleName = 'userPortfolio';
const apiRoute = `/user-portfolio`;

export const getPortfoliosAsync = createAsyncThunk(
  `${moduleName}/getPortfoliosAsync`,
  async (payload) => {
    
    const response = await instance.get(
      `${apiRoute}?user_public_id=${payload?.userId}&profile_public_id=${payload?.profileId}`,
      getHeaders()
    );
    
    return response?.data;
  }
);

export const getPortfolioByIdAsync = createAsyncThunk(
  `${moduleName}/getPortfolioByIdAsync`,
  async ({ portfolioId }) => {
    
    const response = await instance.get(
      `/user-portfolio-details/${portfolioId}`,
      getHeaders()
    );
    
    return response?.data;
  }
);


export const createPortfolioAsync = createAsyncThunk(
  `${moduleName}/createPortfolioAsync`,
  async (payload) => {
    const response = await instance.post(`${apiRoute}`, payload, getHeaders());
    
    return response;
  }
);

export const updatePortfolioAsync = createAsyncThunk(
  `${moduleName}/updatePortfolioAsync`,
  async (payload) => {
    const response = await instance.put(`${apiRoute}`, payload, getHeaders());
    
    return response;
  }
);

export const deletePortfolioAsync = createAsyncThunk(
  `${moduleName}/deletePortfolioAsync`,
  async ({ id }) => {
    
    const response = await instance.delete(
      `${apiRoute}?portfolio_id=${id}&profile_id=${getProfileId()}`,
      getHeaders()
    );
    
    return response;
  }
);

const userPortfolioSlice = createSlice({
  name: moduleName,
  initialState: {
    data: null,
    portfolios: null,
    portfolioDetails: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [getPortfoliosAsync.pending]: (state) => {
      state.loading = true;
    },
    [getPortfoliosAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.portfolios = action.payload;
    },
    [getPortfoliosAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getPortfolioByIdAsync.pending]: (state) => {
      state.loading = true;
    },
    [getPortfolioByIdAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.portfolioDetails = action.payload;
    },
    [getPortfolioByIdAsync.rejected]: (state) => {
      state.loading = false;
    },
    [createPortfolioAsync.pending]: (state) => {
      state.loading = true;
    },
    [createPortfolioAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [createPortfolioAsync.rejected]: (state) => {
      state.loading = false;
    },
    [updatePortfolioAsync.pending]: (state) => {
      state.loading = true;
    },
    [updatePortfolioAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [updatePortfolioAsync.rejected]: (state) => {
      state.loading = false;
    },
    [deletePortfolioAsync.pending]: (state) => {
      state.loading = true;
    },
    [deletePortfolioAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [deletePortfolioAsync.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default userPortfolioSlice.reducer;
