import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RONS_TIPS_ROUTE, YOU_ARE_GREAT_ROUTE } from '../../utils/routes';
import {
  getProfileDataAsync,
  getUserDataAsync,
} from '../store/slices/common/userProfileSlice';
import {
  getCurrentUserRegistered,
  getPreferredUsers,
} from '../store/slices/community/loveSlice';
import { getFollowedCategoriesAndSubcategories } from '../store/slices/ronstips/ronstipsSlice';
import { getUserDetails } from '../../utils';

export const useAuth = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const isCommunity = router.pathname === '/community';
  const isRonsTips = router.pathname === '/rons-tips';
  const [isCheckCompleted, setIsCheckCompleted] = useState(false);

  const loveFunction = async (userData) => {
    try {
      const params = {
        user_id: userData?.user_public_id,
        profile_id: userData?.profile_public_id,
        city_id: userData?.city?.id,
      };

      const registrationResult = await dispatch(
        getCurrentUserRegistered(params)
      );
      if (registrationResult.payload !== 'Not Registered Yet') {
        dispatch(getPreferredUsers(params));
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  async function runIfUserNotLoggedIn() {
    const resUser = await dispatch(getUserDataAsync());
    if (resUser?.payload?.user_id) {
      setIsCheckCompleted(true);
      if (isCommunity) loveFunction(resUser?.payload);
      if (isRonsTips) dispatch(getFollowedCategoriesAndSubcategories(resUser?.payload?.profile_id));
      if (!resUser?.payload?.has_active_profile_details) {
        router.replace(YOU_ARE_GREAT_ROUTE(resUser?.payload?.city?.name));
      }
    }
  }

  async function runIfUserDetailsNotFound(userData) {
    const resUserDetails = await dispatch(
      getProfileDataAsync({ profileId: userData?.profile_public_id })
    );

    if (resUserDetails?.payload?.user_id && router.pathname !== '/') {
      router.replace(router?.asPath);
    } else if (resUserDetails?.payload?.user_id && router.pathname === '/') {
      router.replace(RONS_TIPS_ROUTE(resUserDetails?.payload?.city_name));
    } else if (router?.pathname !== '/log-out') {
      router.replace(YOU_ARE_GREAT_ROUTE(userData?.city?.name));
    }
  }

  async function runIfUserLoggedIn(userData) {
    const userDetails = getUserDetails();
    if (userDetails === null && router.pathname !== '/sign-up/you-are-great') {
      runIfUserDetailsNotFound(userData);
    } else if (userDetails && router.pathname === '/sign-up/you-are-great') {
      router.replace(RONS_TIPS_ROUTE(userDetails?.city_name));
    }
  }

  async function getUserInfo() {
    const userData = JSON.parse(localStorage.getItem('userData')) || null;

    if (userData?.user_id) {
      runIfUserLoggedIn(userData);
    } else {
      runIfUserNotLoggedIn();
    }
  }

  useEffect(() => {
    getUserInfo();
  }, [isCheckCompleted]);

  // return [isCheckCompleted];
};
