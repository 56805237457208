import dynamic from 'next/dynamic';

const Footer = dynamic(() => import('../Footer/Footer'));
const NavBar = dynamic(() => import('../NavBar/NavBar/NavBar'));

const PageContainer = ({ showFooter = true, children }) => {
  return (
    <div className='relative bg-white'>
      <NavBar />
      <main>{children}</main>
      {showFooter && <Footer />}
    </div>
  );
};

export default PageContainer;
