import dynamic from 'next/dynamic';

const MdOutlineSpeakerNotesOff = dynamic(() =>
  import('react-icons/md').then((mod) => mod.MdOutlineSpeakerNotesOff)
);

export default function NoLocationCommentWarning({ lang }) {
  return (
    <div className='flex flex-col items-center justify-center px-[24px] pt-[20px]'>
      <MdOutlineSpeakerNotesOff size={36} />
      <div className='mt-4 flex text-left text-sm'>
        {lang?.common?.no_comments_to_show_in_map ||
          'No comments to show in map'}
      </div>
    </div>
  );
}
