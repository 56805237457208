import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from '../../../../utils';
import { BOOSTED_ADS, MARKET_ADS } from '../../../../utils/constant';
import { instance } from '../../../axios/axios';

const MY_PAID_ADS_TAKE = 8;

export const getMyPaidAdsAsync = createAsyncThunk(
  '/getMyPaidAdsAsync',
  async ({
    paid_type = 'boosted',
    ad_type = 'all',
    filter_date = null,
    search_key = null,
    take = MY_PAID_ADS_TAKE,
    skip = 0,
    user_id,
    profile_id,
  }) => {
    try {
      const res = await instance.get(
        `/market/my-paid-ads?user_id=${user_id}&profile_id=${profile_id}&paid_type=${paid_type}&ad_type=${ad_type}&filter_date=${filter_date}&search=${search_key}&take=${take}&skip=${skip}`,
        getHeaders()
      );

      

      return res?.data;
    } catch (err) {
      console.error('Something went wrong', err);
      return null;
    }
  }
);

const myPaidAdsSlice = createSlice({
  name: 'myPaidAds',
  initialState: {
    selectedTab: BOOSTED_ADS,
    selectedBoostedAdsFilter: 'all',
    selectedMarketAdsFilter: 'all',
    selectedDate: null,
    searchKey: null,
    performSearch: false,
    myPaidAds: null,
    loading: false,
    take: MY_PAID_ADS_TAKE,
    skip: 0,
    page: 1,
  },
  reducers: {
    changeTab: (state, action) => {
      state.selectedDate = null;
      state.searchKey = null;
      state.selectedTab = action?.payload;
    },

    handleSelectedFilter: (state, action) => {
      state.selectedDate = null;
      state.searchKey = null;
      const { selectedTab, selectedBoostedAdsFilter, selectedMarketAdsFilter } =
        state;
      if (selectedTab === BOOSTED_ADS) {
        state.selectedBoostedAdsFilter = action?.payload;
      } else if (selectedTab === MARKET_ADS) {
        state.selectedMarketAdsFilter = action?.payload;
      }
    },

    handleSelectedDate: (state, action) => {
      if (action?.payload === 'clear') {
        state.selectedDate = null;
      } else {
        state.selectedDate = action?.payload;
      }
    },

    handleSearch: (state, action) => {
      if (action?.payload === '') {
        state.searchKey = null;
      } else {
        state.searchKey = action?.payload;
      }
    },

    handlePerformSearch: (state, action) => {
      state.performSearch = action.payload;
    },

    updatePageNumber: (state, action) => {
      state.page = action.payload;
      state.skip = state.page * state.take - state.take;
    },

    handleNextPrevious: (state, action) => {
      if (action?.payload === 'next') {
        state.skip = state.page * state.take;
        state.page += 1;
      } else if (action?.payload === 'prev' && state.page > 1) {
        state.page -= 1;
        if (state.page === 1) {
          state.skip = 0;
        } else {
          state.skip = state.page * state.take - state.take;
        }
      }
    },

    resetPagination: (state) => {
      state.page = 1;
      state.skip = 0;
    },
  },

  extraReducers: {
    [getMyPaidAdsAsync.pending]: (state) => {
      state.loading = true;
    },

    [getMyPaidAdsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.myPaidAds = action?.payload;
    },

    [getMyPaidAdsAsync.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const {
  changeTab,
  handleSelectedFilter,
  handleSelectedDate,
  handleSearch,
  handlePerformSearch,
  updatePageNumber,
  handleNextPrevious,
  resetPagination,
} = myPaidAdsSlice.actions;
export default myPaidAdsSlice.reducer;
