import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from '../../../../utils';
import { getProfileId, getUserId } from '../../../../utils/authUtils';
import { instance } from '../../../axios/axios';

const moduleName = 'forumPost';
const apiRoute = `/forum/comment`;

export const likePostAsync = createAsyncThunk(
  `${moduleName}/likePostAsync`,
  async ({ postId, postType }) => {
    
    const response = await instance.post(
      `/${postType}/post-like?post_id=${postId}&profile_id=${getProfileId()}`,
      {},
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);

export const likeCrowdifyPostAsync = createAsyncThunk(
  `${moduleName}/likeCrowdifyPostAsync`,
  async (payload) => {
    
    const response = await instance.post(
      `/market/category/cityoffer/crowdify-post-like`,
      payload,
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);

export const postCommentAsync = createAsyncThunk(
  `${moduleName}/postCommentAsync`,
  async ({ payload, postType }) => {
    
    const response = await instance.post(
      `/${postType}/comment`,
      payload,
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);
export const updateCommentAsync = createAsyncThunk(
  `${moduleName}/updateCommentAsync`,
  async ({ commentId, updateCommentData, postType }) => {
    
    const response = await instance.put(
      `/${postType}/comment-update/${commentId}`,
      updateCommentData,
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);
export const deleteCommentAsync = createAsyncThunk(
  `${moduleName}/deleteCommentAsync`,
  async ({ commentId, postType }) => {
    
    const response = await instance.delete(
      `/${postType}/comment/${commentId}`,
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);

export const likeCommentAsync = createAsyncThunk(
  `${moduleName}/likeCommentAsync`,
  async ({ postId, commentId, postType }) => {
    
    const response = await instance.post(
      `/${postType}/comment-like?post_id=${postId}&comment_id=${commentId}&profile_id=${getProfileId()}`,
      {},
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);

export const dislikeCommentAsync = createAsyncThunk(
  `${moduleName}/dislikeCommentAsync`,
  async ({ postId, commentId, postType }) => {
    
    const response = await instance.post(
      `/${postType}/comment-dislike?post_id=${postId}&comment_id=${commentId}&profile_id=${getProfileId()}`,
      {},
      getHeaders()
    );

    const data = response.data;
    
    return data;
  }
);

export const getCommentsAsync = createAsyncThunk(
  `${moduleName}/getCommentsAsync`,
  async ({ postId, skip = 0, take = 10, filter = 'most_recent', postType }) => {
    
    const response = await instance.get(
      `/${postType}/comment?post_id=${postId}&user_id=${getUserId()}&profile_id=${getProfileId()}&skip=${skip}&take=${take}&sorting=${filter}`,
      getHeaders()
    );

    const data = response;
    
    return data;
  }
);
export const getCommentsWithLocationAsync = createAsyncThunk(
  `${moduleName}/getCommentsWithLocationAsync`,
  async ({ postId, postType }) => {
    const response = await instance.get(
      `/${postType}/comment-location/${postId}`,
      getHeaders()
    );

    

    return response?.data;
  }
);

export const getTotalCommentsAsync = createAsyncThunk(
  `${moduleName}/getTotalCommentsAsync`,
  async ({ postId, postType }) => {
    const response = await instance.get(
      `/${postType}/total-post-comments?post_id=${postId}`,
      getHeaders()
    );

    

    return response?.data;
  }
);

const commentSlice = createSlice({
  name: 'comment',
  initialState: {
    data: null,
    commentsLoading: false,
    comments: null,
    locationComments: null,
    loading: false,
    error: null,
    commentsCount: null,
  },
  reducers: {
    
    addToComments: (state, action) => {
      const temp = JSON.parse(JSON.stringify(state.comments));
      const { data } = temp;
      const updatedData = [action.payload, ...data];
      state.comments.data = updatedData;
    },
    setUpdatingComments: (state, action) => {
      const temp = JSON.parse(JSON.stringify(state.comments));
      const { data } = temp;
      for (const item of data) {
        if (item.id === action.payload.commentId) {
          item.message = 'show_loader';
        }
      }
      state.comments.data = data;
    },
  },
  extraReducers: {
    [likePostAsync.pending]: (state) => {
      state.loading = true;
    },
    [likePostAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [likePostAsync.rejected]: (state, action) => {
      state.error = action.error?.message;
    },
    [likeCrowdifyPostAsync.pending]: (state) => {
      state.loading = true;
    },
    [likeCrowdifyPostAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [likeCrowdifyPostAsync.rejected]: (state, action) => {
      state.error = action.error?.message;
    },
    
    [updateCommentAsync.pending]: (state) => {
      state.loading = true;
    },
    [updateCommentAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateCommentAsync.rejected]: (state, action) => {
      state.error = action?.error?.message;
    },
    [deleteCommentAsync.pending]: (state) => {
      state.commentsLoading = true;
    },
    [deleteCommentAsync.fulfilled]: (state, action) => {
      state.commentsLoading = false;
    },
    [deleteCommentAsync.rejected]: (state, action) => {
      state.commentsLoading = false;
      state.error = action?.error?.message;
    },
    [likeCommentAsync.pending]: (state) => {
      state.loading = true;
    },
    [likeCommentAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [likeCommentAsync.rejected]: (state, action) => {
      state.error = action?.error?.message;
    },

    [getCommentsAsync.pending]: (state) => {
      state.commentsLoading = true;
    },
    [getCommentsAsync.fulfilled]: (state, action) => {
      state.commentsLoading = false;
      state.comments = action.payload;
    },
    [getCommentsAsync.rejected]: (state) => {
      state.commentsLoading = false;
    },
    [getCommentsWithLocationAsync.pending]: (state) => {
      state.loading = true;
    },
    [getCommentsWithLocationAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.locationComments = action.payload;
    },
    [getCommentsWithLocationAsync.rejected]: (state) => {
      state.loading = false;
    },

    [getTotalCommentsAsync.pending]: (state) => {
      state.loading = false;
    },

    [getTotalCommentsAsync.fulfilled]: (state, action) => {
      state.commentsCount = action.payload;
    },

    [getTotalCommentsAsync.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const {  addToComments, setUpdatingComments } =
  commentSlice.actions;
export default commentSlice.reducer;
