import { capitalizeFirst } from '.';
import { getSeoMeta } from '../src/translations/seo';
const MAX_CHARS_FOR_DESC = 160;

const enPostTypes = {
  cityoffer: 'City Offer',
  housing: 'Real estate',
  jobs: 'Jobs',
  events: 'Events',
  secondhand: 'Second Hand',
};
const frPostTypes = {
  cityoffer: 'Offre de ville',
  housing: 'Immobilier',
  jobs: 'Emplois',
  events: 'Événements dans ta ville',
  secondhand: "D'occasion",
};
const dePostTypes = {
  cityoffer: 'Stadtangebot',
  housing: 'Immobilien',
  jobs: 'Jobs',
  events: 'Events in deiner Stadt',
  secondhand: 'Second Hand',
};

function getMetaDescriptionForPosts(data) {
  return data?.replace(/<[^>]+>/g, '').slice(0, MAX_CHARS_FOR_DESC) || '';
}

const defaultImagesAndTypes = (img = '') => {
  return {
    type: 'website',
    images: [
      {
        url:
          img ||
          'https://www.ronorp.net/templates/default/images/neu/i-love-ron.jpg',
        width: 800,
        height: 600,
        alt: 'Og Image',
      },
    ],
  };
};

const twitterMeta = {
  handle: '@handle',
  site: '@site',
  cardType: 'summary_large_image',
};

export const DEFAULT_META = {
  title: 'Ron ORP',
  description: 'Ron ORP',
  canonical: 'https://ronorp.net',
  openGraph: {
    ...defaultImagesAndTypes(),
  },
  twitter: twitterMeta,
};

export const COMMUNITY_HOME_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.community_home_meta_title,
    description: seo?.community_home_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_HOME_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_home_meta_title,
    description: seo?.rons_tips_home_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const MARKET_HOME_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.market_home_meta_title,
    description: seo?.market_home_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_GUIDES_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_guides_meta_title,
    description: seo?.rons_tips_guides_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/guides`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_FAMILY_LIFE_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_family_life_meta_title,
    description: seo?.rons_tips_family_life_meta_description, 
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/family-life`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_FAMILY_LIFE_DIY_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_family_life_diy_meta_title,
    description: seo?.rons_tips_family_life_diy_meta_description, 
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/family-life/diy`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_FAMILY_LIFE_FINANCE_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_family_life_finance_meta_title,
    description: seo?.rons_tips_family_life_finance_meta_description, 
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/family-life/finance`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_FAMILY_LIFE_PARENTHOOD_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_family_life_parenthood_meta_title,
    description: seo?.rons_tips_family_life_parenthood_meta_description,  
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/family-life/parenthood`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_FAMILY_LIFE_PODCASTS_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_family_life_podcasts_meta_title,
    description: seo?.rons_tips_family_life_podcasts_meta_description,  
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/family-life/podcasts`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};  

export const RONS_TIPS_FAMILY_LIFE_TRIPS_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_family_life_trips_meta_title,
    description: seo?.rons_tips_family_life_trips_meta_description, 
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/family-life/trips`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};      

export const RONS_TIPS_FAMILY_LIFE_INSPIRATION_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_family_life_inspiration_meta_title,
    description: seo?.rons_tips_family_life_inspiration_meta_description,   
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/family-life/inspiration`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};      

export const RONS_TIPS_CITY_LIFE_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_city_life_meta_title,
    description: seo?.rons_tips_city_life_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/guides/city-life`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_RONS_EVENTS_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_rons_events_meta_title,
    description: seo?.rons_tips_rons_events_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/guides/rons-events`,  
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_WHATS_HAPPENING_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_whats_happening_meta_title,
    description: seo?.rons_tips_whats_happening_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/guides/whats-happening`,  
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};
  

export const RONS_TIPS_WHATS_UP_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_whats_up_meta_title,
    description: seo?.rons_tips_whats_up_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/guides/whats-up`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_BALANCE_DAY_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_balance_day_meta_title,
    description: seo?.rons_tips_balance_day_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/guides/balance-day`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_PASTIME_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_pastime_meta_title,
    description: seo?.rons_tips_pastime_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/guides/pastime`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_RON_VOYAGE_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_ron_voyage_meta_title,
    description: seo?.rons_tips_ron_voyage_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/guides/ron-voyage`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_RONCAST_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_roncast_meta_title,
    description: seo?.rons_tips_roncast_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/guides/roncast`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_ROOFTOP_DAY_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_rooftop_day_meta_title,
    description: seo?.rons_tips_rooftop_day_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/guides/rooftop-day`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_STREETFOOD_TOUR_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_streetfood_tour_meta_title,
    description: seo?.rons_tips_streetfood_tour_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/guides/streetfood-tour`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_FOOD_AND_SHOP_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_food_and_shop_meta_title,
    description: seo?.rons_tips_food_and_shop_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/food-shopping`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_FOOD_AND_DRINKS_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_food_and_drinks_meta_title,
    description: seo?.rons_tips_food_and_drinks_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/food-shopping/food-drinks`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_NEW_IN_TOWN_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_new_in_town_meta_title,
    description: seo?.rons_tips_new_in_town_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/food-shopping/new-in-town`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_SHOPPING_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_shopping_meta_title,
    description: seo?.rons_tips_shopping_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/food-shopping/shopping`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_CONTEST_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_contest_meta_title,
    description: seo?.rons_tips_contest_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/contest`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_PEOPLE_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_people_meta_title,
    description: seo?.rons_tips_people_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/people`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const RONS_TIPS_OUT_OF_TOWN_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.rons_tips_out_of_town_meta_title,
    description: seo?.rons_tips_out_of_town_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/out-of-town`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const getMarketCategoryPageMeta = (postType, categoryId, city) => {
  if (city == 'Zurich (EN)') {
    postType = enPostTypes[postType];
  } else if (city == 'Romandie') {
    postType = frPostTypes[postType];
  } else {
    postType = dePostTypes[postType];
  }
  return {
    title: `${capitalizeFirst(postType)}`,
    description: `${capitalizeFirst(postType)}`,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/${postType}/${categoryId}`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const getMarketPostTypeHomePageMeta = (postType, city) => {
  

  if (city == 'Zurich (EN)') {
    postType = enPostTypes[postType];
  } else if (city == 'Romandie') {
    postType = frPostTypes[postType];
  } else {
    postType = dePostTypes[postType];
  }
  return {
    title: `${capitalizeFirst(postType)}`,
    description: `${capitalizeFirst(postType)}`,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/${postType}`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const getCommunityCategoryPageMeta = (category, categoryId) => {
  return {
    title: `${capitalizeFirst(category)}`,
    description: `${capitalizeFirst(category)}`,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/categories/${categoryId}?category=${category}`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const getCommunityPostDetailsMeta = (
  postTitle,
  postDescription,
  image,
  postId
) => {
  return {
    title: postTitle || 'Ron Community Post Details',
    description:
      getMetaDescriptionForPosts(postDescription) ||
      'Ron Community Post Details',
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/posts/${postId}`,
    openGraph: {
      ...defaultImagesAndTypes(image),
    },
    twitter: twitterMeta,
  };
};

export const getRonsTipsPostDetailsMeta = (
  postTitle,
  postDescription,
  image,
  postId
) => {
  return {
    title: postTitle || "Ron's Tips Post Details",
    description:
      getMetaDescriptionForPosts(postDescription) || "Ron's Tips Post Details",
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/${postId}`,
    openGraph: {
      ...defaultImagesAndTypes(image),
    },
    twitter: twitterMeta,
  };
};

export const getRonsTipsListiclePostDetailsMeta = (
  postTitle,
  postDescription,
  image,
  postId
) => {
  return {
    title: postTitle || "Ron's Tips Listicle",
    description:
      getMetaDescriptionForPosts(postDescription) || "Ron's Tips Listicle",
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/${postId}`,
    openGraph: {
      ...defaultImagesAndTypes(image),
    },
    twitter: twitterMeta,
  };
};

export const getMarketPostDetailsMeta = (
  postTitle,
  postDescription,
  image,
  postId
) => {
  return {
    title: postTitle || 'Market Post Details',
    description:
      getMetaDescriptionForPosts(postDescription) || 'Market Post Details',
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/posts/${postId}`,
    openGraph: {
      ...defaultImagesAndTypes(image),
    },
    twitter: twitterMeta,
  };
};

export const ABOUT_RON_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.about_ron_meta_title,
    description: seo?.about_ron_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/about-ron`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const GTC_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.terms_and_conditions_meta_title,
    description: seo?.terms_and_conditions_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/gtc`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const PRIVACY_POLICY_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.privacy_policy_meta_title,
    description: seo?.privacy_policy_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/privacy-policy`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const ADVERTISE_ON_RON_ORP_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.advertise_on_ron_orp_meta_title,
    description: seo?.advertise_on_ron_orp_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/advertise-on-ron-orp`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const PLACE_AD_ON_WEBSITE_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.place_ad_on_website_meta_title,
    description: seo?.place_ad_on_website_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/place-ad-on-website`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const POST_IN_NEWSLETTER_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.post_in_newsletter_meta_title,
    description: seo?.post_in_newsletter_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/post-in-newsletter`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const TOP_PLACEMENT_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.top_placement_meta_title,
    description: seo?.top_placement_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/top-placement`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const START_DISCUSSION_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.start_new_discussion_meta_title,
    description: seo?.start_new_discussion_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/start-discussion`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const CREATE_STORY_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.create_story_meta_title,
    description: seo?.create_story_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/start-discussion/create-story`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const CREATE_SURVEY_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.create_survey_meta_title,
    description: seo?.create_survey_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/start-discussion/create-survey`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const CREATE_PHOTOTRIP_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.create_phototrip_meta_title,
    description: seo?.create_phototrip_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/start-discussion/create-phototrip`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const CREATE_TOGETHER_EVENT_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.create_together_event_meta_title,
    description: seo?.create_together_event_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/start-discussion/create-event`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const LOVE_REGISTRATION_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.love_registration_meta_title,
    description: seo?.love_registration_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/community/love-registration`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const PLACE_AD_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.place_ad_meta_title,
    description: seo?.place_ad_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/place-ad`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const CREATE_EVENT_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.create_events_meta_title,
    description: seo?.create_phototrip_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/place-ad/events`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const CREATE_HOUSING_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.create_housing_meta_title,
    description: seo?.create_housing_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/place-ad/housing`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const CREATE_SECONDHAND_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.create_secondhand_meta_title,
    description: seo?.create_secondhand_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/place-ad/secondhand`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const CREATE_JOBS_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.create_jobs_meta_title,
    description: seo?.create_jobs_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/place-ad/jobs`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const CREATE_CITY_OFFER_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.create_city_offer_meta_title,
    description: seo?.create_city_offer_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/place-ad/cityoffer`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const CREATE_COMMERCIAL_META = (city) => {
  const seo = getSeoMeta(city);
  return {
    title: seo?.create_create_commercial_meta_title,
    description: seo?.create_create_commercial_meta_description,
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/market/place-ad/commercial`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const ACCOUNT_SETTINGS_META = {
  title: 'Account Settings',
  description: 'Account Settings',
  canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/account-settings`,
  openGraph: {
    ...defaultImagesAndTypes(),
  },
  twitter: twitterMeta,
};

export const ADD_NEW_PROFILE_META = {
  title: 'Add New Profile',
  description: 'Add New Profile',
  canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/add-new-profile`,
  openGraph: {
    ...defaultImagesAndTypes(),
  },
  twitter: twitterMeta,
};

export const APPLY_AS_A_CITY_WRITER_META = {
  title: 'Apply as a City Writer',
  description: 'Apply as a City Writer',
  canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/apply-as-a-city-writer`,
  openGraph: {
    ...defaultImagesAndTypes(),
  },
  twitter: twitterMeta,
};

export const CHATS_META = {
  title: 'Chats',
  description: 'Chats',
  canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/chats`,
  openGraph: {
    ...defaultImagesAndTypes(),
  },
  twitter: twitterMeta,
};

export const CHECKOUT_META = {
  title: 'Checkout',
  description: 'Checkout',
  canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/checkout`,
  openGraph: {
    ...defaultImagesAndTypes(),
  },
  twitter: twitterMeta,
};

export const DASHBOARD_META = {
  title: 'Dashboard',
  description: 'Dashboard',
  canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/dashboard`,
  openGraph: {
    ...defaultImagesAndTypes(),
  },
  twitter: twitterMeta,
};

export const EDIT_PROFILE_META = {
  title: 'Edit Profile',
  description: 'Edit Profile',
  canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/edit-profile`,
  openGraph: {
    ...defaultImagesAndTypes(),
  },
  twitter: twitterMeta,
};

export const MY_FOLLOWS_META = {
  title: 'My Follows',
  description: 'My Follows',
  canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/my-follows`,
  openGraph: {
    ...defaultImagesAndTypes(),
  },
  twitter: twitterMeta,
};

export const NOTIFICATIONS_META = {
  title: 'Notifications',
  description: 'Notifications',
  canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/notifications`,
  openGraph: {
    ...defaultImagesAndTypes(),
  },
  twitter: twitterMeta,
};

export const getProfileMeta = (name) => {
  return {
    title: `Profile | ${name}`,
    description: 'Profile',
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/profile`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const getSearchResultsMeta = (module = 'community') => {
  return {
    title: `Search Results | ${capitalizeFirst(module)}`,
    description: 'Search Results',
    canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${module}/search`,
    openGraph: {
      ...defaultImagesAndTypes(),
    },
    twitter: twitterMeta,
  };
};

export const NEWSLETTER_ARCHIVE_META = {
  title: 'Newsletter Archive',
  description: 'Newsletter Archive',
  canonical: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/rons-tips/newsletter-archive`,
  openGraph: {
    ...defaultImagesAndTypes(),
  },
  twitter: twitterMeta,
};
