import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaders } from '../../../../utils';
import { instance } from '../../../axios/axios';
import { getProfileId } from '../../../../utils/authUtils';

const moduleName = 'acSetting';
const apiRoute = `/user-details`;

export const getNewsLetterCityDataAsync = createAsyncThunk(
  `${moduleName}/getNewsLetterCityDataAsync`,
  async () => {
    const response = await instance.get(
      `/user-newsletter-city?profile_id=${getProfileId()}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);

export const updateNewsLetterCityDataAsync = createAsyncThunk(
  `${moduleName}/updateNewsLetterCityDataAsync`,
  async (payload) => {
    
    const response = await instance.post(
      `/user-newsletter-city`,
      payload,
      getHeaders()
    );
    
    const data = response?.data;
    return data;
  }
);

export const pauseNewsLetterAsync = createAsyncThunk(
  `${moduleName}/pauseNewsLetterAsync`,
  async (payload) => {
    
    const response = await instance.post(
      `/user-pause-newsletter`,
      payload,
      getHeaders()
    );
    
    const data = response?.data;
    return data;
  }
);

export const getAlertNotificationsAsync = createAsyncThunk(
  `${moduleName}/getAlertNotificationsAsync`,
  async () => {
    const response = await instance.get(
      `/user-alert-notifications?profile_id=${getProfileId()}`,
      getHeaders()
    );

    const data = response.data;

    return data;
  }
);

export const updateAlertNotificationAsync = createAsyncThunk(
  `${moduleName}/updateAlertNotificationAsync`,
  async (payload) => {
    
    const response = await instance.post(
      `/user-alert-notifications`,
      payload,
      getHeaders()
    );
    
    const data = response?.data;
    return data;
  }
);
export const getGeneralSettingsAsync = createAsyncThunk(
  `${moduleName}/getGeneralSettingsAsync`,
  async () => {
    const response = await instance.get(`/user-account-general`, getHeaders());

    const data = response.data;

    return data;
  }
);

export const updateGeneralSettingsAsync = createAsyncThunk(
  `${moduleName}/updateGeneralSettingsAsync`,
  async (payload) => {
    
    const response = await instance.post(
      `/user-account-general`,
      payload,
      getHeaders()
    );
    
    const data = response?.data;
    return data;
  }
);

const acSettingSlice = createSlice({
  name: 'acSetting',
  initialState: {
    publicUser: null,
    data: null,
    generalSettings: null,
    newsLetterCity: null,
    alertNotifications: null,
    loading: false,
    newsLetterCityloading: false,
    openAllProfilesModal: false,
    confirmProfileSwitchModal: false,
    switchingProfile: null,
  },
  reducers: {},
  extraReducers: {
    [getNewsLetterCityDataAsync.pending]: (state) => {
      state.newsLetterCityloading = true;
    },
    [getNewsLetterCityDataAsync.fulfilled]: (state, action) => {
      state.newsLetterCityloading = false;
      state.newsLetterCity = action.payload;
    },
    [getNewsLetterCityDataAsync.rejected]: (state) => {
      state.newsLetterCityloading = false;
    },
    [updateNewsLetterCityDataAsync.pending]: (state) => {
      state.loading = true;
    },
    [updateNewsLetterCityDataAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateNewsLetterCityDataAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getAlertNotificationsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getAlertNotificationsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.alertNotifications = action.payload;
    },
    [getAlertNotificationsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [updateAlertNotificationAsync.pending]: (state) => {
      state.loading = true;
    },
    [updateAlertNotificationAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateAlertNotificationAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getGeneralSettingsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getGeneralSettingsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.generalSettings = action.payload;
    },
    [getGeneralSettingsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [updateGeneralSettingsAsync.pending]: (state) => {
      state.loading = true;
    },
    [updateGeneralSettingsAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateGeneralSettingsAsync.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default acSettingSlice.reducer;
