import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getAccountType } from '.';
import NoLocationCommentWarning from '../src/components/Comments/MapView/NoLocationCommentWarning';
import DeleteDialog from '../src/components/Common/AlertDialog/DeleteDialog';
import RemoveFollowerDialog from '../src/components/Common/AlertDialog/RemoveFollowerDialog';
import UnfollowDialog from '../src/components/Common/AlertDialog/UnfollowDialog';
import {
  checkIfPostEditable,
  checkIsAdmin,
  getProfileId,
  hasPermissionToEditDeleteCommunityMarketPosts,
} from './authUtils';
import { COMMUNITY_ROUTE } from './routes';

const MySwal = withReactContent(Swal);

export const ronsTipsPostDotMenuOptions = (params, lang) => {
  const { shareHandler = () => {} } = params;

  return [
    {
      label: lang?.common?.share,
      onClickHandler: shareHandler,
    },
  ];
};

export const communityPostDotMenuOptions = (params, lang) => {
  const {
    editHandler = () => {},
    shareHandler = () => {},
    deleteHandler = () => {},
    postAuthorId = '',
    postAuthorProfileId = '',
    cityId,
  } = params;

  const isPostEditable = checkIfPostEditable({
    authorId: postAuthorId,
    authorProfileId: postAuthorProfileId,
    cityId,
  });

  return [
    {
      label: lang?.common?.share,
      onClickHandler: shareHandler,
    },
    isPostEditable && {
      label: lang?.common?.edit,
      onClickHandler: editHandler,
    },
    isPostEditable && {
      label: lang?.common?.delete,
      onClickHandler: deleteHandler,
    },
  ];
};
export const marketPostDotMenuOptions = (params, lang) => {
  const {
    editHandler = () => {},
    shareHandler = () => {},
    deleteHandler = () => {},
    favouritePostHandler = () => {},
    postAuthorId = '',
    postAuthorProfileId = '',
    isFavourite,
    cityId,
  } = params;

  const isPostEditable = checkIfPostEditable({
    authorId: postAuthorId,
    authorProfileId: postAuthorProfileId,
    cityId,
  });
  const isAdmin = checkIsAdmin();

  return [
    {
      label: lang?.common?.share,
      onClickHandler: shareHandler,
    },
    isPostEditable && {
      label: lang?.common?.edit,
      onClickHandler: editHandler,
    },
    isPostEditable && {
      label: lang?.common?.delete,
      onClickHandler: deleteHandler,
    },
    isAdmin && {
      label: isFavourite
        ? lang?.common?.mark_as_unfavorite
        : lang?.common?.mark_as_favorite,
      onClickHandler: favouritePostHandler,
    },
  ];
};

export const getCityId = () => {
  if (typeof window !== 'undefined') {
    const city = JSON.parse(localStorage.getItem('selectedCity'));
    return city ? city.id : '';
  }
};

export const getLangId = () => {
  // 1->English, 2->German(de), 3->French(fr)
  const city = JSON.parse(localStorage.getItem('selectedCity')) || null;
  if (!city?.lang_id) return 3;
  else return city?.lang_id;
};

export const showErrorAlertForPostSubmit = (lang, msg = '') => {
  Swal.fire({
    icon: 'error',
    iconColor: '#000',
    title: lang?.common?.oops,
    text: msg ? lang?.market[msg] : lang?.common?.something_went_wrong,
    timer: 2000,
    showConfirmButton: false,
  });
};

export const showSuccessAlertForPostSubmit = (text, lang) => {
  Swal.fire({
    icon: 'success',
    iconColor: '#000',
    title: lang?.common?.congratulations,
    text,
    timer: 2000,
    showConfirmButton: false,
  });
};
export const showSuccessAlert = ({
  title = 'Congratulations!',
  subtitle = `Action successful!`,
}) => {
  Swal.fire({
    icon: 'success',
    iconColor: '#000',
    title: title,
    text: subtitle,
    timer: 2000,
    showConfirmButton: false,
  });
};

export function showDeleteWarning(lang) {
  return MySwal.fire({
    title: <DeleteDialog lang={lang} />,
    text: '',
    icon: '',
    iconColor: '#000',
    showCancelButton: true,
    confirmButtonColor: '#000',
    confirmButtonText: lang?.common?.yes_delete_it,
    cancelButtonText: lang?.common?.cancel,
    customClass: {
      actions: '',
      cancelButton: 'order-1',
      confirmButton: 'order-2',
      title: 'title-style',
      // htmlContainer: '!p-0',
      popup: '!p-0',
    },
  });
}

export function showUnfollowWarning({ name = '', item, withoutName, lang }) {
  return MySwal.fire({
    title: (
      <UnfollowDialog
        name={name}
        item={item}
        withoutName={withoutName}
        lang={lang}
      />
    ),
    text: '',
    icon: '',
    iconColor: '#000',
    showCancelButton: true,
    confirmButtonColor: '#000',
    confirmButtonText: lang?.common?.yes_unfollow,
    cancelButtonText: lang?.common?.cancel,
    showCloseButton: true,
    customClass: {
      actions: '',
      cancelButton: 'order-1',
      confirmButton: 'order-2',
      title: 'title-style',
      closeButton: 'close-button-swal',
      popup: '!p-0',
    },
  });
}

export function showRemoveFollowerWarning(lang) {
  return MySwal.fire({
    title: <RemoveFollowerDialog lang={lang} />,
    text: '',
    icon: '',
    iconColor: '#000',
    showCancelButton: true,
    confirmButtonColor: '#000',
    confirmButtonText: lang?.common?.remove,
    cancelButtonText: lang?.common?.cancel,
    showCloseButton: true,
    customClass: {
      actions: '',
      cancelButton: 'order-1',
      confirmButton: 'order-2',
      title: 'title-style',
      closeButton: 'close-button-swal',
      popup: '!p-0',
    },
  });
}

export function showNoLocationCommentWarning(lang) {
  return MySwal.fire({
    title: <NoLocationCommentWarning lang={lang} />,
    text: '',
    icon: '',
    iconColor: '#000',
    showConfirmButton: true,
    confirmButtonColor: '#000',
    confirmButtonText: lang?.common?.ok,
    // showCloseButton: true,
    customClass: {
      popup: '!p-0',
      //   actions: 'button-position-unfollow',
      //   cancelButton: 'order-1',
      //   confirmButton: 'order-2',
      //   title: 'title-style',
      //   closeButton: 'close-button-swal'
    },
  });
}

export function showDeleteConfirmation(msg = '', lang) {
  Swal.fire({
    icon: 'success',
    iconColor: '#000',
    title: lang?.common?.success || 'Success',
    text: msg,
    timer: 2000,
    showConfirmButton: false,
  });
}

export function showSomethingWentWrongMessage(lang, msg = '') {
  Swal.fire({
    icon: 'error',
    iconColor: '#000',
    title: lang?.common?.bad_request || 'Bad Request',
    text: msg || lang?.common?.something_went_wrong,
    timer: 3000,
    showConfirmButton: false,
  });
}

export function showAlreadyAppliedMessage(lang) {
  Swal.fire({
    icon: 'warning',
    iconColor: '#000',
    title: lang?.common?.bad_request,
    text: lang?.rons_tips?.you_have_already_applied_for_city_writer,
    timer: 2000,
    showConfirmButton: false,
  });
}

export function hasEditPermissionForCommunityPost(
  res,
  router,
  city,
  setLoading
) {
  const discussionType = res?.payload?.discussion_type;
  const cityId = Number(res?.payload[discussionType]?.city[0]?.id);
  if (res?.payload?.author_info?.profile_id == getProfileId()) {
    setLoading(false);
    return true;
  } else if (hasPermissionToEditDeleteCommunityMarketPosts(cityId)) {
    setLoading(false);
    return true;
  } else {
    router.replace(COMMUNITY_ROUTE(city));
  }
}

export const filterLoveFromCommunityCategories = (categories) => {
  if (categories && categories?.length > 0) {
    return getAccountType() === 'Company'
      ? categories.filter((cat) => cat?.id !== 15)
      : categories;
  }

  return [];
};

export const getStoryCategories = (isAdmin, categories) => {
  if (isAdmin) {
    const tempCategories = categories?.filter(
      (item) =>
        item.id !== 14 && // Together
        item.id !== 15 && // Love
        item.id !== 17 && // Phototrip
        item.id !== 16 // My Area
    );
    return tempCategories;
  } else {
    const tempCategories = categories?.filter(
      (item) =>
        item.id !== 14 && // Together
        item.id !== 15 && // Love
        item.id !== 17 && // Phototrip
        item.id !== 16 && // My Area
        item.id !== 18 // Ronstar Help Ronstar
    );
    return tempCategories;
  }
};
export const getSurveyCategories = (categories) => {
  const tempCategories = categories?.filter(
    (item) =>
      item.id !== 14 && // Together
      item.id !== 15 && // Love
      item.id !== 17 && // Phototrip
      item.id !== 16 && // My Area
      item.id !== 18 // Ronstar Help Ronstar
  );
  return tempCategories;
};

export function getTitle(singlePost) {
  if (singlePost) {
    if (singlePost?.discussion_type === 'story') {
      return singlePost?.story?.title;
    } else if (singlePost?.discussion_type === 'survey') {
      return singlePost?.survey?.title;
    } else if (singlePost?.discussion_type === 'phototrip') {
      return singlePost?.phototrip?.title;
    } else if (singlePost?.discussion_type === 'event') {
      return singlePost?.event?.title;
    }
  }

  return '';
}

export function getDescription(singlePost) {
  if (singlePost) {
    if (singlePost?.discussion_type === 'story') {
      return singlePost?.story?.description;
    } else if (singlePost?.discussion_type === 'survey') {
      return singlePost?.survey?.description;
    } else if (singlePost?.discussion_type === 'phototrip') {
      return singlePost?.phototrip?.description;
    } else if (singlePost?.discussion_type === 'event') {
      return singlePost?.event?.description;
    }
  }

  return '';
}

export function getCommunityPostImage(singlePost) {
  if (singlePost) {
    if (singlePost?.discussion_type === 'story' && singlePost?.story?.image) {
      return singlePost?.story?.image?.path;
    } else if (
      singlePost?.discussion_type === 'survey' &&
      singlePost?.survey?.image
    ) {
      return singlePost?.survey?.image?.path;
    } else if (
      singlePost?.discussion_type === 'phototrip' &&
      singlePost?.phototrip?.image
    ) {
      return singlePost?.phototrip?.image?.path;
    } else if (
      singlePost?.discussion_type === 'event' &&
      singlePost?.event?.image
    ) {
      return singlePost?.event?.image?.path;
    } else return null;
  }

  return '';
}

export const getCommunityPostDescriptionLines = (noImage, titleLines) => {
  switch (titleLines) {
    case 1:
      return noImage ? 11 : 4;
    case 2:
      return noImage ? 9 : 2;
    case 3:
      return noImage ? 8 : 3;
    default:
      return noImage ? 8 : 3;
  }
};
