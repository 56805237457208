// import imageCompression from 'browser-image-compression';
import { clsx } from 'clsx';
import { format } from 'date-fns';
import { de, enUS, fr } from 'date-fns/locale';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { twMerge } from 'tailwind-merge';
import { instance } from '../src/axios/axios';
import { countryNames } from '../src/config/countries';
import store from '../src/store';
import { getProfilePublicId } from './authUtils';
import cryptr, { decrypt, encrypt } from './cryptr';

export const lang = store?.getState()?.common?.language;

export default function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const convertDate = (date) => {
  return format(new Date(date), 'yyyy-MM-dd');
};
export const convertTime = (time) => {
  return format(new Date(time), 'HH:mm:ss');
};
export const convertTimeToAMPM = (str) => {
  let [hh, mm, ss] = str.split(':');
  let hour = 0;
  if (hh === '00') hour = '12';
  else if (hh > 12) hour = hh - 12;
  else hour = hh;

  return `${hour}${+mm > 0 ? `:${mm}` : ''}${hh >= 12 ? ' PM' : ' AM'}`;
};

export const convertDateToJsDateType = (date) => {
  return new Date(date);
};
export const convertTimeToDateType = (time) => {
  if (time === null || !time) return;

  const d = new Date(); // Creates a Date Object using the clients current time

  const [hours, minutes, seconds] = time.split(':');

  d.setHours(+hours); // Set the hours, using implicit type coercion
  d.setMinutes(minutes); // can pass Number or String - doesn't really matter
  // d.setSeconds(seconds);

  // If needed, you could also adjust date and time zone

  return d;
};

export function formatDate(dateString) {
  const formattedDate = format(new Date(dateString), 'do MMM, yyyy');
  return formattedDate;
}

export const getFormattedDate = (date, cityId = 1) => {
  if (!date) return '';

  let dateFormat = 'EEE d MMM';

  if (cityId == 1) dateFormat = 'EEE, d MMM';

  return format(new Date(date), dateFormat, { locale: getLocale(cityId) });
};

export const getFormattedTime = (time) => {
  if (!time) return '';
  const [hours, minutes] = time.split(':');
  return `${hours}:${minutes}`;
};

export const redirectToLogin = () => {
  window.location.replace(`${process.env.NEXT_PUBLIC_BACKEND_URL}/login/azure`);
};

export const toastify = ({ icon = 'success', title = 'Action Successful' }) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
  Toast.fire({
    icon: icon,
    title: title,
  });
};

export const makePopUp = ({
  icon = 'success',
  title = 'Action Successful',
}) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
  Toast.fire({
    icon: icon,
    title: title,
  });
};

export const getUserCity = () => {
  const userData = getUserDetails();

  if (userData) {
    return {
      id: userData?.city_id,
      name: userData?.city_name,
    };
  }

  return null;
};

export const getHeaders = () => {
  if (localStorage.getItem('access_token')) {
    const token = cryptr.decrypt(localStorage.getItem('access_token'));
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  } else {
    return null;
  }
};

export const getHeadersFromCookie = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};
export const getRefreshToken = () => {
  return cryptr.decrypt(localStorage.getItem('refresh_token'));
};

export function formatCurrency(value) {
  return new Intl.NumberFormat('en-CH').format(value);
}

export const linkify = (inputText = '') => {
  if (/<a\s+.*?href=.*?>.*?<\/a>/i.test(inputText)) {
    return inputText; // Return as-is if already linkified
  }
  if (inputText?.includes('https://')) {
    inputText = inputText.replaceAll('https://', 'www.');
  }

  if (inputText?.includes('www.www.')) {
    inputText = inputText.replaceAll('www.www.', 'www.');
  }

  var replacedText, replacePattern1, replacePattern2, replacePattern3;
  if (inputText?.indexOf('<a ')) {
    //URLs starting with http://, https://, or ftp://
    replacePattern1 =
      /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText?.replace(
      replacePattern1,
      ' <a class=""  href="$1" target="_blank">$1</a>'
    );

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText?.replace(
      replacePattern2,
      ' <a class=""  href="http://$2" target="_blank">$2</a>'
    );

    //Change email addresses to mailto:: links.
    replacePattern3 = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})/g;
    replacedText = replacedText?.replace(
      replacePattern3,
      ' <a class="" href="mailto:$&">$&</a>'
    );
  } else {
    replacedText = inputText;
  }
  // alert(replacedText)
  return replacedText;
};

export const stripHtml = (html) => {
  // Create a new div element
  var temporalDivElement = document.createElement('div');
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return temporalDivElement.textContent || temporalDivElement.innerText || '';
};

export const capitalizeFirst = (sentence) => {
  const words = sentence?.split(' ');

  for (let i = 0; i < words?.length; i++) {
    words[i] = words[i][0]?.toUpperCase() + words[i]?.substr(1);
  }

  return words?.join(' ');
};
export const isArrayIncludesElement = (element, array) => {
  return array.some((item) => element.includes(item));
};
export const findArrayElement = (field = 'id', value, array) => {
  return array?.find((item) => item.id == value);
};
export const convertStrArrayToNumArray = (strArray) => {
  const numbersArray = JSON.parse(strArray.replace(/\s/g, ''));
  const arrayOfNumbers = numbersArray.map((num) => parseInt(num));
  return arrayOfNumbers;
};

export function concatenateStrings(arr) {
  return arr.join(', ');
}

export const getUserDetails = () => {
  if (typeof window !== 'undefined') {
    const doesExist = localStorage.getItem('userDetails') || '';
    if (doesExist?.includes('user_id')) {
      const userDetails = JSON.parse(doesExist) || null;
      return userDetails;
    } else if (doesExist) {
      const decryptUserDetails = decrypt(localStorage.getItem('userDetails'));
      const userDetails = JSON.parse(decryptUserDetails) || null;
      return userDetails;
    }
  }

  return null;
};

export const getUserData = () => {
  if (typeof window !== 'undefined') {
    const userData = JSON.parse(localStorage.getItem('userData')) || null;
    return userData;
  }

  return null;
};

export const setUserDetails = ({ profileId }) => {
  const apiRoute = `/user-details?profile_public_id=${profileId || getProfilePublicId()}`;
  instance.get(apiRoute, getHeaders()).then((res) => {
    localStorage.setItem('userDetails', encrypt(JSON.stringify(res.data)));
  });
};

export function formatNumber(value) {
  // If the value is greater than or equal to 1 million
  if (value >= 1000000) {
    // Convert to millions and round to one decimal place
    return (value / 1000000).toFixed(1) + 'M';
  }
  // If the value is greater than or equal to 1000
  else if (value >= 1000) {
    // Convert to thousands
    return Math.round(value / 1000) + 'k';
  }
  // If the value is less than 1000
  else {
    // Return the original value
    return value.toString();
  }
}

export const extractNumericValue = (stringWithUnit) => {
  const numericPart = stringWithUnit.match(/\d+/)?.[0];
  if (numericPart) {
    return parseInt(numericPart, 10);
  }
  return null;
};

export function validateUrl(value) {
  return /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
}

export function convertShortLinkToFullURL(shortUrl) {
  // Extract the video ID from the short URL
  const videoId = shortUrl.split('/')[3].split('?')[0];

  // Create the full YouTube URL using the extracted video ID
  const fullURL = `https://www.youtube.com/watch?v=${videoId}`;

  return fullURL;
}

export function isYoutubeShortLink(url) {
  // Check if the URL starts with "https://youtu.be/" or "http://youtu.be/"
  return (
    url.startsWith('https://youtu.be/') || url.startsWith('http://youtu.be/')
  );
}

export function validatePlatformUrl(value, platform) {
  let urlRegex;

  // Define URL validation patterns for each platform
  const platformPatterns = {
    Facebook: /^(https?:\/\/)?([a-z0-9-]+\.)?facebook\.com\/.*/,
    Instagram: /^(https?:\/\/)?([a-z0-9-]+\.)?instagram\.com\/.*/,
    Youtube: /^(https?:\/\/)?([a-z0-9-]+\.)?youtu(?:\.be|be\.com)\/.*/,
    Pinterest: /^(https?:\/\/)?([a-z0-9-]+\.)?pinterest\.com\/.*/,
    Soundcloud: /^(https?:\/\/)?([a-z0-9-]+\.)?soundcloud\.com\/.*/,
    Spotify: /^(https?:\/\/)?([a-z0-9-]+\.)?open\.spotify\.com\/.*/,
  };

  // Use the specified platform's pattern, or a generic pattern if not found
  urlRegex = platformPatterns[platform] || /^(https?:\/\/)?([a-z0-9-]+\.)?.*/;

  return urlRegex.test(value);
}

export function getPlatformFromUrl(url) {
  // Define URL validation patterns for each platform
  const platformPatterns = {
    Facebook: /^(https?:\/\/)?(www\.)?facebook\.com\/.*/,
    Instagram: /^(https?:\/\/)?(www\.)?instagram\.com\/.*/,
    Youtube: /^(https?:\/\/)?(?:www\.)?youtu(?:\.be|be\.com)\/.*/, // Updated Youtube pattern
    Pinterest: /^(https?:\/\/)?(www\.)?pinterest\.com\/.*/,
    Soundcloud: /^(https?:\/\/)?(www\.)?soundcloud\.com\/.*/,
    Spotify: /^(https?:\/\/)?(www\.)?open\.spotify\.com\/track\/.*/,
  };

  // Iterate over each platform pattern to find a match
  for (const [platform, pattern] of Object.entries(platformPatterns)) {
    if (pattern.test(url)) {
      return platform;
    }
  }

  // Return 'Unknown' if no platform matches
  return 'Unknown';
}

// export const all_cities = [
//   {
//     id: 5,
//     value: 5,
//     name: "Basel",
//     label: "Basel",
//     lang_id: 3,
//     created_at: "2023-02-02T06:58:41.000000Z",
//     updated_at: "2023-02-02T06:58:41.000000Z",
//     location: {
//       lat: 47.5596,
//       lng: 7.5886,
//     },
//   },
//   {
//     id: 6,
//     value: 6,
//     name: "Bern",
//     label: "Bern",
//     lang_id: 3,
//     created_at: "2023-02-02T06:58:41.000000Z",
//     updated_at: "2023-02-02T06:58:41.000000Z",
//     location: {
//       lat: 46.948,
//       lng: 7.4474,
//     },
//   },
//   {
//     id: 11,
//     value: 11,
//     name: "Family NL",
//     label: "Family NL",
//     lang_id: 3,
//     created_at: "2023-02-02T06:58:41.000000Z",
//     updated_at: "2023-02-02T06:58:41.000000Z",
//     location: {
//       lat: 52.1326,
//       lng: 5.2913,
//     },
//   },
//   {
//     id: 3,
//     value: 3,
//     name: "Genève",
//     label: "Genève",
//     lang_id: 2,
//     created_at: "2023-02-02T06:58:41.000000Z",
//     updated_at: "2023-02-02T06:58:41.000000Z",
//     location: {
//       lat: 46.2044,
//       lng: 6.1432,
//     },
//   },
//   {
//     id: 4,
//     value: 4,
//     name: "Lausanne",
//     label: "Lausanne",
//     lang_id: 2,
//     created_at: "2023-02-02T06:58:41.000000Z",
//     updated_at: "2023-02-02T06:58:41.000000Z",
//     location: {
//       lat: 46.5196,
//       lng: 6.6323,
//     },
//   },
//   {
//     id: 7,
//     value: 7,
//     name: "Luzern",
//     label: "Luzern",
//     lang_id: 3,
//     created_at: "2023-02-02T06:58:41.000000Z",
//     updated_at: "2023-02-02T06:58:41.000000Z",
//     location: {
//       lat: 47.0502,
//       lng: 8.3093,
//     },
//   },
//   {
//     id: 8,
//     value: 8,
//     name: "St. Gallen",
//     label: "St. Gallen",
//     lang_id: 3,
//     created_at: "2023-02-02T06:58:41.000000Z",
//     updated_at: "2023-02-02T06:58:41.000000Z",
//     location: {
//       lat: 47.4979,
//       lng: 9.491,
//     },
//   },
//   {
//     id: 9,
//     value: 9,
//     name: "Winterthur",
//     label: "Winterthur",
//     lang_id: 3,
//     created_at: "2023-02-02T06:58:41.000000Z",
//     updated_at: "2023-02-02T06:58:41.000000Z",
//     location: {
//       lat: 47.5056,
//       lng: 8.7241,
//     },
//   },
//   {
//     id: 10,
//     value: 10,
//     name: "Zug",
//     label: "Zug",
//     lang_id: 3,
//     created_at: "2023-02-02T06:58:41.000000Z",
//     updated_at: "2023-02-02T06:58:41.000000Z",
//     location: {
//       lat: 47.1712,
//       lng: 8.5167,
//     },
//   },
//   {
//     id: 2,
//     value: 2,
//     name: "Zürich",
//     label: "Zürich",
//     lang_id: 3,
//     created_at: "2023-02-02T06:58:41.000000Z",
//     updated_at: "2023-02-02T06:58:41.000000Z",
//     location: {
//       lat: 47.3667,
//       lng: 8.55,
//     },
//   },
//   {
//     id: 1,
//     value: 1,
//     name: "Zurich (EN)",
//     label: "Zurich (EN)",
//     lang_id: 1,
//     created_at: "2023-02-02T06:58:41.000000Z",
//     updated_at: "2023-02-02T06:58:41.000000Z",
//     location: {
//       lat: 47.3667,
//       lng: 8.55,
//     },
//   },
// ];

export const all_cities = [
  {
    id: 4,
    value: 4,
    name: 'Basel',
    label: 'Basel',
    lang_id: 3,
    created_at: '2023-10-30T08:23:25.000000Z',
    updated_at: '2023-10-30T08:23:25.000000Z',
    location: {
      lat: 47.5596,
      lng: 7.5886,
    },
    fb_link: 'https://www.facebook.com/ronorpbasel',
    insta_link: 'https://www.instagram.com/ron_orp_basel/',
  },
  {
    id: 5,
    value: 5,
    name: 'Bern',
    label: 'Bern',
    lang_id: 3,
    created_at: '2023-10-30T08:23:25.000000Z',
    updated_at: '2023-10-30T08:23:25.000000Z',
    location: {
      lat: 46.948,
      lng: 7.4474,
    },
    fb_link: 'https://www.facebook.com/ronorpbern',
    insta_link: 'https://www.instagram.com/ron_orp_bern/',
  },
  {
    id: 9,
    value: 9,
    name: 'Family Zurich',
    label: 'Family Zurich',
    lang_id: 3,
    created_at: '2023-10-30T08:23:25.000000Z',
    updated_at: '2023-10-30T08:23:25.000000Z',
    location: {
      lat: 52.1326,
      lng: 5.2913,
    },
    fb_link: 'https://www.facebook.com/ronorpfamily',
    insta_link: 'https://www.instagram.com/ronorpfamily/',
  },
  {
    id: 6,
    value: 6,
    name: 'Luzern',
    label: 'Luzern',
    lang_id: 3,
    created_at: '2023-10-30T08:23:25.000000Z',
    updated_at: '2023-10-30T08:23:25.000000Z',
    location: {
      lat: 47.0502,
      lng: 8.3093,
    },
    fb_link: 'https://www.facebook.com/ronorpluzern',
    insta_link: 'https://www.instagram.com/ronorpluzern/',
  },
  {
    id: 3,
    value: 3,
    name: 'Romandie',
    label: 'Romandie',
    lang_id: 2,
    created_at: '2023-10-30T08:23:25.000000Z',
    updated_at: '2023-10-30T08:23:25.000000Z',
    location: {
      lat: 46.7407,
      lng: 7.4114,
    },
    fb_link: null,
    insta_link: null,
  },
  {
    id: 7,
    value: 7,
    name: 'St. Gallen',
    label: 'St. Gallen',
    lang_id: 3,
    created_at: '2023-10-30T08:23:25.000000Z',
    updated_at: '2023-10-30T08:23:25.000000Z',
    location: {
      lat: 47.4979,
      lng: 9.491,
    },
    fb_link: 'https://www.facebook.com/ronorpst.gallen',
    insta_link: 'https://www.instagram.com/ronorp_stgallen/',
  },
  {
    id: 8,
    value: 8,
    name: 'Winterthur',
    label: 'Winterthur',
    lang_id: 3,
    created_at: '2023-10-30T08:23:25.000000Z',
    updated_at: '2023-10-30T08:23:25.000000Z',
    location: {
      lat: 47.5056,
      lng: 8.7241,
    },
    fb_link: 'https://www.facebook.com/ronorpwinterthur',
    insta_link: 'https://www.instagram.com/ronorpwthur/',
  },
  {
    id: 2,
    value: 2,
    name: 'Zürich',
    label: 'Zürich',
    lang_id: 3,
    created_at: '2023-10-30T08:23:25.000000Z',
    updated_at: '2023-10-30T08:23:25.000000Z',
    location: {
      lat: 47.3667,
      lng: 8.55,
    },
    fb_link: 'https://www.facebook.com/ronorpzuerich',
    insta_link: 'https://www.instagram.com/ronorpch/',
  },
  {
    id: 1,
    value: 1,
    name: 'Zurich (EN)',
    label: 'Zurich (EN)',
    lang_id: 1,
    created_at: '2023-10-30T08:23:25.000000Z',
    updated_at: '2023-10-30T08:23:25.000000Z',
    location: {
      lat: 47.3667,
      lng: 8.55,
    },
    fb_link: null,
    insta_link: null,
  },
];

export function getCityDetailsFromId(cityId) {
  return all_cities.find((item) => item?.id == cityId);
}

export function getCityDetailsFromName(cityName = '') {
  return all_cities.find((item) => item?.name == cityName);
}

export function getCityDetails(cityName) {
  for (let i = 0; i < all_cities.length; i++) {
    if (all_cities[i].name === cityName) {
      return all_cities[i];
    }
  }
  return all_cities[8];
}

// export function getCityDetails(cityName) {
//   for (let i = 0; i < all_cities.length; i++) {
//     if (all_cities[i].name === cityName) {
//       return all_cities[i];
//     }
//   }
//   return all_cities[10];
// }

export function getModuleByPostType(postType) {
  const communityPostTypes = ['story', 'survey', 'phototrip', 'event'];
  const marketPostTypes = [
    'community',
    'events',
    'housing',
    'secondhand',
    'jobs',
    'cityoffer',
    'commercial',
  ];
  if (communityPostTypes.includes(postType)) return 'forum';
  else if (marketPostTypes.includes(postType)) return 'market';
  else return 'ron-tips';
}

export function checkIsDetailsPage(pathname) {
  return (
    pathname === '/market/posts/[id]' ||
    pathname === '/rons-tips/[id]' ||
    pathname === '/community/posts/[id]'
  );
}

export function getPostType(pathname) {
  if (pathname.includes('/community')) return 'forum';
  else if (pathname.includes('/rons-tips')) return 'ron-tips';
  else if (pathname.includes('/market')) return 'market';
  else return 'forum';
}

export function getModule(pathname) {
  if (pathname.includes('/community'))
    return { label: 'Community', routePath: 'community', apiPath: 'forum' };
  else if (pathname.includes('/rons-tips'))
    return { label: 'Rons Tips', routePath: 'rons-tips', apiPath: 'ron-tips' };
  else if (pathname.includes('/market'))
    return { label: 'Market', routePath: 'market', apiPath: 'market' };
  else return 'community';
}

export function getPostTypeString(pathname) {
  if (pathname.includes('/community')) return 'community';
  else if (pathname.includes('/rons-tips')) return 'rons-tips';
  else if (pathname.includes('/market')) return 'market';
  else return 'community';
}

export function getIndexesOfDuplicateArrayValues(values) {
  const duplicateIndexes = [];
  values.forEach((value, index) => {
    const firstIndex = values.indexOf(value);
    if (firstIndex !== index && !duplicateIndexes.includes(index)) {
      if (!duplicateIndexes.includes(firstIndex)) {
        duplicateIndexes.push(firstIndex);
      }
      duplicateIndexes.push(index);
    }
  });

  return duplicateIndexes;
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const getSelectedCity = () => {
  if (typeof window !== 'undefined') {
    const isJSON = isJsonString(localStorage.getItem('selectedCity'));
    const city = isJSON
      ? JSON.parse(localStorage.getItem('selectedCity'))
      : null;
    if (city) {
      return {
        id: city.id,
        name: city.name,
      };
    } else {
      return {
        id: 2,
        name: 'Zürich',
      };
    }
  }
};

export function convertToLink(fullStr, targetStr, link) {
  if (fullStr) {
    const splitted = fullStr.split(' ');
    const target = splitted.find((str) => str === targetStr);
    const beforeTarget = fullStr.substr(0, fullStr.indexOf(targetStr));
    const afterTarget = fullStr.slice(beforeTarget.length + target.length);
    const convertTargetStrToLink = `<a style="color: blue;" href=${link}>${targetStr}</a>`;
    const convertedFullHtml = `<div>${beforeTarget}${convertTargetStrToLink}${afterTarget}</div>`;
    return convertedFullHtml;
  }
  return '';
}

export function scrollToTop() {
  scrollTo({
    top: 0,
    left: 0,
    behavior: 'auto',
  });
}

export function scrollToElement(elementRef) {
  if (elementRef && elementRef.current) {
    elementRef.current.scrollIntoView({ behavior: 'smooth' });
  }
}

export function scrollToElementToSpecificPosition(
  selector,
  event,
  topOffset = 0
) {
  if (event) {
    event.preventDefault();
  }

  const element = document.querySelector(selector);
  if (element) {
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - topOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
}

export function getActiveProfileSubscription() {
  if (typeof window !== 'undefined') {
    const userDetails = getUserDetails();

    return userDetails && userDetails?.subscription;
  }

  return;
}

export function checkIsFreeUser() {
  if (typeof window !== 'undefined') {
    const userDetails = getUserDetails();

    return userDetails && userDetails?.subscription?.package_name === 'Free';
  }

  return;
}

export function getAccountType() {
  if (typeof window !== 'undefined') {
    const userDetails = getUserDetails();

    return userDetails && userDetails?.account_type;
  }
}

export function isProfileIncomplete() {
  if (typeof window !== 'undefined') {
    const userDetails = getUserDetails();

    if (
      userDetails?.profile_img === null ||
      userDetails?.interest?.length === 0
    )
      return true;
  }

  return false;
}

export function getDiffBetweenTwoDates(date) {
  if (date !== null) {
    const date1 = new Date();
    const date2 = new Date(date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }
  return;
}

export function sortNamesAlhabeticallyArrayOfObjects(data) {
  if (Array.isArray(data)) {
    return data.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }
}

// Secure alternative using crypto.getRandomValues
export function getRandomIntCrypto(max) {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  // Using modulo to limit the range
  return array[0] % max;
}

export function generateRandomString() {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < 12; i++) {
    const randomIndex = Math.floor(getRandomIntCrypto(characters.length));
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}
export function removeCountryWithComma(inputString) {
  // Create a regular expression pattern to match a comma followed by a country name
  const regexPattern = new RegExp(`,\\s*(${countryNames.join('|')})$`, 'i');

  // Use the regular expression to find a match in the input string
  const match = inputString.match(regexPattern);

  // If a match is found, remove the comma and country name
  if (match) {
    const [, countryName] = match; // Extract the matched country name
    return inputString.replace(regexPattern, '').trim();
  }

  // If no match is found, return the original string
  return inputString.trim();
}

export function updatePostDataOnLike({ posts, likeData }) {
  return posts?.map((item) => {
    if (item.id === likeData.postId) {
      return {
        ...item,
        ...likeData,
      };
    } else {
      return item;
    }
  });
}

// export async function handleImageUpload(imageFile) {
//   // const imageFile = event.target.files[0];

//   const options = {
//     maxSizeMB: 2,
//     // maxWidthOrHeight: 1920,
//     useWebWorker: true,
//   };
//   try {
//     const compressedFile = await imageCompression(imageFile, options);

//     return compressedFile;
//     // await uploadToServer(compressedFile); // write your own logic
//   } catch (error) {
//     console.error(error);
//   }
// }

export function setUserDataInCookies(userData) {
  if (userData) {
    const cookieData = {
      profile_id: userData?.profile_id,
      user_id: userData?.user_id,
      // city_id: userData?.city?.id,
    };
    Cookies.set('userData', JSON.stringify(cookieData), { expires: 7 });
  }
}

export function isValidAmount(num) {
  if (num > 0) return true;
  else return false;
}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function numberToBase64(num) {
  if (typeof num !== 'number' || !isFinite(num) || Math.floor(num) !== num) {
    throw new Error('Input must be a finite integer.');
  }

  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  let result = '';
  let isNegative = num < 0;

  num = Math.abs(num);

  while (num > 0) {
    result = chars[num % 64] + result;
    num = Math.floor(num / 64);
  }

  return generateRandomString() + (isNegative ? '-' : '') + (result || 'A'); // 'A' represents 0
}

export function base64ToNumber(base64) {
  if (typeof base64 !== 'string' || !base64.match(/^-?[A-Za-z0-9+/]+$/)) {
    throw new Error('Input must be a valid base64 string.');
  }

  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  let num = 0;
  let isNegative = base64[0] === '-';

  if (isNegative) {
    base64 = base64.slice(1);
  } else {
    base64 = base64.slice(generateRandomString().length);
  }

  for (let i = 0; i < base64.length; i++) {
    num = num * 64 + chars.indexOf(base64[i]);
  }

  return isNegative ? -num : num;
}

export function getLangBasedOnCity(city) {
  const cityDetails = getCityDetailsFromName(city);
  if (cityDetails?.lang_id == 1) return 'en';
  else if (cityDetails?.lang_id == 2) return 'fr';
  else return 'de';
}

export const checkIsMobile = () => {
  if (typeof window !== 'undefined') {
    if (window.innerWidth < 768) return 1;
  }
  return 0;
};

export function debounce(fn, delay = 500) {
  let timeoutId;
  return function () {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn();
    }, delay);
  };
}

export function formatSwissNumber(number) {
  if (!number) return;
  // Convert the number to a string and split it into the integer and decimal parts
  let [integerPart, decimalPart] = number.toString().split('.');

  // Add the comma as the thousands separator for the integer part
  integerPart = integerPart.replace(/\B(?=(?:\d{3})+(?!\d))/g, "'");

  // Combine the integer and decimal parts back together with a period as the decimal separator
  return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
}

export function getProfileName(userDetails) {
  if (userDetails && userDetails?.full_name) {
    return userDetails?.full_name;
  } else if (userDetails && userDetails?.first_name && userDetails?.sur_name) {
    return `${userDetails?.first_name} ${userDetails?.sur_name}`;
  } else if (userDetails && userDetails?.first_name) {
    return userDetails?.first_name;
  } else {
    return 'Unknown User';
  }
}

export function convertToK(num) {
  if (num >= 1000) {
    return Math.round(num / 1000) + 'K';
  }
  return num.toString();
}

export function prepareNotificationMessage(lang, language_msg) {
  if (!Array.isArray(language_msg)) return language_msg;

  let message = '';

  for (const item of language_msg) {
    if (lang?.notification && lang?.notification[item]) {
      message += lang?.notification[item] + ' ';
    } else {
      message += item + ' ';
    }
  }

  return message;
}

export function getLocale(cityId) {
  if (cityId == 1) return enUS;
  else if (cityId == 3) return fr;
  else return de;
}

export function getLocaleString(cityId) {
  if (cityId == 1) return 'en';
  else if (cityId == 3) return 'fr';
  else return 'de';
}

export const isDateValid = (value) =>
  new Date(value).toString() !== 'Invalid Date';

function removeParamsFromUrl(url) {
  const urlObj = new URL(url, 'https://example.com'); // Base URL (optional)

  // Create the updated URL
  const updatedUrl = `${urlObj.pathname}`;

  return updatedUrl;
}

export function getUrlBasedOnLocale(locale, router) {
  if (locale === 'en') {
    return `${removeParamsFromUrl(router.asPath)}?city=Zurich (EN)`;
  } else if (locale === 'fr') {
    return `${removeParamsFromUrl(router.asPath)}?city=Romandie`;
  } else {
    return `${removeParamsFromUrl(router.asPath)}?city=Zürich`;
  }
}

export const getTextLines = (element) => {
  if (!element) return 0;

  const style = window.getComputedStyle(element);
  const lineHeight = parseFloat(style.lineHeight);
  const height = element.offsetHeight;

  return Math.round(height / lineHeight);
};

export const checkIsEventClosed = ({ startDate, endDate, endTime }) => {
  const currentDate = new Date();

  // If end date exists, check against end date and time
  if (endDate) {
    const eventEndDate = new Date(endDate);

    if (endTime) {
      const [hours, minutes] = endTime.split(':');
      eventEndDate.setHours(parseInt(hours), parseInt(minutes));
      return currentDate > eventEndDate;
    }
  }

  // If no end date, check if start date has passed
  if (startDate) {
    const eventStartDate = new Date(startDate);
    const today = new Date(currentDate.toDateString());
    const eventDay = new Date(eventStartDate.toDateString());
    // Compare dates only by converting both to date strings (removes time component)
    return today > eventDay;
  }

  return false;
};

export const validatePhoneNumber = (phone) => {
  const phoneRegex = /^[0-9+\-]+$/;
  return phoneRegex.test(phone);
};
