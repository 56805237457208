import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCityId } from '../../../../utils/community';
import { instance } from '../../../axios/axios';
import Cookies from 'js-cookie';

const moduleName = 'cities';
const apiRoute = `/cities`;

export const getCitiesAsync = createAsyncThunk(
  `${moduleName}/getCitiessAsync`,
  async (payload) => {
    const cachedCities = JSON.parse(localStorage.getItem('cities')) || [];
    if (cachedCities.length > 0) {
      return cachedCities;
    } else {
      const response = await instance.get(apiRoute);

      const data = response.data;
      localStorage.setItem('cities', JSON.stringify(data));
      return data;
    }
  }
);
export const getLanguageCitiesAsync = createAsyncThunk(
  `${moduleName}/getLanguageCitiesAsync`,
  async (payload) => {
    const response = await instance.get(`/language-cities/${payload.cityId}`);

    const data = response.data;
    
    return data;
  }
);
export const getPublicationCitiesAsync = createAsyncThunk(
  `${moduleName}/getPublicationCitiesAsync`,
  async (payload) => {
    const response = await instance.get(
      `/market/publication-city?city_id=${getCityId()}&category_id=${payload.selectedCategory}&subcategory_id=${payload.selectedSubcategory}`
    );

    const data = response.data;
    
    return data;
  }
);

const citiesSlice = createSlice({
  name: 'cities',
  initialState: {
    selectedCity: null,
    cities: null,
    languageCities: null,
    publicationCities: null,
    citiesLoading: false,
    loading: false,
  },
  reducers: {
    setSelectedCity: (state, action) => {
      
      localStorage.setItem('selectedCity', JSON.stringify(action.payload));
      Cookies.set('selectedCity', JSON.stringify(action.payload));
      state.selectedCity = action.payload;
    },
  },
  extraReducers: {
    [getCitiesAsync.pending]: (state) => {
      state.citiesLoading = true;
    },
    [getCitiesAsync.fulfilled]: (state, action) => {
      state.citiesLoading = false;
      state.cities = action.payload;
    },
    [getCitiesAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getLanguageCitiesAsync.pending]: (state) => {
      state.citiesLoading = true;
    },
    [getLanguageCitiesAsync.fulfilled]: (state, action) => {
      state.citiesLoading = false;
      state.languageCities = action.payload;
    },
    [getLanguageCitiesAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getPublicationCitiesAsync.pending]: (state) => {
      state.citiesLoading = true;
    },
    [getPublicationCitiesAsync.fulfilled]: (state, action) => {
      state.citiesLoading = false;
      state.publicationCities = action.payload;
    },
    [getPublicationCitiesAsync.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { setSelectedCity } = citiesSlice.actions;
export default citiesSlice.reducer;
